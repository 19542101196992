import React, { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { DocumentImageAttachmentRow } from "../../RAFModules/ActiveContacts/Document/Library/DocumentHelper";
import RAFImageFileAttachment from "./RAFImageFileAttachment";
import { RAFDefaultFieldProps, RAFFieldProps, RAFFormContext, setFormValue } from "./RFFUtils";


function RAFImageFileAttachmentInput<T>({
    field,
    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,
    ...props
}: PropsWithChildren<RAFFieldProps<T>>) {
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const onChangeAttachment = (fileAttchements: DocumentImageAttachmentRow[]) => {
        setFormValue(rafFormContextValue, field.toString(), fileAttchements);
        if (props.onChanged) {
            props.onChanged(fileAttchements);
        }
    };

    return (
        <div>
            <Field name={field.toString()}>
                {({ input }) => {
                    return (
                        <div>
                            <RAFImageFileAttachment
                                fileAttchements={input.value ?? []}
                                onChangeAttachment={onChangeAttachment}
                            />
                        </div>
                    );
                }}
            </Field>
        </div>
    );
}

export default React.memo(RAFImageFileAttachmentInput);