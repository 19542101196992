import React, { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { RRule } from "rrule";
import RAFRepeatProcesssDropdown from "../../RAFModules/ActiveContacts/Task/RAFRepeatProcesssDropdown";
import RAFDetailInlineEdit1 from "../Navigation/RAFDetailInlineEdit1";
import { IsNullOrWhiteSpace, isNotNullAndUndefined } from "../helpers/utils";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFormContext, RAFTextBoxProps, setFormValue } from "./RFFUtils";


interface IProps {
    rRuleExpressionPropertyName?: string;
}


function RAFRecurrenceEditor<T>({
    field,
    label,
    width,
    created,
    onChanged,
    onInputBlur,
    fieldInnerText,
    children,
    maxlength,
    description,
    descriptionAsLabel,
    titleLocation,

    rRuleExpressionPropertyName = 'RruleExpression',

    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,
    ...props
}: PropsWithChildren<IProps & RAFTextBoxProps<T>>) {
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    let labelClassName = isNotNullAndUndefined(props.labelClassName)
        ? props.labelClassName
        : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : "col-12";
    if (titleLocation === "Right") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-auto order-last";
        inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-3";
        inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
        labelClassName = "order-last";
    }


    const showEditableField = (fieldId?: string) => {
        let openEditDivs = isNotNullAndUndefined(document)
            ? document.querySelectorAll('div[id^="editable_"]')
            : null;

        if (isNotNullAndUndefined(openEditDivs)) {
            openEditDivs.forEach((x) => {
                x.classList.add("hidden");
            });
        }

        if (isNotNullAndUndefined(fieldId)) {
            let editDiv = document.querySelector("#editable_" + fieldId);
            if (isNotNullAndUndefined(editDiv)) {
                editDiv.classList.remove("hidden");
            }
            //if (isNotNullAndUndefined(valueDiv)) {
            //    valueDiv.classList.add('hidden');
            //}
        }
    };

    const rRuleExpressionCustomContent = () => {
        return (
            <div className="p-3">
                <RAFRepeatProcesssDropdown
                    field="RepeatProcesss"
                    rRuleExpressionPropertyName={rRuleExpressionPropertyName}
                    showLabel={false}
                    label="Recurring"
                    onChanged={() => {
                        showEditableField();
                    }}
                />
            </div>
        );
    };

    const onClickClearField = (field) => {
        setFormValue(rafFormContextValue, field, null);
    };

    const onClickCreateRepeatProcesss = () => {
        onClickClearField("RepeatProcesss");
        onClickClearField(rRuleExpressionPropertyName);
    };

    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName + " form-group"
                    : "form-group"
            }
        >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                        descriptionAsLabel={descriptionAsLabel}
                    />
                )}
                <div className={inputFieldClassName}>
                    <div
                        className={
                            isNotNullAndUndefined(fieldInnerText)
                                ? "fieldInnerText d-flex"
                                : "d-flex"
                        }
                    >
                        <RAFDetailInlineEdit1
                            label="Recurring"
                            showLabel={false}
                            fieldName={rRuleExpressionPropertyName}
                            tooltipContent={rRuleExpressionCustomContent()}
                            onClickClearField={() => onClickCreateRepeatProcesss()}
                            // labelClassName="col-3"
                            // inputClassName="col-9"
                            UiMode="floatingCustomDiv"
                            valueUiMode="card"
                            customValue={<div className="d-flex align-items-baseline"><i className="fas fa-timer me-2"></i> <Field
                                name={rRuleExpressionPropertyName}
                                allowNull
                                parse={(value) => (value === "" ? null : value)}
                            >
                                {({ input }) => {
                                    let RecurringValue =
                                        isNotNullAndUndefined(input) &&
                                            !IsNullOrWhiteSpace(input.value)
                                            ? RRule.fromString(
                                                input.value.slice(0, input.value.length - 1)
                                            ).toText()
                                            : "Never";
                                    return <span className="text-wrap form-value">{RecurringValue}</span>;
                                }}
                            </Field></div>}
                        />
                    </div>
                    {props.hideRequiredMessage !== true ? (
                        <RAFFieldError name={field.toString()} />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(RAFRecurrenceEditor);