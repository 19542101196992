import { Inject, MentionComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  HtmlEditor,
  RichTextEditorComponent,
  Toolbar,
  ToolbarSettingsModel,
} from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";
import { PropsWithChildren, useContext, useEffect, useRef } from "react";
import { Field, FormRenderProps } from "react-final-form";
import {
  Guid,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../RAFComponents/helpers/utils";
import { getAllUsers } from "../helpers/AppHelper";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFTextAreaProps,
  isRequired
} from "./RFFUtils";

function RAFTextArea<T>({
  field,
  label,
  width,
  onInputs,
  onChanged,
  onFocus,
  rowClassName,
  onInputBlur,
  // menuContainer,
  //children,
  description,
  descriptionAsLabel,
  titleLocation,

  rows = 3,
  useMentions = false,
  mentionChar = "@",

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFTextAreaProps<T>>) {
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  rowClassName = isNotNullAndUndefined(rowClassName)
    ? `${rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : 'col-12';
  let textarea = useRef(null);
  if (titleLocation === 'Right') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-auto order-last';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Left') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-3';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Bottom') {
    labelClassName = 'order-last';
  }
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  let rteObj: RichTextEditorComponent;
  let mentionComponent: MentionComponent;
  const mentionfields = { text: 'value', value: 'value' };
  const toolbarSettings: ToolbarSettingsModel = {
    items: [],
    enable: false,
  };

  const objGuid = Guid.newGuid();

  const setMentionUsers = (selectedUsersArray) => {
    //console.log("setMentionUsers1", selectedUsersArray);
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators) &&
      isNotNullAndUndefined(props.mentionsField)
    ) {
      rafFormContextValue.form.mutators.setValue(
        props.mentionsField,
        selectedUsersArray
      );
    }
  };

  useEffect(() => {
    if (isNotNullAndUndefined(disabled) && disabled === false) {
      if (useMentions && useMentions === true) {
        refresh();
      }
    }
  });

  async function refresh() {
    let object: { key: string; value: string; id: string; }[] = [];
    let users = await getAllUsers();
    users &&
      users.forEach((item) => {
        object.push({
          key: item.UID,
          value: item.Value,
          id: item.UID,
        });
      });
    if (isNotNullAndUndefined(mentionComponent))
      mentionComponent.dataSource = object;
  }

  function displayTemplate(data) {
    let displayText = `@${data.value}`;
    return (
      <span className="tribute-mention" contentEditable={false}>
        <span id={data.id}> {displayText}</span>
      </span>
    );
  }

  const onInput = (innerHTML) => {
    //console.log("onInput", innerHTML);
    /*input.onInput(innerHTML);*/

    if (isNotNullAndUndefined(innerHTML)) {
      onInputs(innerHTML);
    }
  };

  const onBlur = (
    childNodes: NodeListOf<ChildNode>,
    children: HTMLCollection,
    input,
    innerHTML
  ) => {
    //console.log("array1", children, innerHTML);
    let selectedUserIdArray: string[] = [];
    //let selectedUserIdArray: LookUpRow[] = [];
    if (isNotNullAndUndefined(children)) {
      for (var i = 0; i < children.length; i++) {
        const objElement: Element = children.item(i);
        if (
          objElement.classList.contains("tribute-mention") ||
          objElement.classList.contains("e-mention-chip")
        ) {
          const objChildren = objElement.children;
          if (isNotNullAndUndefined(objChildren)) {
            const objFirstItem = objChildren[0];
            if (
              isNotNullAndUndefined(objFirstItem) &&
              isNotNullAndUndefined(objFirstItem.attributes) &&
              isNotNullAndUndefined(objFirstItem.attributes.getNamedItem("id"))
            ) {
              const objUserId =
                objFirstItem.attributes.getNamedItem("id").value;
              //const objUserName = objFirstItem.attributes.getNamedItem("name").value;
              if (isNotNullAndUndefined(objUserId)) {
                let userExist = false;
                if (isNotNullAndUndefined(selectedUserIdArray)) {
                  //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                  userExist =
                    selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                      ? true
                      : false;
                }
                if (!userExist) {
                  //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                  selectedUserIdArray.push(objUserId);
                }
              }
            }
          }
        }
      }
    }
    //console.log("array1", selectedUserIdArray, innerHTML);
    setMentionUsers(selectedUserIdArray);
    input.onChange(innerHTML);
    if (isNotNullAndUndefined(onChanged)) {
      onChanged(innerHTML);
    }
  };

  const onFocusOut = (value) => {
    if (isNotNullAndUndefined(onInputBlur))
      if (isNotNullAndUndefined(value) && !IsNullOrWhiteSpace(value)) {
        onInputBlur(value);
      }
  };

  function actionBegineHandler(args) {
    if (args.requestType === "EnterAction") {
      args.cancel = true;
    }
  }

  function onChangeRichTextEditor(value, input) {
    let selectedUserIdArray = getSelectedUserIdArray(value);
    setMentionUsers(selectedUserIdArray);
    input.onChange(value !== null ? value : null);
    if (isNotNullAndUndefined(onChanged)) {
      onChanged(value !== null ? value : null);
    }
    if (isNotNullAndUndefined(onInputs)) {
      onInputs(value);
    }
  }

  function getSelectedUserIdArray(value) {
    let selectedUserIdArray: string[] = [];
    if (!IsNullOrWhiteSpace(value)) {
      let innerHTML = value;
      const div = document.createElement("div");
      div.innerHTML = innerHTML.trim();
      let tributeItem = div.getElementsByClassName("tribute-mention");
      if (isNotNullAndUndefined(tributeItem) && tributeItem.length > 0) {
        for (var i = 0; i < tributeItem.length; i++) {
          const objFirstItem = tributeItem[i];
          if (
            isNotNullAndUndefined(objFirstItem) &&
            isNotNullAndUndefined(objFirstItem.children) &&
            isNotNullAndUndefined(objFirstItem.children[0]) &&
            isNotNullAndUndefined(objFirstItem.children[0].id)
          ) {
            const objUserId = objFirstItem.children[0].id;
            if (isNotNullAndUndefined(objUserId)) {
              let userExist = false;
              if (isNotNullAndUndefined(selectedUserIdArray)) {
                //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                userExist =
                  selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                    ? true
                    : false;
              }
              if (!userExist) {
                //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                selectedUserIdArray.push(objUserId);
              }
            }
          }
        }
      }
    }
    return selectedUserIdArray;
  }

  const onBlurRichTextEditor = (e: React.FocusEvent<Element, Element>, input) => {
    if (e.isTrusted) {
      if (isNotNullAndUndefined(rteObj)) {
        const rteObjValue = rteObj.getHtml();
        if (e.relatedTarget !== null && isNotNullAndUndefined(e.relatedTarget.closest('.raf-mentions'))) {
        } else {
          onChangeRichTextEditor(rteObjValue, input);
        }
      }
    }
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group align-items-center py-0"
          : "form-group align-items-center py-0"
      }
    >
      <div
        className={
          rowClassName
        }
        id={"rafdiv" + field.toString() + objGuid}
      >
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
            rightSection={props.labelRightSection}
          ></RAFFieldLabel>
        )}
        <div
          className={
            inputFieldClassName
          }
        >
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input /*, meta*/ }) => {
              return (
                <div className="d-flex flex-column text_input_area">
                  {/*
                                 <TextBoxComponent value={input.value} name={input.name} change={(e) => {
                                    input.onChange(e.value);
                                    if (isNotNullAndUndefined(onChanged)) {
                                        onChanged(e.value);
                                    }
                                }}
                                    width={width} placeholder={placeholder} disabled={disabled} />
                                
                                <textarea className="e-control e-textbox e-lib e-input" value={input.value} name={input.name} onChange={(value) => {
                                    input.onChange(value);
                                    if (isNotNullAndUndefined(onChanged)) {
                                        onChanged(value);
                                    }
                                }}
                                    style={{ width: width }} rows={props.rows} placeholder={props.placeholder} disabled={disabled} />
                                */}
                  {/*<RichTextEditorComponent ref={d => textEditorComponent = d} cssClass='richtextEditor' value={input.value} name={input.name} change={(e) => {
                                    onUpdate(e, input);
                                    //input.onChange(e.value !== null ? e.value : null);
                                    //if (isNotNullAndUndefined(onChanged)) {
                                    //    onChanged(e.value !== null ? e.value : null);
                                    //}
                                }} readonly={disabled} placeholder={props.placeholder} focus={onFocus} created={onCreated}>
                                    <Inject services={[HtmlEditor]} />
                                </RichTextEditorComponent>*/}

                  {useMentions && useMentions === true ? (
                    // <p
                    //   //id={"p" + field.toString() + objGuid}
                    //   id={`p${field.toString()}_${objGuid}`}
                    //   className="tribute-demo-input e-control e-textbox e-lib e-input"
                    //   placeholder={props.placeholder}
                    //   onInput={(e) => onInput(e.currentTarget.innerHTML)}
                    //   onFocus={onFocus}
                    //   onBlur={(e) =>
                    //     onBlur(
                    //       e.currentTarget.childNodes,
                    //       e.currentTarget.children,
                    //       input,
                    //       e.currentTarget.innerHTML
                    //     )
                    //   }
                    //   dangerouslySetInnerHTML={{
                    //     __html: DOMPurify.sanitize(input.value),
                    //   }}
                    // //dangerouslySetInnerHTML={{ __html: (input.value) }}
                    // ></p>
                    <RichTextEditorComponent
                      ref={(r) => (rteObj = r)}
                      id={`mention_integration${field.toString()}_${objGuid}`}
                      value={input.value}
                      name={input.name}
                      placeholder={props.placeholder}
                      className="tribute-demo-input e-control e-textbox e-lib e-input raf_prevent_submit"
                      actionBegin={actionBegineHandler.bind(this)}
                      // change={(e) => {
                      //   if (e.isInteracted === true) {
                      //     onChangeRichTextEditor(e.value, input);
                      //   }
                      // }}
                      onBlur={(e) => {
                        onBlurRichTextEditor(e, input);
                      }}
                      toolbarSettings={toolbarSettings}
                    >
                      <Inject
                        services={[
                          HtmlEditor,
                          Toolbar,
                          //Link,
                          //QuickToolbar,
                        ]}
                      />
                    </RichTextEditorComponent>
                  ) : (
                    <textarea
                      ref={textarea}
                      id={`tta_${field.toString()}`}
                      className="e-control e-textbox e-lib e-input"
                      value={input.value || ""}
                      name={input.name}
                      onChange={(value) => {
                        input.onChange(value);
                        if (isNotNullAndUndefined(onChanged)) {
                          onChanged(value);
                        }
                        if (textarea) {
                          textarea.current.style.height = "auto";
                          textarea.current.style.height = `${textarea.current.scrollHeight}px`;
                        }
                        // input.onChange(DOMPurify.sanitize(value));
                        // if (isNotNullAndUndefined(onChanged)) {
                        //     onChanged(DOMPurify.sanitize(value));
                        // }
                      }}
                      onBlur={(e) => onFocusOut(e.target.value)}
                      style={{ width: width, height: "auto" }}
                      rows={rows}
                      onInput={onInputs}
                      onFocus={onFocus}
                      placeholder={props.placeholder}
                      disabled={disabled}
                    // ref={(textarea) => {
                    //   if (textarea) {
                    //     textarea.style.height = "auto";
                    //     textarea.style.height = `${textarea.scrollHeight}px`;
                    //   }
                    // }}
                    />
                  )}
                  {useMentions === true && disabled !== true && (
                    <MentionComponent
                      //ref={mentionComponent}
                      ref={(m) => (mentionComponent = m)}
                      id="mentionEditor"
                      //target={`#mentionElement${objGuid}`}
                      target={`#mention_integration${field.toString()}_${objGuid}_rte-edit-view`}
                      //target={"#p" + field.toString() + objGuid}
                      //target={`#mention_integration_${objGuid}_rte-edit-view`}
                      //suggestionCount={8}
                      showMentionChar={false}
                      // allowSpaces={true}
                      allowSpaces
                      //dataSource={object}
                      fields={mentionfields}
                      popupWidth="250px"
                      popupHeight="200px"
                      //itemTemplate={itemTemplate}
                      displayTemplate={displayTemplate}
                      mentionChar={mentionChar ?? "@"}
                      //created={onMentionCreated}
                      sortOrder={"Ascending"}
                      suffixText={"&nbsp;"}
                      cssClass="raf-mentions"
                    ></MentionComponent>
                  )}
                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          </Field>
        </div>
        {isNotNullAndUndefined(description) && false &&
          <div className="col-12">
            <div className="">
              <label className="secondary-text m-0 mb-2">{description}</label>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default RAFTextArea;
