import { ButtonComponent, ChipDirective, ChipListComponent, ChipsDirective } from '@syncfusion/ej2-react-buttons';
import { PropsWithChildren } from "react";
import { Field } from 'react-final-form';
import { RAFButtonConstant } from '../../constants/Common/Constants';
import RAFDetailInlineEdit1 from '../Navigation/RAFDetailInlineEdit1';
import { showWarningToast } from '../Utility/RAFToastComponent';
import { Guid, IsNotNullOrWhiteSpace, closeFloatingPopupContent, isEmptyOrNull, isNotEmptyArray, isNotNullAndUndefined } from '../helpers/utils';
import { composeValidators } from './RAFForm';
import { RAFDefaultFieldProps, RAFTagInputFieldProps, hasEmoji, isRequired } from './RFFUtils';

function RAFTaskTagInput<T>(
    { field, onChanged, showAddBtn, moduleName, children, label,
        description,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFTagInputFieldProps<T>>,
) {
    const objGuid = Guid.newGuid();
    let labelClassName = (isNotNullAndUndefined(props.labelClassName) && isEmptyOrNull(props.labelClassName)) ? props.labelClassName : "col-auto";

    const addTagData = (event, input, inputValue) => {
        if (IsNotNullOrWhiteSpace(event.target.value)) {
            if (isEmptyOrNull(hasEmoji(event.target.value))) {
                const tagData = inputValue;
                let tagUpdatedData = [];
                if (isNotNullAndUndefined(tagData)) {
                    tagUpdatedData = [...tagData, event.target.value];
                } else {
                    tagUpdatedData[0] = event.target.value;
                }
                input.onChange(tagUpdatedData);
                event.target.value = '';
                if (isNotNullAndUndefined(onChanged)) {
                    onChanged(tagUpdatedData);
                }
                hideTagsInputField();
            } else {
                showWarningToast(`${hasEmoji(event.target.value)}`);
            }
        }
        event.preventDefault();
    };

    const removeTagData = (indexToRemove, input, inputValue) => {
        if (disabled === false) {
            const tagData = inputValue;
            let tagUpdatedData = [];
            if (isNotNullAndUndefined(tagData)) {
                tagUpdatedData = tagData && [...tagData.filter((_, index) => index !== indexToRemove)];
            }
            if (!isNotEmptyArray(tagUpdatedData)) {
                tagUpdatedData = null;
            }
            input.onChange(tagUpdatedData);
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(tagUpdatedData);
            }
        }

    };

    const removeItemOnBackSpaceClick = (indexToRemove, input) => {
        return; // on pressing back space inside a non empty text box, previous value getting removed 
        const tagData = input.value;
        let tagUpdatedData = [];
        if (isNotNullAndUndefined(tagData) && tagData.length > 0) {
            tagUpdatedData = tagData.slice(0, -1);
            input.onChange(tagUpdatedData);
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(tagUpdatedData);
            }
        }
    };

    const addTagsInputField = () => {
        let buttonElement = document.getElementById(`tagButton_${objGuid}`);
        let inputElement = document.getElementById(`tagInputField_${objGuid}`);
        if (isNotNullAndUndefined(buttonElement) && isNotNullAndUndefined(inputElement)) {
            buttonElement.classList.add("hidden");
            inputElement.classList.remove("hidden");
        }
    };

    const hideTagsInputField = () => {
        let buttonElement = document.getElementById(`tagButton_${objGuid}`);
        let inputElement = document.getElementById(`tagInputField_${objGuid}`);
        if (isNotNullAndUndefined(buttonElement) && isNotNullAndUndefined(inputElement)) {
            buttonElement.classList.remove("hidden");
            inputElement.classList.add("hidden");
        }
    };

    const customValue = (input, inputValue, disabled) => {
        return (isNotNullAndUndefined(inputValue) && inputValue.length > 0 ?
            <div className='d-flex'>
                <i className='fas fa-tag pt-2 pe-2 mt-1'></i>
                <ChipListComponent id="chip-avatar" enableDelete={disabled === true ? false : true} deleted={e => removeTagData(e.index, input, inputValue)}>
                    <ChipsDirective>
                        {inputValue.map((tag, index) => (
                            <ChipDirective key={index} text={tag} ></ChipDirective>
                        ))}
                    </ChipsDirective>
                </ChipListComponent>
            </div>
            :
            disabled === true ? <span className='form-value'>Not set</span> :
                <span className='form-value'>Click on Edit btn to add tags</span>

        );
    };

    const tagsListJsonCustomContent = (input, inputValue) => {
        return (
            <div className='p-3 pb-2'>
                <div className='e-input-group e-control-wrapper e-primary mb-2'>
                    <input
                        type="text"
                        //onKeyUp={event => (event.key === 'Enter' ? addTagData(event, input) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null)}
                        onKeyDown={(event) => {
                            event.stopPropagation();
                            event.key === 'Enter' ? addTagData(event, input, inputValue) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null;
                        }}
                        placeholder={isNotNullAndUndefined(props.placeholder) ? props.placeholder : "Type and press enter to add a tag"}
                        id={`input_addTag_${objGuid}_${field.toString()}`}
                        className='e-control e-textbox e-lib e-input'
                    />
                </div>
                {customValue(input, inputValue, false)}
            </div>
        );
    };

    const customFooter = (input, inputValue) => {
        return (<div className='w-100'>
            <div className="col-12">
                <div id={`tagButton_${objGuid}`}
                    className="justify-content-end"
                //className="d-none d-sm-flex justify-content-end"
                >
                    <ButtonComponent type="button"
                        cssClass="e-outline btn-roundedCorner me-2"
                        onClick={() => closeFloatingPopupContent(field)}
                        id={`btn_${RAFButtonConstant.Close.Id}_${objGuid}_${field.toString()}`}
                        content={RAFButtonConstant.Close.DisplayName}
                    >
                    </ButtonComponent>
                    <ButtonComponent type="button"
                        cssClass="e-outline btn-roundedCorner"
                        isPrimary
                        onClick={() => addTagsInputField()}
                        id={`btn_addTag_${objGuid}_${field.toString()}`}
                    >Add Tag</ButtonComponent>
                </div>
            </div>
            <div className="col-12">
                <div id={`tagInputField_${objGuid}`} className="hidden d-flex align-items-center w-100">
                    {/* <RAFForm onSubmit={(event) => addTagData(event, input, inputValue)} submitOnEnterKey>
                        <RAFTextBox field='Tags' showLabel={false}></RAFTextBox>
                    </RAFForm> */}
                    <div className='e-input-group e-control-wrapper e-primary'>
                        <input
                            type="text"
                            //onKeyUp={event => (event.key === 'Enter' ? addTagData(event, input) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null)}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                                event.key === 'Enter' ? addTagData(event, input, inputValue) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null;
                            }}
                            placeholder={isNotNullAndUndefined(props.placeholder) ? props.placeholder : "Type and press enter to add a tag"}
                            id={`input_addTag_${objGuid}_${field.toString()}`}
                            className='e-control e-textbox e-lib e-input'
                        />
                    </div>
                    <ButtonComponent type="button" iconCss='fas fa-xmark'
                        id={`btn_closeTag_${objGuid}_${field.toString()}`}
                        onClick={() => hideTagsInputField()} title="Click to remove" cssClass="primary-custom-button e-outline border-0 ms-2">
                    </ButtonComponent>
                </div>
            </div>
        </div>
        );
    };

    return (
        <div className="raftags app-container p-0"
            //id={"rafdiv" + field.toString()}
            id={`rafdiv_${field.toString()}_${objGuid}`}
        >
            <Field name={field.toString()}
                {...props.initialValue ? { initialValue: props.initialValue } : {}}
                //validate={validate === true ? (required && isRequired) : null}
                {...props.validators ?
                    { validate: validate === true ? composeValidators(required === true ? isRequired : null, ...props.validators) : null }
                    :
                    { validate: validate === true ? composeValidators(required === true ? isRequired : null) : null }
                }
                allowNull parse={value => (value === "" ? null : value)}>
                {({ input }) => {
                    let inputValue = isNotNullAndUndefined(input) && isNotEmptyArray(input.value) ? input.value : [];
                    return (
                        <div>
                            <RAFDetailInlineEdit1
                                label="Tags"
                                fieldName="TagsListJson"
                                tooltipContent={tagsListJsonCustomContent(input, inputValue)}
                                // labelClassName="col-3"
                                // inputClassName="col-9"
                                UiMode="floatingCustomDiv"
                                customValue={customValue(input, inputValue, true)}
                                valueUiMode='card'
                                // customButton={customFooter(input, inputValue)}
                                disabled={disabled}
                            />
                        </div>
                    );
                }}
            </Field>
        </div >
    );
}

export default RAFTaskTagInput;