import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SignatureComponent } from "@syncfusion/ej2-react-inputs";
import { PropsWithChildren, forwardRef, useContext, useImperativeHandle } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFieldProps,
  RAFFormContext,
  isRequired,
  setFormValue,
} from "./RFFUtils";
//const RAFTextBox = ({ field, label, required }) => {

interface IProps {
  setDefaultSignatureValue?: boolean;
  defaultSignatureValue?: string;
}

const RAFSignaturePad = forwardRef(
  function RAFSignaturePad<T>({
    field,
    label,
    children,
    labelClassName,
    description,
    descriptionAsLabel,
    titleLocation,
    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,
    ...props
  }: PropsWithChildren<RAFFieldProps<T> & IProps>, ref) {
    let signatureComponent: SignatureComponent;
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
    labelClassName = isNotNullAndUndefined(labelClassName)
      ? labelClassName
      : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
      ? `${props.rowClassName} row`
      : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
      ? props.inputFieldClassName
      : 'col-12';
    if (titleLocation === 'Right') {
      rowClassName = `${rowClassName} flex-nowrap gx-2`;
      labelClassName = 'col-auto order-last';
      inputFieldClassName = 'col';
    }
    else if (titleLocation === 'Left') {
      rowClassName = `${rowClassName} flex-nowrap gx-2`;
      labelClassName = 'col-3';
      inputFieldClassName = 'col';
    }
    else if (titleLocation === 'Bottom') {
      labelClassName = 'order-last';
    }

    useImperativeHandle(ref, () => ({
      onClearSignatureCall() {
        onClearSignature();
      },
      reloadSignatureCall() {
        onCreated();
      },
    }));

    const onClearSignature = () => {
      if (isNotNullAndUndefined(signatureComponent)) signatureComponent.clear();
      setFormValue(rafFormContextValue, field, null);
    };

    const onDrawSignatureEnd = () => {
      setFormValue(
        rafFormContextValue,
        field,
        signatureComponent.getSignature("Png")
      );
    };

    const onCreated = () => {
      if (isNotNullAndUndefined(signatureComponent)) {
        const canvasElement = signatureComponent.element;
        // const canvasElement = document.querySelector("#signature");
        // const parentDiv =canvasElement.target.closest()
        const parentDiv = canvasElement.parentElement;
        // const parentDiv = document.querySelector('.signature_parent_div');
        if (isNotNullAndUndefined(canvasElement))
          canvasElement.setAttribute("width", (props.width ? props.width.toString() : ((isNotNullAndUndefined(parentDiv) && isNotNullAndUndefined(parentDiv.clientWidth)) ? `${parentDiv.clientWidth}` : "400")));
        // canvasElement.setAttribute("width", (props.width ? props.width.toString() : ((isNotNullAndUndefined(parentDiv) && isNotNullAndUndefined(parentDiv.clientWidth) && parentDiv.clientWidth < 500) ? `${parentDiv.clientWidth}` : "400")));
        canvasElement.setAttribute("height", props.height ? props.height.toString() : '180');
        signatureComponent.strokeColor = "#0177c1";
        if (props.setDefaultSignatureValue === true && IsNotNullOrWhiteSpace(props.defaultSignatureValue)) {
          signatureComponent.draw(props.defaultSignatureValue, 'Dancing Script', 20);
        }

        let fieldArray = isNotNullAndUndefined(field.toString())
          ? field.toString().split(".")
          : null;
        if (
          isNotNullAndUndefined(rafFormContextValue) &&
          isNotNullAndUndefined(rafFormContextValue.values)
        ) {
          if (isNotNullAndUndefined(fieldArray) && fieldArray.length > 1) {
            let formData = rafFormContextValue.values[fieldArray[0]];
            let value = isNotNullAndUndefined(formData)
              ? formData[fieldArray[1]]
              : null;
            if (isNotNullAndUndefined(value)) signatureComponent.load(value);
          } else {
            let value = rafFormContextValue.values[field.toString()];
            if (isNotNullAndUndefined(value)) signatureComponent.load(value);
          }
        }
      }
    };

    return (
      <div
        className={
          isNotNullAndUndefined(props.formGroupClassName)
            ? props.formGroupClassName + " form-group"
            : "form-group"
        }
      >
        <div className={rowClassName} id={"rafdiv" + field.toString()}>
          {showLabel && showLabel === true && (
            <RAFFieldLabel
              field={field}
              label={label}
              required={required}
              labelClassName={labelClassName}
              description={description}
              descriptionAsLabel={descriptionAsLabel}
            ></RAFFieldLabel>
          )}
          <div className={inputFieldClassName}>
            <Field
              name={field.toString()}
              {...(props.initialValue
                ? { initialValue: props.initialValue }
                : {})}
              //validate={validate === true ? (required && isRequired) : null}
              {...(props.validators
                ? {
                  validate:
                    validate === true
                      ? composeValidators(
                        required === true ? isRequired : null,
                        ...props.validators
                      )
                      : null,
                }
                : {
                  validate:
                    validate === true
                      ? composeValidators(required === true ? isRequired : null)
                      : null,
                })}
              allowNull
              parse={(value) => (value === "" ? null : value)}
            >
              {({ }) => (
                <div>
                  <div>
                    <div id="signature-control">
                      <SignatureComponent
                        ref={(ref) => {
                          signatureComponent = ref;
                        }}
                        change={onDrawSignatureEnd}
                        id="signature"
                        created={onCreated}
                        isReadOnly={disabled}
                      ></SignatureComponent>
                    </div>

                    {props.hideRequiredMessage !== true ? (
                      <RAFFieldError name={field.toString()} />
                    ) : (
                      ""
                    )}
                  </div>
                  {showClearButton && (
                    <div className="mt-2">
                      <ButtonComponent
                        id={`btn_ClearSignature_${field.toString}`}
                        type="button"
                        cssClass="e-flat"
                        style={{ width: props.width ? props.width.toString() : "396px", maxWidth: "100%" }}
                        //onClick={() => onClearSignature()}
                        onClick={() => onClearSignature()}
                        disabled={disabled}
                      >
                        Clear
                      </ButtonComponent>
                    </div>
                  )}
                </div>
              )}
            </Field>
          </div>
        </div>
      </div>
    );
  });

export default RAFSignaturePad;
