import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor";
import React, { Reducer, useReducer, useRef } from "react";
import { BrowserWidth, RAFButtonConstant } from "../../constants/Common/Constants";
import RAFButtonComponent from "../Navigation/RAFButtonComponent";
import RAFEmptyState from "../Navigation/RAFEmptyState";
import { Guid, IsNotNullOrWhiteSpace, isNotNullAndUndefined, isNullOrUndefined } from "../helpers/utils";
import './ProfilePhotoStyle.css';

interface IProps {
    isActive: boolean;
    srcProfilePhoto: string;
    fileName: string;
    updateProfilePhoto: (file: any) => void;
    removeProfileClicked: () => void;
    selectCropType?: 'circle' | 'square' | 'custom';
    uploadButtonText?: string;

    selectedFileName: string;
    selectedProfilePhoto: string;
}

interface IState {
    selectedFileName: string;
    selectedProfilePhoto: string;
    profilePhotoDivKey: number;
    displayMode: 'view' | 'upload';
}

function RAFProfilePhotoImageEditor({
    srcProfilePhoto,
    selectCropType = 'circle',
    uploadButtonText = 'profile photo',
    ...props }: IProps) {
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            selectedFileName: props.selectedFileName,
            profilePhotoDivKey: Math.random(),
            selectedProfilePhoto: props.selectedProfilePhoto,
            displayMode: 'view'
        }
    );

    const outerDivId = `raf_image_editordlg_Outer_Div_${Guid.newGuid()}`;

    const imageEditorInstance = useRef<ImageEditorComponent>(null);
    const profileOuterDivRef = useRef(null);
    const imageUpload = useRef(null);
    let imageCanvas = useRef(null);
    let zoomLevel = 1;
    const zoomSettings = { maxZoomFactor: 30, minZoomFactor: 0.1 };

    const resetButtonClick = () => {
        setState({ profilePhotoDivKey: Math.random() });
        zoomLevel = 1;
        return;
        if (isNotNullAndUndefined(imageEditorInstance) && isNotNullAndUndefined(imageEditorInstance.current)) {
            imageEditorInstance.current.reset();
            zoomLevel = 1;
            imageEditorInstance.current.zoom(zoomLevel);
        }
    };

    const openButtonClick = () => {
        imageUpload.current.click();
    };

    const rotateButtonClick = () => {
        if (isNotNullAndUndefined(imageEditorInstance) && isNotNullAndUndefined(imageEditorInstance.current)) {
            imageEditorInstance.current.rotate(-90);
        }
    };

    const profilePhotoUpload = (file) => {
        if (props.updateProfilePhoto) {
            props.updateProfilePhoto(file);
        }
    };

    const applyButtonClick = () => {
        if (isNotNullAndUndefined(imageEditorInstance) && isNotNullAndUndefined(imageEditorInstance.current)) {
            imageEditorInstance.current.crop();
            let croppedData = imageEditorInstance.current.getImageData();
            if (isNotNullAndUndefined(croppedData)) {
                let tempCanvas = document.createElement("canvas");
                let tempContext = tempCanvas.getContext("2d");
                tempCanvas.width = croppedData.width;
                tempCanvas.height = croppedData.height;
                tempContext.putImageData(croppedData, 0, 0);

                // Convert the canvas to a Blob
                tempCanvas.toBlob(blob => {
                    // Create a new File object with the current date as the filename 
                    const { selectedFileName } = state;
                    const fileName = IsNotNullOrWhiteSpace(props.fileName) ? `${props.fileName} Profile Photo` : selectedFileName;
                    let newFile = new File([blob], fileName, { type: blob.type });
                    profilePhotoUpload(newFile);
                });
            }
        }
    };

    const fileChanged = (args) => {
        const fileData = isNotNullAndUndefined(args) && isNotNullAndUndefined(args.target) && isNotNullAndUndefined(args.target.files) ? args.target.files[0] : null;
        let selectedProfilePhoto = null;
        let selectedFileName = null;
        if (isNotNullAndUndefined(fileData)) {
            const URL = window.URL;
            const url = URL.createObjectURL(fileData);
            selectedFileName = fileData.name;
            selectedProfilePhoto = url;
        }

        setState({ selectedProfilePhoto, selectedFileName, profilePhotoDivKey: Math.random() });
    };

    const getImageEditorContent = () => {
        let imageEditorHeight
        let headerContent = document.getElementById('profile-dialog_dialog-header');
        let headerHeight = isNotNullAndUndefined(headerContent) && (headerContent.clientHeight !== 0) ? headerContent.clientHeight : 60;
        let footerContent = document.getElementById(`raf_image_editordlg_footer_content_Div`);
        let footerHeight = isNotNullAndUndefined(footerContent) && (footerContent.clientHeight !== 0) ? footerContent.clientHeight : 60;
        if (BrowserWidth <= 576) {
            imageEditorHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px - 32px)`;
        }
        else {
            imageEditorHeight = `calc(90vh - ${headerHeight}px - ${footerHeight}px - 32px)`;
        }
        return (
            <div className="control-pane" key={state.profilePhotoDivKey}>
                <div className="control-section e-img-editor-profile">
                    <div className="e-profile w-100">
                        <div className="e-custom-wrapper hidden" ref={profileOuterDivRef}>  {/* dont remove this div it is used for image editor */}
                            <canvas id="img-canvas" ref={imageCanvas}></canvas>
                            <input
                                type="file"
                                accept="image/*"
                                id="img-upload"
                                className="e-custom-file"
                                onChange={fileChanged} ref={imageUpload} />
                        </div>
                        {isNotNullAndUndefined(state.selectedProfilePhoto) ? (
                            // <ImageEditorComponent
                            //     ref={imageEditorInstance}
                            //     toolbar={[]}
                            //     fileOpened={fileOpened}
                            //     created={created}
                            // // width="300px"
                            // // height="300px"
                            // />
                            <ImageEditorComponent
                                ref={imageEditorInstance}
                                toolbar={[]}
                                fileOpened={fileOpened}
                                created={created}
                                height={imageEditorHeight}
                            >
                            </ImageEditorComponent>
                        ) :
                            (
                                <div className="p-3 pb-0">
                                    <RAFEmptyState iconClass="fa-icon fa-Camera"
                                        title={`Please select ${uploadButtonText}`}
                                        emptyStateContainerClass="h-auto"
                                    ></RAFEmptyState>
                                </div>
                            )}
                        {isNullOrUndefined(state.selectedProfilePhoto) && (
                            <RAFButtonComponent className="btn-roundedCorner btn-dashed e-outline w-100"
                                onClick={openButtonClick} isPrimary
                                btnContent={isNotNullAndUndefined(state.selectedProfilePhoto) ? `Change ${uploadButtonText}`
                                    : `Select ${uploadButtonText}`} />

                        )}
                    </div>
                </div >
            </div >
        );
    };

    const fileOpened = () => {
        imageEditorInstance.current.select(selectCropType ?? "circle");
    };

    const created = () => {
        if (isNotNullAndUndefined(state.selectedProfilePhoto)) {
            imageEditorInstance.current.open(state.selectedProfilePhoto);
        }
        if (imageEditorInstance.current.theme &&
            window.location.href.split("#")[1]) {
            imageEditorInstance.current.theme = window.location.href
                .split("#")[1]
                .split("/")[1];
        }
    };

    const removeProfileClicked = () => {
        if (props.removeProfileClicked) {
            props.removeProfileClicked();
        }
    };

    const zoominButtonClick = () => {
        if (isNotNullAndUndefined(imageEditorInstance) && isNotNullAndUndefined(imageEditorInstance.current)) {
            //imageEditorInstance.current.zoom(5);

            if (zoomLevel < 1) {
                zoomLevel += 0.1;
            } else {
                zoomLevel += 1;
            }
            const value = zoomSettings.maxZoomFactor;
            if (zoomLevel > value) {
                zoomLevel = value;
            }
            imageEditorInstance.current.zoom(zoomLevel); // Zoom in
        }
    };

    const zoomoutButtonClick = () => {
        if (isNotNullAndUndefined(imageEditorInstance) && isNotNullAndUndefined(imageEditorInstance.current)) {
            //imageEditorInstance.current.zoom(-1);

            if (zoomLevel <= 1) {
                zoomLevel -= 0.1;
            } else {
                zoomLevel -= 1;
            }
            const value = zoomSettings.minZoomFactor;
            if (zoomLevel < value) {
                zoomLevel = value;
            }
            imageEditorInstance.current.zoom(zoomLevel); // Zoom out
        }
    };

    if (props.isActive) {
        return (
            <div className="h-100" id={outerDivId}>
                <div className="e-dlg-content-outer">
                    <div className="e-dlg-body-content p-2 p-md-3">
                        {getImageEditorContent()}
                    </div>
                    {isNotNullAndUndefined(state.selectedProfilePhoto) && (
                        <div className="e-dlg-footerContent w-100" id={`raf_image_editordlg_footer_content_Div`}>
                            <div className="w-100">
                                <div className="row gx-2">
                                    {isNotNullAndUndefined(srcProfilePhoto) ? (
                                        <div className="col-auto">
                                            <RAFButtonComponent
                                                action={RAFButtonConstant.Delete}
                                                onClick={removeProfileClicked}
                                                className="e-danger e-outline d-none d-md-block"
                                                showIcon={false}
                                                btnContent="Remove"
                                            />
                                            <RAFButtonComponent
                                                action={RAFButtonConstant.Delete}
                                                onClick={removeProfileClicked}
                                                className="e-danger e-outline d-md-none"
                                                btnContent="Remove"
                                                iconBtn
                                            />
                                        </div>
                                    )
                                        :
                                        <div className="col-auto">
                                            <RAFButtonComponent
                                                action={RAFButtonConstant.Edit}
                                                btnContent="Change"
                                                onClick={openButtonClick}
                                                className="btn_brand_primary semi_dark d-none d-md-block"
                                                showIcon={false}
                                            />
                                            <RAFButtonComponent
                                                action={RAFButtonConstant.Edit}
                                                onClick={openButtonClick}
                                                className="btn_brand_primary semi_dark d-md-none"
                                                btnContent="Change"
                                                iconBtn
                                            />
                                            {/* <RAFButtonComponent
                                                type="button"
                                                btnContent="Change"
                                                isPrimary
                                                onClick={openButtonClick}
                                            /> */}
                                        </div>
                                    }
                                    {/* <div className="col-auto">
                            <RAFButtonComponent
                                type="button"
                                btnContent="Open"
                                onClick={openButtonClick}
                            />
                        </div> */}
                                    <div className="col-auto ms-auto">
                                        <RAFButtonComponent
                                            type="button"
                                            btnContent="Rotate"
                                            action={RAFButtonConstant.Rotate}
                                            onClick={rotateButtonClick}
                                            className="e-outline"
                                            iconBtn
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <RAFButtonComponent
                                            type="button"
                                            action={RAFButtonConstant.ZoomIn}
                                            btnContent="Zoom In"
                                            onClick={zoominButtonClick}
                                            className="e-outline"
                                            iconBtn
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <RAFButtonComponent
                                            type="button"
                                            action={RAFButtonConstant.ZoomOut}
                                            btnContent="Zoom Out"
                                            onClick={zoomoutButtonClick}
                                            className="e-outline"
                                            iconBtn
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <RAFButtonComponent
                                            type="button"
                                            action={RAFButtonConstant.Reset}
                                            onClick={resetButtonClick}
                                            className="e-outline d-none d-md-block"
                                            showIcon={false}
                                        />
                                        <RAFButtonComponent
                                            type="button"
                                            action={RAFButtonConstant.Reset}
                                            onClick={resetButtonClick}
                                            className="e-outline d-md-none"
                                            iconBtn
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <RAFButtonComponent
                                            type="button"
                                            btnContent="Apply"
                                            isPrimary
                                            onClick={applyButtonClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default React.memo(RAFProfilePhotoImageEditor);