import { DataManager, Query, UrlAdaptor, ODataV4Adaptor } from "@syncfusion/ej2-data";
import { RAFCustomFilter } from "../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { Constants, RAFHeaderNames } from "../../constants/Common/Constants";
import { getPureSubDomainOrHint, isNotNullAndUndefined, isNullOrUndefined, IsNullOrWhiteSpace } from "../../RAFComponents/helpers/utils";
import { msalInstance } from "../..";
import { RAFSort } from "../helpers/types";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AzureAdB2C } from "../../RAFAzure/AuthModule";

export class RAFUrlAdaptor extends
    UrlAdaptor {
    //ODataV4Adaptor {

    public opt: IRAFUrlAdaptorOptions;
    public schema: { result: string, count: string; };
    public query: string;
    public getQuery: Function;
    _authToken;
    _currentBusinessUnitId;


    constructor(options: IRAFUrlAdaptorOptions) {
        super();
        this.opt = options;
        this.schema = this.opt.response;
        this.query = this.opt.query;
        // tslint:disable-next-line:no-empty 
        this.getQuery = () => this.query;
    }

    async updateAuthTokenOld() {
        this._authToken = await msalInstance.getADToken();
        this._currentBusinessUnitId = msalInstance.currentBusinessUnitId;
    }

    updateAuthToken() {
        this._authToken = msalInstance.accessToken;
        this._currentBusinessUnitId = msalInstance.currentBusinessUnitId;
    }

    processQuery(datamanager: DataManager, query: Query) {
        //console.log('query', query);
        let containsText: any;
        if (isNotNullAndUndefined(query) && isNotNullAndUndefined(query.queries) && query.queries.length > 0) {
            const objQueryOptions = query.queries[0].e;
            if (isNotNullAndUndefined(objQueryOptions) && isNotNullAndUndefined(objQueryOptions.value)) {
                containsText = objQueryOptions.value;
            }
        }
        let sortQuery: string[] = [];

        if (
            isNotNullAndUndefined(this.opt.Sort) &&
            isNotNullAndUndefined(this.opt.Sort.field) &&
            !IsNullOrWhiteSpace(this.opt.Sort.field)
        ) {
            sortQuery.push(
                this.opt.Sort.order === "descending" ? `${this.opt.Sort.field} desc` : this.opt.Sort.field
            );
        }
        const result = super.processQuery.apply(this, arguments);
        //const tmp = JSON.parse(result.data);
        // const data = JSON.stringify({
        //     //query: this.getQuery(),
        //     ContainsText: isNotNullAndUndefined(containsText) ? containsText : null,

        //     Skip: isNotNullAndUndefined(this.opt.Skip) ? this.opt.Skip : 0,
        //     Take: isNotNullAndUndefined(this.opt.Take) ? this.opt.Take : Constants.DropdownFetchCount,
        //     CustomFilter: isNotNullAndUndefined(this.opt.CustomFilter) ? this.opt.CustomFilter : null

        // });
        const data = {
            //query: this.getQuery(),
            ContainsText: isNotNullAndUndefined(containsText) ? containsText : null,

            Skip: isNotNullAndUndefined(this.opt.Skip) ? this.opt.Skip : 0,
            Take: isNotNullAndUndefined(this.opt.Take) ? this.opt.Take : Constants.DropdownFetchCount,
            CustomFilter: isNotNullAndUndefined(this.opt.CustomFilter) ? this.opt.CustomFilter : null,
            Sort: isNotNullAndUndefined(sortQuery) ? sortQuery : null,

        };
        if (!IsNullOrWhiteSpace(this.opt.viewId)) {
            data['ViewUID'] = this.opt.viewId;
        }
        if (!IsNullOrWhiteSpace(this.opt.viewName)) {
            data['ViewName'] = this.opt.viewName;
        }
        if (!IsNullOrWhiteSpace(this.opt.reportId)) {
            data['ReportUID'] = this.opt.reportId;
        }
        if (!IsNullOrWhiteSpace(this.opt.entityName)) {
            data['EntityName'] = this.opt.entityName;
        }
        if (
            isNotNullAndUndefined(this.opt.additionalParams) &&
            this.opt.additionalParams.length > 0
        ) {
            this.opt.additionalParams.forEach((objProp) => {
                data[objProp.key] = objProp.value;
            });
        }
        result.data = JSON.stringify(data);
        return result; // form query based on your need 
    }

    processResponse(data: any) {
        //console.log(' data', data.Entities);  // should return result and count 
        if (isNullOrUndefined(data.Entities))
            return [];
        return data.Entities;  // should return result and count  
        //return {
        //    result: data.Entities,
        //    count: data.TotalCount,
        //}// should return result and count
    }

    beforeSend(dm, request) {
        try {
            if (this._authToken) {
                // request.setRequestHeader(RAFHeaderNames.Authorization, `Bearer ${this._authToken}`);
                // request.setRequestHeader(RAFHeaderNames.BusinessUnitUID, this._currentBusinessUnitId);
                // request.setRequestHeader(RAFHeaderNames.Domain, getPureSubDomainOrHint());
                request.headers.set(RAFHeaderNames.Authorization, `Bearer ${this._authToken}`);
                request.headers.set(RAFHeaderNames.BusinessUnitUID, this._currentBusinessUnitId);
                request.headers.set(RAFHeaderNames.Domain, getPureSubDomainOrHint());
            }
        } catch (error) {
            console.error('RAFUrlAdaptor beforeSend', error);
        }
        //inside beforeSend here you have create a new Request and set credentials as include

        // // Use a callback to handle asynchronous token refreshing
        // this.refreshAccessToken(() => {
        //     // Attach the access token to the request headers
        //     request.setRequestHeader(RAFHeaderNames.Authorization, `Bearer ${this._authToken}`);
        //     request.setRequestHeader(RAFHeaderNames.BusinessUnitUID, this._currentBusinessUnitId);
        //     request.setRequestHeader(RAFHeaderNames.Domain, getPureSubDomainOrHint());
        // });
    }

    // beforeSend(dm, request, settings) {
    //     console.log('beforeSend', { request }, { dm }, { settings }, { this: this });
    //     // if (this._authToken) {
    //     //     request.setRequestHeader(RAFHeaderNames.Authorization, `Bearer ${this._authToken}`);
    //     //     request.setRequestHeader(RAFHeaderNames.BusinessUnitUID, this._currentBusinessUnitId);
    //     //     request.setRequestHeader(RAFHeaderNames.Domain, getPureSubDomainOrHint());
    //     // }
    //     console.log('beforeSend2', { request }, { dm }, { settings });
    //     //inside beforeSend here you have create a new Request and set credentials as include

    //     // var newFetchRequest = new Request(request.url, {

    //     //     method: request.method,

    //     //     headers: { 'Syncfusion': 'true' },

    //     //     credentials: 'include', // Set credentials to 'include' here

    //     // });

    //     // request = newFetchRequest;

    //     const url = isNotNullAndUndefined(dm.dataSource.url) ? dm.dataSource.url : request.url;
    //     const method = isNotNullAndUndefined(dm.dataSource.requestType) ? dm.dataSource.requestType : request.method;


    //     var newFetchRequest = new Request(url, {

    //         method: method,

    //         headers: {
    //             [RAFHeaderNames.Authorization]: `Bearer ${this._authToken}`,
    //             [RAFHeaderNames.BusinessUnitUID]: this._currentBusinessUnitId,
    //             [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
    //         },

    //         credentials: 'include', // Set credentials to 'include' here

    //     });

    //     settings.fetchRequest = newFetchRequest;





    //     // // // Use a callback to handle asynchronous token refreshing
    //     // this.refreshAccessToken(() => {
    //     //     // Attach the access token to the request headers
    //     //     request.setRequestHeader(RAFHeaderNames.Authorization, `Bearer ${this._authToken}`);
    //     //     request.setRequestHeader(RAFHeaderNames.BusinessUnitUID, this._currentBusinessUnitId);
    //     //     request.setRequestHeader(RAFHeaderNames.Domain, getPureSubDomainOrHint());
    //     // });
    // }

    async refreshAccessToken(callback) {
        // Add logic to refresh the access token
        // This can involve making a request to the token endpoint with a refresh token
        // or using any other mechanism provided by your authentication service
        // After refreshing, call the callback to proceed with the request
        await this.someAsyncTokenRefreshLogic();
        callback();
    };

    async someAsyncTokenRefreshLogic() {
        // Your asynchronous token refresh logic
        // For example, make a request to refresh the token

        // //await msalInstance.getADToken();
        // this._authToken = await msalInstance.getADToken();
        // this._currentBusinessUnitId = msalInstance.currentBusinessUnitId;

        //acquire silent token. if error, acquire token interactively. if error, logout
        const account = msalInstance.getActiveAccount();
        if (!account) {
            const loginPopupResponse = await msalInstance.loginPopup({
                scopes: [AzureAdB2C.AcquireTokenScope],
                //account: account
            });
            if (isNotNullAndUndefined(loginPopupResponse)) {
                msalInstance.setActiveAccount(loginPopupResponse.account);
                msalInstance.accessToken = (loginPopupResponse.accessToken);
                this._authToken = loginPopupResponse.accessToken;

            }
        }

        // try {
        const acquireTokenResponse = await msalInstance.acquireTokenSilent({
            scopes: [AzureAdB2C.AcquireTokenScope],
            account: account
        });
        if (isNotNullAndUndefined(acquireTokenResponse)) {
            msalInstance.setActiveAccount(acquireTokenResponse.account);
            msalInstance.accessToken = (acquireTokenResponse.accessToken);
            this._authToken = acquireTokenResponse.accessToken;
        }

        // } 
        // catch (error) {
        //     if (error instanceof InteractionRequiredAuthError) {
        //         const acquireTokenPopupResponse = await msalInstance.acquireTokenPopup({
        //             scopes: [AzureAdB2C.AcquireTokenScope],
        //             account: account
        //         });
        //         if (isNotNullAndUndefined(acquireTokenPopupResponse)) {
        //             msalInstance.setActiveAccount(acquireTokenPopupResponse.account);
        //             msalInstance.accessToken = (acquireTokenPopupResponse.accessToken);
        //             this._authToken = acquireTokenPopupResponse.accessToken;
        //         }
        //     } else {
        //         console.error(error);
        //         await msalInstance.logoutRedirect();

        //     }
        // }
    };
}

export interface IRAFUrlAdaptorOptions {
    response?: { result: string, count: string; };
    query?: string;
    getQuery?: () => string;


    Skip?: number;
    Take?: number;
    Sort?: RAFSort;
    ContainsText?: string;
    ContainsField?: string;
    Criteria?: any[];
    EqualityFilter?: any;
    IncludeDeleted?: boolean;
    ExcludeTotalCount?: boolean;

    IncludeColumns?: string[];
    ExcludeColumns?: string[];
    DistinctFields?: string[];

    CustomFilter?: RAFCustomFilter;
    additionalParams?: { key: string; value?: string | string[]; }[];

    viewId?: string;
    viewName?: string;
    reportId?: string;
    entityName?: string;

}


export class RAFDataManagerOld extends DataManager {
    /*constructor(datasource?, query?, adaptor?) {
        super(datasource, query, adaptor);
    }*/

    async executeQuery(query?, done?, fail?, always?) {
        if (this.adaptor instanceof RAFUrlAdaptor)
            await this.adaptor.updateAuthToken();
        return super.executeQuery(query, done, fail, always);
    }
}


function getTokenExpirationTime(): number | undefined {
    // Get the MSAL account
    const account = msalInstance.getActiveAccount();
    //console.log('getTokenExpirationTime account', account);
    // Check if the account exists and has an expiration time
    if (account && account.idTokenClaims) {

        const idTokenClaims = account.idTokenClaims;
        if (isNotNullAndUndefined(idTokenClaims)) {
            const expirationTime = idTokenClaims['exp'];
            //expirationTime is epoch time. convert to milliseconds
            return expirationTime * 1000;
        }

    }

    return undefined;
}

function isTokenExpired(): boolean {
    // Get the token expiration time from your authentication provider
    const tokenExpirationTime = getTokenExpirationTime(); // Replace with your implementation

    // Get the current time
    const currentTime = new Date().getTime();
    //console.log('isTokenExpired', tokenExpirationTime, currentTime);
    // Compare the token expiration time with the current time
    if (tokenExpirationTime && currentTime > tokenExpirationTime) {
        // Token has expired
        return true;
    }

    // Token is not expired
    return false;
}


async function refreshToken(): Promise<void> {
    try {
        // Call the MSAL instance's acquireTokenSilent method to refresh the token
        const response = await msalInstance.acquireTokenSilent({
            scopes: [AzureAdB2C.AcquireTokenScope],
            account: msalInstance.getActiveAccount()
        });

        // Handle the response, e.g., update the token in your application's state or storage
        if (isNotNullAndUndefined(response) && typeof response === 'object') {
            msalInstance['accessToken'] = response.accessToken;;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        // Handle the error, e.g., redirect to login page or display an error message
        // ...
        throw error;
    }
}

export class RAFDataManager extends DataManager {
    async executeQuery(query: Query): Promise<any> {
        // Add your interceptor logic here
        // For example, you can check if the token is expired and refresh it before sending the request

        // Check if the token is expired
        if (isTokenExpired()) {
            // Refresh the token
            await refreshToken();
        }

        if (this.adaptor instanceof RAFUrlAdaptor)
            this.adaptor.updateAuthToken();

        // Call the original executeQuery method to send the request
        return super.executeQuery(query);
    }
}

