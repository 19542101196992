import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Dialog } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import * as React from "react";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import { Field, FormRenderProps } from "react-final-form";
import {
  getQueryAttribute,
  removeSessionStorage
} from "../../RAFComponents/helpers/AppHelper";
import {
  Guid,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../RAFComponents/helpers/utils";
import { StorageKey } from "../../constants/Common/Constants";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import "./InputStyle.scss";
import ManageChoiceList from "./ManageChoiceList";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFDropdownFieldProps,
  RAFFormContext,
  isRequired,
} from "./RFFUtils";

const getDataFromChildren = (
  children?,
  addEmpty?: boolean,
  emptyString?: string,
  attributeJM?: QueryAttributeJM
) => {
  let retVal: ValueJson[] = [];
  if (isNotNullAndUndefined(children) && children.length > 0) {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: null,
        DisplayName: emptyString || "None",
        ColorCode: "#B3B6B7",
        Category: null,
      });
    }
    React.Children.forEach(children, (child, i) => {
      //retVal.push({ ...child["props"], label: (child["props"]["children"] || child["props"]["label"]) });
      retVal.push({
        Id: i + 1,
        Name: child["props"]["value"],
        DisplayName: child["props"]["children"] || child["props"]["label"],
        ColorCode: child["props"]["colorCode"],
        Category: child["props"]["category"],
      });
    });
  } else {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: "",
        DisplayName: emptyString || "None",
        ColorCode: "transparent",
        Category: "",
      });
    }
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
  }
  return retVal;
};

const itemTemplate = (data: any): JSX.Element => {
  if (isNotNullAndUndefined(data)) {
    return (
      <div
        className="dropdown_item d-flex align-items-center m-2"
        style={{
          background: data.colorCode,
          color: "#fff",
          borderRadius: "2px",
        }}
      >
        <div
          className="ms-2"
          style={{
            background: data.colorCode,
            width: "8px",
            height: "8px",
            borderRadius: "50%",
          }}
        ></div>
        <span className="">{data.label}</span>
      </div>
    );
  }
};
const valueTemplate = (data: any): JSX.Element => {
  if (isNotNullAndUndefined(data)) {
    return (
      <div
        className="dropdown_value"
        style={{ background: data.colorCode, color: "#fff" }}
      >
        {data.label}
      </div>
    );
  }
};
//const RAFDropdown = ({ field, label, required, children }) => {

interface IProps {
  moduleName?: string;
  allowAdd?: boolean;
  emptyString?: string;
  queryAttribute?: QueryAttributeJM;
}

function RAFGroupDropdown<T>({
  field,
  label,
  width,
  onChanged,
  isColorOption,
  children,
  moduleName,
  description,
  descriptionAsLabel,
  formGroupClassName,


  uitype = "default",
  allowAdd = true,
  textField = "DisplayName",
  valueField = "Name",

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFDropdownFieldProps<T> & IProps>) {
  const fields = { text: "DisplayName", value: "Name", groupBy: "Category" };
  if (isNotNullAndUndefined(textField)) {
    fields.text = textField;
  }
  if (isNotNullAndUndefined(valueField)) {
    fields.value = valueField;
  }
  let dropdownBtnComponent: DropDownButtonComponent;
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  //let choiceOptions = getDataFromChildren(React.Children.toArray(children));
  //let items = uitype === 'colorpicker' ? getDataFromChildren(null, true,'', attributeJM) : getDataFromChildren(React.Children.toArray(children));

  //const [items, setRafChoiceItems] = useState(choiceItems as RAFChoiceList[]);
  const [attributeJM, setAttributeJM] = useState(
    isNotNullAndUndefined(props.queryAttribute)
      ? props.queryAttribute
      : ({} as QueryAttributeJM)
  );
  //const didMountRef = useRef(false)
  let indexVal: string = Guid.newGuid();

  const onItemClicked = (label, value, divId, colorCode) => {
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    let customDropdownBtn = document.querySelector<HTMLElement>(
      ".customBtn_" + field.toString()
    );
    if (isNotNullAndUndefined(customDropdownBtn)) {
      if (isNullOrUndefined(props.mode)) {
        customDropdownBtn.style.background = colorCode;
      }
      //customDropdownBtn.style.color = '#fff';
      customDropdownBtn.style.paddingLeft = "15px";
      customDropdownBtn.style.paddingRight = "10px";
      customDropdownBtn.style.fontWeight = "400";
    }

    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      /*value === '' ?
                rafFormContextValue.form.mutators.setValue(field.toString(), null) :
                rafFormContextValue.form.mutators.setValue(field.toString(), value);*/
      label === ""
        ? rafFormContextValue.form.mutators.setValue(field.toString(), null)
        : rafFormContextValue.form.mutators.setValue(field.toString(), label);
    }

    if (isNotNullAndUndefined(onChanged)) {
      onChanged(label !== null ? label : null, value !== null ? value : null);
    }

    if (isNotNullAndUndefined(dropdownBtnComponent)) {
      dropdownBtnComponent.content = label;
    }
  };

  function dialogClose(): void {
    const e1 = document.getElementById(
      "createDropdownDialog" + field.toString()
    );
    if (isNotNullAndUndefined(e1)) {
      e1.parentElement.remove();
    }
    document.body.classList.add("overflow-hidden");
  }

  function dialogOpen(): void {
    //console.log('dialogOpen');
    const root = ReactDOMClient.createRoot(
      document.getElementById(`divManageChoiceListContent${field.toString()}`)
    );
    root.render(
      <ManageChoiceList
        allowAdd
        field={field.toString()}
        onSave={() => refreshDropdown()}
        onClose={() => dialogClose()}
        attributeJM={attributeJM}
      //moduleName={moduleName}
      ></ManageChoiceList>
    );
  }

  //function Wrapped2<P = {}>({ as2: Component = 'a', ...props1 }: WrappedProps2<P>) {
  //    return (
  //        <Component {...props1} />
  //    );
  //}

  /*const createDialogContent = () => {
       

        return <ManageChoiceList allowAdd field={field.toString()} onSave={() => refreshDropdown()} onClose={() => dialogClose()} attributeJM={attributeJM} moduleName={moduleName}></ManageChoiceList>

        //if (isNotNullAndUndefined(createform)) {
        //    return (
        //        <>

        //            <ManageChoiceList allowAdd field={field.toString()} choiceListItems={choiceOptions} onSave={() => dialogClose()} onClose={() => dialogClose()}></ManageChoiceList>
        //        </>
        //    );
        //}
        //else {
        //    return (
        //        <div className="dialogContent">
        //            <span className="dialogText">Greetings Nancy! When will you share me the source files of the project?</span>
        //        </div>
        //    );
        //}
    }*/

  const createDynamicDialog = () => {
    //console.log('createDynamicDialog.content', content);
    let dialogObj: Dialog = new Dialog({
      //width: '335px',
      header: "Manage : " + field.toString(),
      showCloseIcon: true,
      //visible:,
      cssClass: "centerDialog-sm choice-list-dialog",
      //overlayClick:,
      isModal: true,
      target: "body",
      closeOnEscape: false,
      //content: `<ManageChoiceList allowAdd=${true} field=${field.toString()} choiceListItems=${choiceOptions}></ManageChoiceList>`,//' <div className="dialogContent">aaa</div>',//createDialogContent(objComp) as any,
      //content: createDialogContent as any,//`<Wrapped<LinkProps> as=${createform} to="/foo">Something</Wrapped>`,//' <div className="dialogContent">aaa</div>',//createDialogContent(objComp) as any,
      content: `<div id="divManageChoiceListContent${field.toString()}"></div>`,
      animationSettings: { effect: "None" },
      //buttons: [{
      //    click: dlgButtonClick,
      //    buttonModel: { content: 'OK', cssClass: 'e-flat', isPrimary: true }
      //}],
      open: dialogOpen,
      close: dialogClose,
    });
    const ele = document.createElement("div");
    ele.setAttribute("id", "createDropdownDialog" + field.toString());
    if (
      document.getElementById("createDropdownDialog" + field.toString()) ===
      null
    ) {
      const newtarget = document.getElementById("rafdiv" + field.toString());
      newtarget.appendChild(ele);
      dialogObj.appendTo("#createDropdownDialog" + field.toString());
    } else {
      dialogObj.appendTo("#createDropdownDialog" + field.toString());
    }
    //ReactDOM.createPortal(createDialogContent as any, dialogObj.element);
    const root = ReactDOMClient.createRoot(
      document.getElementById(`divManageChoiceListContent${field.toString()}`)
    );
    root.render(
      <ManageChoiceList
        allowAdd
        field={field.toString()}
        onSave={() => refreshDropdown()}
        onClose={() => dialogClose()}
        attributeJM={attributeJM}
      //moduleName={moduleName}
      ></ManageChoiceList>
    );
  };

  const CreateModalToggle = () => {
    //let dropdownTypeInitialvalues = createInstance(AttributeChoiceList);
    //dropdownTypeInitialvalues['ChoiceList'] = choiceOptions;
    createDynamicDialog();
  };

  const refreshDropdown = () => {
    removeSessionStorage(StorageKey.viewAttribs_modulename + moduleName);
    if (isNullOrUndefined(props.queryAttribute)) {
      getQueryAttribute(moduleName, field.toString())
        .then((queryAttribute) => {
          setAttributeJM(queryAttribute);
        })
        .catch((error) => error);
    }
    dialogClose();
  };

  useEffect(() => {
    const fetchDropdowItems = () => {
      if (isNullOrUndefined(props.queryAttribute)) {
        getQueryAttribute(moduleName, field.toString())
          .then((queryAttribute) => {
            setAttributeJM(queryAttribute);
          })
          .catch((error) => error);
      }
    };

    //if (didMountRef.current) {
    fetchDropdowItems();
    //}
    //else didMountRef.current = true
  }, []);

  //const items = getDataFromChildren(null, true, '', attributeJM);
  const items =
    uitype === "colorpicker"
      ? getDataFromChildren(
        null,
        required ? false : true,
        props.emptyString,
        attributeJM
      )
      : getDataFromChildren(
        React.Children.toArray(children),
        false,
        "",
        attributeJM
      );
  setTimeout(() => {
    setClickEvents(items);
  }, 100);

  const setClickEvents = (items1) => {
    const btnManageChoice: HTMLElement = document.getElementById(
      `btnManageChoice_${field.toString()}_${indexVal}`
    );
    if (isNotNullAndUndefined(btnManageChoice)) {
      btnManageChoice.onclick = () => {
        CreateModalToggle();
      };
    }

    if (isNotNullAndUndefined(items1) && items1.length > 0) {
      for (var i = 0; i < items1.length; i++) {
        const objItem = items1[i];
        const objElement: HTMLElement = document.getElementById(
          `dropdown_item_${objItem.Name}_${field.toString()}_${indexVal}`
        );
        if (isNotNullAndUndefined(objElement)) {
          objElement.onclick = () => {
            onItemClicked(
              objItem.DisplayName,
              objItem.Name,
              "customBtn_" + field.toString(),
              objItem.ColorCode
            );
          };
        }
      }
    }
  };

  /*const getDisplayNamebyName = (name) => {
        let displayName = '';
        let selectedItem = items && items.find(x => x.Name === name);
        displayName = selectedItem && selectedItem.DisplayName;
        return displayName;
    }*/

  const dropdownClick = (btnClass, containerId) => {
    let btnDiv = document.querySelector("." + btnClass);
    let containerDiv = document.getElementById(containerId);
    let btnDivWidth = btnDiv.clientWidth;
    containerDiv.style.width = btnDivWidth + "px";
  };
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? " " + props.labelClassName
    : "";

  return (
    <div
      className={
        isNotNullAndUndefined(formGroupClassName)
          ? formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className="col-12">
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => {
              return (
                <div>
                  {uitype === "colorpicker" ? (
                    isNotNullAndUndefined(props.mode) ? (
                      <div className="d-flex align-items-center">
                        <div className="w-100">
                          <div
                            className="customDropdownMenu"
                            id={
                              "drodownBtnComponent" +
                              field.toString() +
                              "_" +
                              indexVal
                            }
                          >
                            {items.map((item) => {
                              return (
                                <div
                                  key={item.Name}
                                  id={`dropdown_item_${item.Name
                                    }_${field.toString()}_${indexVal}`}
                                  className={"dropdown_item colorIcon"}
                                  onClick={() =>
                                    onItemClicked(
                                      item.DisplayName,
                                      item.Name,
                                      "customBtn_" + field.toString(),
                                      item.ColorCode
                                    )
                                  }
                                >
                                  {props.mode === "squareView" ? (
                                    <i
                                      className="fas fa-square"
                                      style={{ color: item.ColorCode }}
                                    ></i>
                                  ) : props.mode === "iconView" ? (
                                    <i
                                      className={props.iconName}
                                      style={{ color: item.ColorCode }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fas fa-circle"
                                      style={{ color: item.ColorCode }}
                                    ></i>
                                  )}
                                  <span className="w-100">
                                    {item.DisplayName}
                                  </span>
                                  {input.value === item.DisplayName ? (
                                    <span className="fas fa-check"></span>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                          <DropDownButtonComponent
                            id={`ddb_${field.toString()}`}
                            ref={(d) => (dropdownBtnComponent = d)}
                            target={
                              "#drodownBtnComponent" +
                              field.toString() +
                              "_" +
                              indexVal
                            }
                            style={{ fontWeight: 400 }}
                            cssClass={
                              "unset-custom-button-md customDropdownBtn customBtn_" +
                              field.toString()
                            }
                            onClick={() =>
                              dropdownClick(
                                "customBtn_" + field.toString(),
                                "drodownBtnComponent" +
                                field.toString() +
                                "_" +
                                this.indexVal
                              )
                            }
                          >
                            {IsNullOrWhiteSpace(input.value) ? (
                              isNotNullAndUndefined(props.emptyString) ? (
                                props.emptyString
                              ) : (
                                "None"
                              )
                            ) : (
                              <div>
                                {props.mode === "squareView" ? (
                                  // <i className="fas fa-square" style={{ color: items && items.find(x => x.DisplayName === input.value) && items.find(x => x.DisplayName === input.value).ColorCode }}></i>
                                  <i
                                    className="fas fa-square"
                                    style={{
                                      color: items?.find(
                                        (x) => x.DisplayName === input.value
                                      )?.ColorCode,
                                    }}
                                  ></i>
                                ) : props.mode === "iconView" ? (
                                  // <i className={props.iconName} style={{ color: items && items.find(x => x.DisplayName === input.value) && items.find(x => x.DisplayName === input.value).ColorCode }}></i>
                                  <i
                                    className={props.iconName}
                                    style={{
                                      color: items?.find(
                                        (x) => x.DisplayName === input.value
                                      )?.ColorCode,
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-circle"
                                    style={{
                                      color:
                                        items &&
                                        items.find(
                                          (x) => x.DisplayName === input.value
                                        ) &&
                                        items.find(
                                          (x) => x.DisplayName === input.value
                                        ).ColorCode,
                                    }}
                                  ></i>
                                )}
                                <span>{input.value}</span>
                              </div>
                            )}
                          </DropDownButtonComponent>
                          {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{input.value === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(input.value) ? '\u00a0' : getDisplayNamebyName(input.value)}</DropDownButtonComponent>*/}
                        </div>
                        {allowAdd && allowAdd === true && (
                          <div className="mx-2 w-auto">
                            <ButtonComponent
                              id={`btnManageChoice_${field.toString()}_${indexVal}`}
                              type="button"
                              cssClass="e-flat icon-only default filterIcon"
                              iconCss="fas fa-plus "
                            //onClick={() => CreateModalToggle()}
                            ></ButtonComponent>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="w-100">
                          <div
                            className="customDropdownMenu"
                            id={
                              "drodownBtnComponent" +
                              field.toString() +
                              "_" +
                              indexVal
                            }
                          >
                            {items.map((item) => {
                              return (
                                <div
                                  key={item.Name}
                                  id={`dropdown_item_${item.Name
                                    }_${field.toString()}_${indexVal}`}
                                  className="dropdown_item"
                                  style={{
                                    background: item.ColorCode,
                                    color:
                                      isNotNullAndUndefined(item.ColorCode) &&
                                        item.ColorCode !== "transparent" &&
                                        item.ColorCode !== ""
                                        ? "white"
                                        : "black",
                                  }}
                                  onClick={() =>
                                    onItemClicked(
                                      item.DisplayName,
                                      item.Name,
                                      "customBtn_" + field.toString(),
                                      item.ColorCode
                                    )
                                  }
                                >
                                  <span>{item.DisplayName}</span>
                                  {input.value === item.DisplayName ? (
                                    <span className="fas fa-check"></span>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                          <DropDownButtonComponent
                            id={`ddb_${field.toString()}`}
                            ref={(d) => (dropdownBtnComponent = d)}
                            target={
                              "#drodownBtnComponent" +
                              field.toString() +
                              "_" +
                              indexVal
                            }
                            style={{
                              color:
                                isNotNullAndUndefined(
                                  items &&
                                  items.find(
                                    (x) => x.DisplayName === input.value
                                  ) &&
                                  items.find(
                                    (x) => x.DisplayName === input.value
                                  ).ColorCode
                                ) &&
                                  items.find((x) => x.DisplayName === input.value)
                                    .ColorCode !== "transparent" &&
                                  items.find((x) => x.DisplayName === input.value)
                                    .ColorCode !== ""
                                  ? "white"
                                  : "black",
                              background:
                                items &&
                                items.find(
                                  (x) => x.DisplayName === input.value
                                ) &&
                                items.find((x) => x.DisplayName === input.value)
                                  .ColorCode,
                              fontWeight: 400,
                            }}
                            cssClass={
                              "unset-custom-button-md customDropdownBtn customBtn_" +
                              field.toString()
                            }
                            onClick={() =>
                              dropdownClick(
                                "customBtn_" + field.toString(),
                                "drodownBtnComponent" +
                                field.toString() +
                                "_" +
                                this.indexVal
                              )
                            }
                          >
                            {IsNullOrWhiteSpace(input.value)
                              ? isNotNullAndUndefined(props.emptyString)
                                ? props.emptyString
                                : isNotNullAndUndefined(required) && required
                                  ? null
                                  : "None"
                              : input.value}
                          </DropDownButtonComponent>
                          {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{input.value === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(input.value) ? '\u00a0' : getDisplayNamebyName(input.value)}</DropDownButtonComponent>*/}
                        </div>
                        {allowAdd && allowAdd === true && (
                          <div className="mx-2 w-auto">
                            <ButtonComponent
                              id={`btnManageChoice_${field.toString()}_${indexVal}`}
                              type="button"
                              cssClass="e-flat icon-only default filterIcon"
                              iconCss="fas fa-plus "
                            //onClick={() => CreateModalToggle()}
                            ></ButtonComponent>
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <DropDownListComponent
                      name={input.name}
                      value={input.value}
                      id={`ddl_${field.toString()}`}
                      change={(e) => {
                        if (e.isInteracted) {
                          input.onChange(
                            e.itemData !== null
                              ? e.itemData[fields.value]
                              : null
                          );
                          if (isNotNullAndUndefined(onChanged)) {
                            onChanged(
                              e.itemData !== null
                                ? e.itemData[fields.text]
                                : null,
                              e.itemData !== null
                                ? e.itemData[fields.value]
                                : null
                            );
                          }
                        }
                      }}
                      dataSource={items as any}
                      fields={fields}
                      //delayUpdate
                      allowFiltering={props.allowFiltering}
                      width={width}
                      readonly={disabled}
                      itemTemplate={isColorOption ? itemTemplate : null}
                      valueTemplate={isColorOption ? valueTemplate : null}
                      filterType="Contains"
                      cssClass={`${props.enableGroupByFormat ? 'unsetHeight ' : ''}
                        ${meta.error && meta.touched ? "inputFieldError" : null}`
                      }
                    //{...props}
                    />
                  )}

                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFGroupDropdown;
