import { ButtonComponent, ChipDirective, ChipListComponent, ChipsDirective } from '@syncfusion/ej2-react-buttons';
import { PropsWithChildren, useState } from "react";
import { showWarningToast } from '../Utility/RAFToastComponent';
import { Guid, IsNotNullOrWhiteSpace, isEmptyOrNull, isNotEmptyArray, isNotNullAndUndefined } from '../helpers/utils';
import { RAFDefaultFieldProps, RAFTagInputFieldProps, hasEmoji } from './RFFUtils';

interface IProps {
    inputValue: string[];
}

function RAFManageTagItems<T>(
    { field, onChanged, showAddBtn,
        moduleName, children,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFTagInputFieldProps<T> & IProps>,
) {

    const objGuid = Guid.newGuid();

    const [inputStateValue, setInputValue] = useState(props.inputValue);

    let inputValue = isNotEmptyArray(inputStateValue) ? inputStateValue : [];

    const addTagData = (event
        //, input
    ) => {
        if (IsNotNullOrWhiteSpace(event.target.value)) {
            if (isEmptyOrNull(hasEmoji(event.target.value))) {
                const tagData = inputValue;
                let tagUpdatedData = [];
                if (isNotNullAndUndefined(tagData)) {
                    tagUpdatedData = [...tagData, event.target.value];
                } else {
                    tagUpdatedData[0] = event.target.value;
                }
                //input.onChange(tagUpdatedData);
                setInputValue(tagUpdatedData);
                event.target.value = '';
                if (isNotNullAndUndefined(onChanged)) {
                    onChanged(tagUpdatedData);
                }
                hideTagsInputField();
            } else {
                showWarningToast(`${hasEmoji(event.target.value)}`);
            }
        }
    };

    const removeTagData = (indexToRemove
        //, input
    ) => {
        if (disabled === false) {
            const tagData = inputValue;
            let tagUpdatedData = [];
            if (isNotNullAndUndefined(tagData)) {
                tagUpdatedData = tagData && [...tagData.filter((_, index) => index !== indexToRemove)];
            }
            if (!isNotEmptyArray(tagUpdatedData)) {
                tagUpdatedData = null;
            }
            setInputValue(tagUpdatedData);
            //input.onChange(tagUpdatedData);
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(tagUpdatedData);
            }
        }

    };

    const removeItemOnBackSpaceClick = (indexToRemove
        //, input
    ) => {
        return; // on pressing back space inside a non empty text box, previous value getting removed 
        // const tagData = input.value;
        // let tagUpdatedData = [];
        // if (isNotNullAndUndefined(tagData) && tagData.length > 0) {
        //     tagUpdatedData = tagData.slice(0, -1);
        //     input.onChange(tagUpdatedData);
        //     if (isNotNullAndUndefined(onChanged)) {
        //         onChanged(tagUpdatedData);
        //     }
        // }
    };

    const addTagsInputField = () => {
        let buttonElement = document.getElementById(`tagButton_${objGuid}`);
        let inputElement = document.getElementById(`tagInputField_${objGuid}`);
        if (isNotNullAndUndefined(buttonElement) && isNotNullAndUndefined(inputElement)) {
            buttonElement.classList.add("hidden");
            inputElement.classList.remove("hidden");
        }
    };

    const hideTagsInputField = () => {
        let buttonElement = document.getElementById(`tagButton_${objGuid}`);
        let inputElement = document.getElementById(`tagInputField_${objGuid}`);
        if (isNotNullAndUndefined(buttonElement) && isNotNullAndUndefined(inputElement)) {
            buttonElement.classList.remove("hidden");
            inputElement.classList.add("hidden");
        }
    };

    return (
        <div className="raftags app-container p-0"
            //id={"rafdiv" + field.toString()}
            id={`rafdiv_${field.toString()}_${objGuid}`}
        >
            <div>
                {showAddBtn ?
                    <div className="tag-input">
                        <div className='row g-2'>
                            {inputValue && inputValue.length > 0 &&
                                <div className='col-auto'>
                                    <ChipListComponent id="chip-avatar" enableDelete={disabled === true ? false : true} deleted={e => removeTagData(e.index)}>
                                        <ChipsDirective>
                                            {inputValue && inputValue.map((tag, index) => (
                                                <ChipDirective key={index} text={tag} ></ChipDirective>
                                            ))}
                                        </ChipsDirective>
                                    </ChipListComponent>
                                </div>
                            }
                            {!disabled &&
                                <div id={`tagButton_${objGuid}`}
                                    className="col-auto"
                                //className="col-auto d-none d-sm-flex"
                                >
                                    <ButtonComponent type="button"
                                        cssClass="e-small link-button p-0"
                                        onClick={() => addTagsInputField()}
                                        id={`btn_addTag_${objGuid}_${field.toString()}`}
                                    >Add Tag</ButtonComponent>
                                </div>
                            }
                            {!disabled &&
                                <div className="col-auto d-flex">
                                    <div id={`tagInputField_${objGuid}`} className="hidden d-flex align-items-center">
                                        <input
                                            type="text"
                                            //onKeyUp={event => (event.key === 'Enter' ? addTagData(event, input) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null)}
                                            onKeyDown={(event) => { event.stopPropagation(); event.key === 'Enter' ? addTagData(event) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event) : null; }}
                                            placeholder="Type and press enter to add a tag"
                                            id={`input_addTag_${objGuid}_${field.toString()}`}
                                        />
                                        <ButtonComponent type="button" iconCss='fas fa-xmark'
                                            id={`btn_closeTag_${objGuid}_${field.toString()}`}
                                            onClick={() => hideTagsInputField()} title="Click to remove" cssClass="primary-custom-button e-outline border-0 ms-2">
                                        </ButtonComponent>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="tag-input">
                        {inputValue && inputValue.length > 0 &&
                            <ChipListComponent id="chip-avatar"
                                enableDelete={!disabled}
                                click={e => removeTagData(e.index)}>
                                <ChipsDirective>
                                    {inputValue && inputValue.map((tag, index) => (
                                        <ChipDirective key={index} text={tag} ></ChipDirective>
                                    ))}
                                </ChipsDirective>
                            </ChipListComponent>
                        }
                        {!disabled &&
                            <input
                                type="text"
                                //onKeyUp={event => (event.key === 'Enter' ? addTagData(event, input) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null)}
                                onKeyDown={(event) => { event.stopPropagation(); event.key === 'Enter' ? addTagData(event) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event) : null; }}
                                placeholder="Type and press enter to add a tag"
                            />
                        }
                    </div>
                }
            </div>
        </div >
    );
}

export default RAFManageTagItems;