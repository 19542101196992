import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import React, { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "../helpers/utils";
import { ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFUIType } from "../models/Common/RAFDataType";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFieldProps, isRequired } from "./RFFUtils";

interface IProps {
    uiType?: RAFUIType.RatingWithLabel | RAFUIType.Rating;
    valueJson: ValueJson[];
}

function RAFStarRating<T>({
    field,
    label,
    width,
    created,
    onChanged,
    fieldInnerText,
    children,
    maxlength,
    description,
    descriptionAsLabel,
    titleLocation,
    valueJson,

    uiType = RAFUIType.Rating,
    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,

    ...props }: PropsWithChildren<IProps & RAFFieldProps<T>>) {
    const [hoverActiveValue, setHoverActiveValue] = React.useState(null);

    //const attributesContext = useContext(RAFAttributesContext);
    //const queryAttributes = isNotNullAndUndefined(attributesContext) ? attributesContext.queryAttributes : null;

    const choiceOptions: { value: number, text: string; }[] = isNotEmptyArray(valueJson) ? valueJson.reduce((acc, item) => {
        acc.push({ value: item.Score, text: item.DisplayName ?? item.Name });
        return acc;
    }, []) : [];

    let labelClassName = isNotNullAndUndefined(props.labelClassName)
        ? props.labelClassName
        : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : "col-12";
    if (titleLocation === "Right") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-auto order-last";
        inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-3";
        inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
        labelClassName = "order-last";
    }

    function getLabelText(value: number) {
        const hoverItem = isNotNullAndUndefined(value) ? choiceOptions[value] : null;
        const displayLabel = isNotNullAndUndefined(hoverItem) ? hoverItem.text : null;
        return `${value} Star${value !== 1 ? "s" : ""}, ${displayLabel}`;
    }

    const onChangeInput = (itemIndex, input) => {
        let value = null;
        if (isNotEmptyArray(choiceOptions) && isNotNullAndUndefined(itemIndex) && itemIndex > 0) {
            const selectedItem = choiceOptions[itemIndex - 1];
            value = isNotNullAndUndefined(selectedItem) ? selectedItem.text : null;
        }
        if (isNotNullAndUndefined(value)) {
            input.onChange(value);
            if (onChanged) {
                onChanged(value);
            }
        }
    };

    const onChangeActive = (event, itemIndex) => {
        if (uiType === RAFUIType.RatingWithLabel && itemIndex > 0 && isNotNullAndUndefined(itemIndex) && isNotEmptyArray(choiceOptions)) {
            const hoverItem = choiceOptions[itemIndex - 1];
            const displayLabel = isNotNullAndUndefined(hoverItem) ? hoverItem.text : null;
            setHoverActiveValue(displayLabel);
        } else {
            setHoverActiveValue(null);
        }
    };

    const getRatingValueIndex = (text) => {
        let index = null;
        if (isNotNullAndUndefined(text) && isNotEmptyArray(choiceOptions)) {
            const itemIndex = choiceOptions.findIndex((item) => item.text === text);
            if (itemIndex !== -1) {
                index = itemIndex + 1;
            } else {
                index = null;
            }
        }
        return index;
    };

    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName + " form-group"
                    : "form-group"
            }
        >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                        descriptionAsLabel={descriptionAsLabel}
                    />
                )}
                <Field
                    name={field.toString()}
                    {...(props.initialValue ? { initialValue: props.initialValue } : {})}
                    //validate={validate === true ? (required && isRequired) : null}
                    {...(props.validators
                        ? {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null,
                                        ...props.validators
                                    )
                                    : null,
                        }
                        : {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null
                                    )
                                    : null,
                        })}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                >
                    {({ input }) => {
                        return (
                            <div className={inputFieldClassName}>
                                <div
                                    className={
                                        isNotNullAndUndefined(fieldInnerText)
                                            ? "fieldInnerText d-flex align-items-center flex-wrap"
                                            : "d-flex align-items-center flex-wrap"
                                    }
                                >
                                    <Rating
                                        id={`rating_${field.toString()}`}
                                        name={input.name}
                                        value={getRatingValueIndex(input.value)}
                                        precision={1}
                                        getLabelText={getLabelText}
                                        onChange={(e, newValue) => {
                                            if (e.isTrusted) {
                                                onChangeInput(newValue, input);
                                            }
                                        }}
                                        onChangeActive={(event, newHover) => {
                                            onChangeActive(event, newHover);
                                        }}
                                        disabled={disabled}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }}
                                            fontSize="inherit"
                                        />}
                                        {...(isNotEmptyArray(choiceOptions)
                                            ? {
                                                max: choiceOptions.length
                                            }
                                            : {})}
                                    />
                                    {uiType === RAFUIType.RatingWithLabel && (
                                        <div>{IsNotNullOrWhiteSpace(hoverActiveValue) ? hoverActiveValue : input.value}</div>
                                    )}
                                </div>
                                {props.hideRequiredMessage !== true ? (
                                    <RAFFieldError name={field.toString()} />
                                ) : (
                                    ""
                                )}
                            </div>
                        );
                    }}
                </Field>
            </div>
        </div>
    );
}

export default React.memo(RAFStarRating);