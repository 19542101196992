import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import {
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
} from "../../RAFComponents/helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators, RAFFieldError } from "./RAFForm";
import {
  hasEmoji,
  isRequired,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFTextBoxProps
} from "./RFFUtils";

//const RAFTextBox = ({ field, label, required }) => {
//const emojiRegex = /[\uD800-\uDFFF].|[\u200D\uFE0F\u20E3\u00a9\u00ae\u3030\u303D\u2000-\u2BFF\u3200-\u33FF\uF900-\uFD3F\uFE00-\uFE6F\uFEFF]/g;

function RAFTextBox<T>({
  field,
  label,
  width,
  created,
  onChanged,
  onInputBlur,
  fieldInnerText,
  children,
  maxlength,
  description,
  descriptionAsLabel,
  titleLocation,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFTextBoxProps<T>>) {
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : "col-12";
  if (titleLocation === "Right") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-auto order-last";
    inputFieldClassName = "col";
  } else if (titleLocation === "Left") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-3";
    inputFieldClassName = "col";
  } else if (titleLocation === "Bottom") {
    labelClassName = "order-last";
  }

  const onFocusOut = (value) => {
    if (isNotNullAndUndefined(onInputBlur))
      if (isNotNullAndUndefined(value) && !IsNullOrWhiteSpace(value)) {
        onInputBlur(value);
      }
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <Field
          name={field.toString()}
          {...(props.initialValue ? { initialValue: props.initialValue } : {})}
          //validate={validate === true ? (required && isRequired) : null}
          {...(props.validators
            ? {
              validate:
                validate === true
                  ? composeValidators(
                    required === true ? isRequired : null,
                    hasEmoji,
                    ...props.validators
                  )
                  : null,
            }
            : {
              validate:
                validate === true
                  ? composeValidators(
                    required === true ? isRequired : null,
                    hasEmoji
                  )
                  : null,
            })}
          allowNull
          parse={(value) => (value === "" ? null : value)}
        >
          {({ input, meta }) => (
            <div className={inputFieldClassName}>
              <div
                className={
                  isNotNullAndUndefined(fieldInnerText)
                    ? "fieldInnerText d-flex"
                    : "d-flex"
                }
              >
                <TextBoxComponent
                  id={`ttx_${field.toString()}`}
                  value={input.value}
                  name={input.name}
                  change={(e) => {
                    if (e.isInteracted) {
                      //eValue = eValue.replace(emojiRegex, '');
                      input.onChange(e.value);
                      if (isNotNullAndUndefined(onChanged)) {
                        onChanged(e.value);
                      }
                    }
                  }}
                  type="text"
                  created={() =>
                    isNotNullAndUndefined(created) ? created(input.name) : null
                  }
                  width={width && width !== null ? width : "100%"}
                  showClearButton={showClearButton}
                  placeholder={props.placeholder}
                  disabled={disabled}
                  cssClass={
                    meta.error && meta.touched ? "inputFieldError" : null
                  }
                  htmlAttributes={
                    isNotNullAndUndefined(maxlength)
                      ? { maxlength: maxlength }
                      : null
                  }
                  blur={(e) => onFocusOut(e.value)}
                />
                {isNotNullAndUndefined(fieldInnerText) && (
                  <span className="fieldInnerLabel">{fieldInnerText}</span>
                )}
              </div>

              {props.hideRequiredMessage !== true ? (
                <RAFFieldError name={field.toString()} />
              ) : (
                ""
              )}
            </div>
          )}
        </Field>
      </div>
    </div>
  );
}

export default RAFTextBox;
