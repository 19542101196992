import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import * as React from "react";
import { PropsWithChildren, Reducer, useReducer } from "react";
import { Field, FieldInputProps, FormRenderProps } from "react-final-form";
import {
  Guid,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../RAFComponents/helpers/utils";
import { RAFButtonConstant } from "../../constants/Common/Constants";
import RAFButtonComponent from "../Navigation/RAFButtonComponent";
import { getQueryAttribute } from "../helpers/AppHelper";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFormContext, RAFMultiSelectDropdownFieldProps, isRequiredForArray, setFormValue } from "./RFFUtils";

interface IProps {
  disableItems?: any[];
  allowFiltering?: boolean;
  moduleName?: string;
  allowAdd?: boolean;
  emptyString?: string;
  queryAttribute?: QueryAttributeJM;
  uitype?: "dropdown" | "checkbox";
  showAllCheckBoxItems?: boolean;
  showMinimumItems?: number;
}

interface IState {
  showAllCheckBoxItems: boolean;
}

const getDataFromChildren = (
  children?,
  addEmpty?: boolean,
  emptyString?: string,
  attributeJM?: QueryAttributeJM
) => {
  let retVal: ValueJson[] = [];
  if (isNotNullAndUndefined(children) && children.length > 0) {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: null,
        DisplayName: emptyString || "None",
        ColorCode: "#B3B6B7",
      });
    }
    React.Children.forEach(children, (child, i) => {
      //retVal.push({ ...child["props"], label: (child["props"]["children"] || child["props"]["label"]) });
      retVal.push({
        Id: i + 1,
        Name: child["props"]["value"],
        DisplayName: child["props"]["children"] || child["props"]["label"],
        ColorCode: child["props"]["colorCode"],
      });
    });
  } else {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: "",
        DisplayName: emptyString || "None",
        ColorCode: "transparent",
      });
    }
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
  }
  return retVal;
};

function RAFMultiSelectDropdown<T>({
  field,
  label,
  width,
  onChanged,
  children,
  disableItems,
  allowFiltering,
  description,
  descriptionAsLabel,
  titleLocation,
  showMinimumItems = 5,
  showAllCheckBoxItems = false,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFMultiSelectDropdownFieldProps<T> & IProps>) {

  const rafFormContextValue: FormRenderProps = React.useContext(RAFFormContext);

  const [attributeJM, setAttributeJM] = React.useState(null);
  let multiSelectComponent: MultiSelectComponent;
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : "col-12";
  if (titleLocation === "Right") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-auto order-last";
    inputFieldClassName = "col";
  } else if (titleLocation === "Left") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-3";
    inputFieldClassName = "col";
  } else if (titleLocation === "Bottom") {
    labelClassName = "order-last";
  }

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showAllCheckBoxItems: showAllCheckBoxItems === true ? showAllCheckBoxItems : false,
    }
  );

  React.useEffect(() => {
    const fetchDropdowItems = () => {
      if (isNullOrUndefined(props.queryAttribute)) {
        getQueryAttribute(props.moduleName, field.toString())
          .then((queryAttribute) => {
            setAttributeJM(queryAttribute);
          })
          .catch((error) => error);
      } else {
        setAttributeJM(props.queryAttribute);
      }
    };

    //if (didMountRef.current) {
    fetchDropdowItems();
    //}
    //else didMountRef.current = true
  }, [props.queryAttribute]);

  const fields = {
    text: "DisplayName",
    value: "Name",
    itemCreated: (e) => {
      if (isNotEmptyArray(disableItems)) {
        disableItems.forEach((item) => {
          if (isNotNullAndUndefined(item) && item === e.curData.value) {
            e.item.classList.add("e-disabled");
            e.item.classList.add("e-overlay");
          }
        });
      }
    },
  };

  //let items = getDataFromChildren(React.Children.toArray(children));
  const items = getDataFromChildren(
    React.Children.toArray(children),
    false,
    "",
    attributeJM
  );

  let selectedItems = [];

  const onChange = (e, input: FieldInputProps<any, HTMLElement>) => {
    const myArrayFiltered = items.filter((el) => {
      return e.value.some((f) => {
        return f === el.DisplayName || f === el.Name;
      });
    });
    selectedItems = myArrayFiltered.map((e) => {
      return e.Name;
    });

    input.onChange(selectedItems);
    if (isNotNullAndUndefined(onChanged)) {
      //onChanged(JSON.stringify(selectedItems));
      onChanged(selectedItems);
    }
  };

  const setValueDelay = (inputValue) => {
    if (isNotNullAndUndefined(inputValue) && !IsNullOrWhiteSpace(inputValue)) {
      setTimeout(() => {
        if (multiSelectComponent) {
          multiSelectComponent.value = inputValue;
        }
      }, 100);
    }
  };

  const onToggleCheckboxItemsCount = () => {
    setState({ showAllCheckBoxItems: !state.showAllCheckBoxItems });
  };

  const onChangeSelectAllCheckBox = (isChecked: boolean) => {
    if (isChecked) {
      const allValues = isNotEmptyArray(items) ? items.map((e) => e.Name) : [];
      setFormValue(rafFormContextValue, field.toString(), allValues);
    } else {
      setFormValue(rafFormContextValue, field.toString(), []);
    }
  };

  const getCheckboxMultiSelectContent = () => {
    const { showAllCheckBoxItems } = state;

    const checkBoxItems = showAllCheckBoxItems ? items :
      isNotEmptyArray(items) && showMinimumItems > 0 && items.length > showMinimumItems ? items.slice(0, showMinimumItems) : items;

    return (
      <div className="row g-2">
        {isNotEmptyArray(items) && items.length > 3 && (
          <Field name={field.toString()}>
            {({ input }) => {
              const selectedItems = input.value;
              const isSelectedAllValues = isNotEmptyArray(selectedItems) && selectedItems.length === items.length;
              return (
                <div className={isNotNullAndUndefined(props.dropdownCheckboxDivClass) ? props.dropdownCheckboxDivClass : "col-12"}
                  key={`${field.toString()}_selectAll`}
                >
                  <CheckBoxComponent
                    name={`${field.toString()}_selectAll`}
                    value={'Select All'}
                    className="custom-control-input"
                    change={(e) => onChangeSelectAllCheckBox(e.checked)}
                    checked={isSelectedAllValues}
                    label="Select All"
                  />
                </div>
              );
            }}
          </Field>
        )}
        {checkBoxItems.map((item) => {
          let indexVal: string = Guid.newGuid();
          return (
            <div className={isNotNullAndUndefined(props.dropdownCheckboxDivClass) ? props.dropdownCheckboxDivClass : "col-12"} key={item.Id}>
              <Field
                name={field.toString()}
                component="input"
                type="checkbox"
                value={item.Name}
                className="custom-control-input"
                id={item.Name + "_" + indexVal}
                disabled={disabled}
              />

              <label
                className="custom-control-label"
                htmlFor={item.Name + "_" + indexVal}
              >
                <span className="ms-2 ecllipseFirstLine">{item.DisplayName}</span>
              </label>
            </div>
          );
        })}
        {isNotEmptyArray(items) && items.length > showMinimumItems && (
          <div className="col-12">
            <RAFButtonComponent
              action={showAllCheckBoxItems ? RAFButtonConstant.HideLess : RAFButtonConstant.ShowMore}
              className="new_style link-button raf_sm text-decoration-underline"
              showIcon={false}
              onClick={onToggleCheckboxItemsCount}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className={inputFieldClassName}>
          <Field
            name={field.toString()}
            {...(isNotEmptyArray(props.initialValue)
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequiredForArray : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequiredForArray : null,
                    )
                    : null,
              })}
            allowNull
            parse={(value) => (isNotEmptyArray(value) ? value : null)}
          >
            {({ input, meta }) => {
              return (
                <div>
                  {props.uitype === "checkbox" ? (
                    getCheckboxMultiSelectContent()
                  ) : (
                    <MultiSelectComponent
                      id={`msc_${field.toString()}`}
                      ref={(m) => (multiSelectComponent = m)}
                      name={input.name}
                      value={input.value}
                      filterType="Contains"
                      //mode="CheckBox"
                      showDropDownIcon
                      popupHeight="350px"
                      dataSource={items as any}
                      fields={fields}
                      showClearButton={showClearButton}
                      width={width}
                      readonly={disabled}
                      placeholder={props.placeholder}
                      change={(e) => {
                        if (e.isInteracted) {
                          onChange(e, input);
                        }
                      }}
                      cssClass={
                        meta.error && meta.touched ? "inputFieldError" : null
                      }
                      allowFiltering={allowFiltering}
                    >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                  )}

                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFMultiSelectDropdown;
