import { debounce } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  ChangeEventArgs,
  DropDownListComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  DialogComponent,
  Tooltip,
  TooltipComponent,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import * as React from "react";
import { Field, FieldInputProps, FormRenderProps } from "react-final-form";
import { getEntityByName } from "../../RAFMaster/helpers/RMutils";
import { Constants } from "../../constants/Common/Constants";
import { PreventFocusOnDialogOpen } from "../Dialog/SFDialogUtils";
import { createInstance } from "../Utility/FormUtility";
import {
  getDefaultFieldNameByModule,
  getDisplayNameByModuleName,
} from "../helpers/RAFMenuHelper";
import {
  ConvertToElementID,
  IsNullOrWhiteSpace,
  deepEqual,
  isNotNullAndUndefined,
} from "../helpers/utils";
import { ILookupRow } from "../models/CompositeTypes/ILookupRow";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import RAFLookupGrid from "./RAFLookupGrid";
import { RAFDataManager, RAFUrlAdaptor } from "./RAFUrlAdaptor";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFLookupFieldProps,
  getFormValue,
  isRequired,
  setFormValue,
} from "./RFFUtils";

interface IState {
  initialValues: {};
  showCreateContent?: boolean;
  showLookupGridContent?: boolean;
  createDialogHeader?: string;
}

class RAFLookupCC<T> extends React.Component<RAFLookupFieldProps<T>, IState> {
  //static defaultProps: Partial<RAFLookupFieldProps<T>>;

  static defaultProps = {
    ...RAFDefaultFieldProps,
    showFullList: true,
    closeToolTip: true,
    SearchCreateOptionMode: "Default",
  };

  _isMounted = false;

  private field = this.props.field.toString();
  private fields = { text: "Value", value: "Value" };
  private objLookupRow = isNotNullAndUndefined(this.props.type)
    ? (createInstance(this.props.type) as ILookupRow)
    : null;
  private searchedText;
  private isFiltering = false;
  private tooltip: Tooltip;
  private skip = 0;
  private take: number = Constants.DropdownFetchCount;
  private filteredSkip = 0;
  private filteredTake: number = Constants.DropdownFetchCount;
  private dropDownListComponent: DropDownListComponent;
  private rafFormContextValue: FormRenderProps;
  //private SearchCreateOptionMode: "Footer" | "Default" = isNotNullAndUndefined(this.props.SearchCreateOptionMode) ? this.props.SearchCreateOptionMode : "Default";

  //private showlookUpGrid = isNotNullAndUndefined(this.props.showFullList) ? this.props.showFullList : true;
  private showFullList =
    this.props.showFullList && isNotNullAndUndefined(this.props.moduleName);
  private showCreateBtn =
    (this.props.showCreateButton === true ||
      (isNotNullAndUndefined(this.objLookupRow) &&
        this.objLookupRow.isOptionCreatable() === true)) &&
    isNotNullAndUndefined(this.props.createform);

  constructor(props) {
    super(props);
    if (isNotNullAndUndefined(this.props.textField)) {
      this.fields.text = this.props.textField;
    }
    this.state = {
      initialValues: null,
      showCreateContent: false,
      showLookupGridContent: false,
      createDialogHeader: "",
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.customFilter, this.props.customFilter)) {
      // Update your custom filter here
      this.onUpdateDropdownCustomFilter();
    }
  }

  onUpdateDropdownCustomFilter = () => {
    if (isNotNullAndUndefined(this.dropDownListComponent)) {
      this.dropDownListComponent.dataSource = this.getCustomerData();
      this.dropDownListComponent.refresh();
    }
    setFormValue(this.rafFormContextValue, this.field.toString(), null);
    setFormValue(this.rafFormContextValue, this.field.toString() + "UID", null);
    this.dropDownListComponent.value = null;
  };

  getCustomerData() {
    return new RAFDataManager({
      adaptor: new RAFUrlAdaptor({
        Skip: this.skip,
        Take: this.take,
        CustomFilter: this.props.customFilter,
        entityName: this.props.moduleName,
      }),
      crossDomain: true,
      url: isNotNullAndUndefined(this.props.url)
        ? Constants.baseAPIUrl + this.props.url
        : Constants.baseAPIUrl + this.objLookupRow.getLookupUrl(),
      requestType: "POST",
      //enableCaching: true,
      //offline: true
      headers: [],
    });
  }

  private customerData: RAFDataManager = new RAFDataManager({
    adaptor: new RAFUrlAdaptor({
      Skip: this.skip,
      Take: this.take,
      CustomFilter: this.props.customFilter,
      entityName: this.props.moduleName,
    }),
    crossDomain: true,
    url: isNotNullAndUndefined(this.props.url)
      ? Constants.baseAPIUrl + this.props.url
      : Constants.baseAPIUrl + this.objLookupRow.getLookupUrl(),
    requestType: "POST",
    //enableCaching: true,
    //offline: true
    headers: [],
  });

  onFiltering = (args) => {
    args.preventDefaultAction = true;
    this.setFilteringDebounce(args);
  };

  setFilteringDebounce = debounce((args) => {
    this.isFiltering = true;
    this.filteredSkip = 0;
    this.filteredTake = Constants.DropdownFetchCount;
    this.customerData.adaptor = new RAFUrlAdaptor({
      Skip: this.filteredSkip,
      Take: this.filteredTake,
      CustomFilter: this.props.customFilter,
      entityName: this.props.moduleName,
    });
    let query = new Query();
    query =
      args.text !== ""
        ? query.where(this.field, "contains", args.text, true)
        : query;
    this.searchedText = args.text;
    setTimeout(() => {
      args.updateData(this.customerData, query);
    }, 500);
  }, 300);

  actionBegin = (args) => {
    if (args.query === undefined) {
      //temp fix to prevent repeated api calls
      args.cancel = true;
    }
  };

  actionComplete = (e, inputValue) => {
    if (isNotNullAndUndefined(inputValue)) {
      let uid = getFormValue(this.rafFormContextValue, this.field + "UID");
      const currentFormValue = getFormValue(this.rafFormContextValue, this.field);//check condition added to avoid duplicate by text
      if (
        isNotNullAndUndefined(inputValue) &&
        isNotNullAndUndefined(uid) &&
        !this.isFiltering
      ) {
        if (isNotNullAndUndefined(e.result)) {
          const objIndexForUID = e.result.findIndex((x) => x["UID"] === uid);
          const objIndexForValue = e.result.findIndex((x) => x["Value"] === currentFormValue);//check condition added to avoid duplicate by text
          //const objIndex = e.result.findIndex(x => x[fields.value] === inputValue);
          //let objItem = e.result.find(x => x['UID'] === uid);
          //let newValue = isNotNullAndUndefined(objItem) ? objItem.Value : null;
          //if (isNotNullAndUndefined(newValue) && newValue !== inputValue) {
          //setFormValue(this.rafFormContextValue, this.field, newValue);
          //}
          if (objIndexForUID < 0) {
            if (objIndexForValue < 0) {
              //check condition added to avoid duplicate by text
              e.result.push({ [this.fields.value]: inputValue });
            }
          }
        }
      }
    }

    let { addEmpty, emptyString } = this.props;
    let emptyValue = {
      UID: null,
      [this.fields.value]: isNotNullAndUndefined(emptyString)
        ? emptyString
        : "None",
    };
    let dropdownItems = isNotNullAndUndefined(e.result) ? [...e.result] : [];

    if (addEmpty === true && IsNullOrWhiteSpace(this.searchedText)) {
      if (isNotNullAndUndefined(dropdownItems)) {
        dropdownItems.splice(0, 0, emptyValue);
      } else {
        dropdownItems = [];
        dropdownItems.splice(0, 0, emptyValue);
      }
    }
    e.result = R.uniq(dropdownItems);
  };

  created = () => {
    if (this.dropDownListComponent) {
      this.tooltip = new Tooltip({
        beforeRender: this.onBeforeRender,
        content: "Loading...",
        position: "TopCenter",
        target: isNotNullAndUndefined(this.dropDownListComponent.element)
          ? `#${ConvertToElementID(
            this.dropDownListComponent.element.id
          )}_popup .e-list-item`
          : ".e-list-item",
      });
      this.tooltip.appendTo("body");
    }
  };

  onBeforeRender = (args: TooltipEventArgs) => {
    if (isNotNullAndUndefined(this.tooltip))
      this.tooltip.content = args.target.textContent;
  };

  opened = () => {
    //args.preventDefaultAction = true;
    //let start = 7;
    //let end = 12;
    let listElement: HTMLElement = (this.dropDownListComponent as any).list;
    listElement.addEventListener("scroll", (e) => {
      if (
        listElement.scrollTop + listElement.offsetHeight + 1 >=
        listElement.scrollHeight
      ) {
        this.setScrollDebounce(e);
      }
    });
  };

  setScrollDebounce = debounce(() => {
    if (isNotNullAndUndefined(this.dropDownListComponent)) {
      let filterQuery = isNotNullAndUndefined(this.dropDownListComponent.query)
        ? this.dropDownListComponent.query.clone()
        : new Query();
      const searchedText =
        this.dropDownListComponent["searchKeyModule"]["element"]["value"];
      if (isNotNullAndUndefined(searchedText) && searchedText !== "") {
        this.filteredSkip += this.filteredTake;
        filterQuery = filterQuery.where(
          this.field,
          "contains",
          searchedText,
          true
        );
        this.customerData.adaptor = new RAFUrlAdaptor({
          Skip: this.filteredSkip,
          Take: this.filteredTake,
          CustomFilter: this.props.customFilter,
          entityName: this.props.moduleName,
        });
      } else {
        this.skip += this.take;
        this.customerData.adaptor = new RAFUrlAdaptor({
          Skip: this.skip,
          Take: this.take,
          CustomFilter: this.props.customFilter,
          entityName: this.props.moduleName,
        });
      }
      this.customerData
        //.executeQuery(filterQuery.range(start, end))
        .executeQuery(filterQuery)
        .then((event) => {
          //start = end;
          //end += 5;
          if (
            isNotNullAndUndefined(event) &&
            isNotNullAndUndefined((event as any).result) &&
            isNotNullAndUndefined(this.dropDownListComponent)
          ) {
            this.dropDownListComponent.addItem(
              (event as any).result as { [key: string]: Object; }[]
            );
          }
        })
        .catch();
    }
  }, 500);

  onClose = () => {
    //let openTooltips = document.querySelectorAll('div.e-tooltip-wrap.e-popup-open[id^="tooltip_"]');
    let openTooltips = document.querySelectorAll(
      "div.e-tooltip-wrap.e-popup-open"
    );
    //if (closeToolTip === true) {
    if (isNotNullAndUndefined(openTooltips)) {
      openTooltips.forEach((x) => {
        x.classList.add("hidden");
      });
    }
    if (isNotNullAndUndefined(this.tooltip)) {
      this.tooltip.close();
    }
  };

  onChange = (e: ChangeEventArgs, input: FieldInputProps<any, HTMLElement>) => {
    const itemData = (isNotNullAndUndefined(e) && isNotNullAndUndefined(e.itemData)) ? e.itemData : null;
    setFormValue(this.rafFormContextValue, this.field + "UID", itemData !== null ? itemData["UID"] : null);
    input.onChange(itemData !== null ? itemData["Value"] : null);
    if (isNotNullAndUndefined(this.props.onChanged)) {
      if (isNotNullAndUndefined(itemData)) {
        this.props.onChanged(itemData["Value"], itemData["UID"]);
      } else {
        this.props.onChanged(null, null);
      }
    }
  };

  setValueDelay = (inputValue) => {
    //if (isNotNullAndUndefined(inputValue) && !IsNullOrWhiteSpace(inputValue)) {
    setTimeout(() => {
      if (isNotNullAndUndefined(this.dropDownListComponent)) {
        this.dropDownListComponent.value = inputValue ?? null;
      }
    }, 500);
    //}
  };

  noRecordsTemplate1 = (): JSX.Element => {
    return this.showCreateBtn === true &&
      this.props.SearchCreateOptionMode === "Default" ? (
      <div>
        <button
          type="button"
          className="link-button"
          onClick={() => this.openCreateDialog()}
        >
          {" "}
          {'Create "' + this.searchedText + '"'}{" "}
        </button>
      </div>
    ) : (
      <span className="foot">No record found</span>
    );
  };

  noRecordsTemplate = () => {
    let noRecordsTemplate = "<span class='foot'>No record found</span>";
    return noRecordsTemplate;
  };

  footerTemplate = () => {
    return (
      <div className="row g-0 justify-content-between border-top flex-nowrap">
        {this.props.SearchCreateOptionMode === "Footer" &&
          this.showCreateBtn === true && (
            <div className="col-auto d-flex">
              <ButtonComponent
                type="button"
                iconCss="fas fa-plus"
                className="link-button custom-link-btn custom-button-sm"
                onClick={() => this.openCreateDialog()}
                id={`btn_create_lookupData${this.field.toString()}`}
              >
                Create
              </ButtonComponent>
            </div>
          )}
        {this.props.SearchCreateOptionMode === "Footer" &&
          this.showFullList === true && (
            <div className="col d-flex">
              <ButtonComponent
                type="button"
                className="link-button custom-link-btn ms-auto custom-button-sm"
                onClick={() => this.showLookUpGridDialogOpen()}
                id={`btn_show_lookupGrid${this.field.toString()}`}
              >
                <span className="ecllipseFirstLine work-break-all text-wrap">
                  Show full list
                </span>
              </ButtonComponent>
            </div>
          )}
      </div>
    );
  };

  // create dialog starts

  openCreateDialog = () => {
    let openTooltips = document.querySelectorAll("div.e-ddl.e-popup-open");
    if (isNotNullAndUndefined(openTooltips)) {
      openTooltips.forEach((x) => {
        x.classList.add("hidden");
      });
    }
    if (isNotNullAndUndefined(this.dropDownListComponent)) {
      this.dropDownListComponent.hidePopup();
    }

    getEntityByName(this.props.moduleName).then(async (entity) => {
      let initialValues = {};
      if (isNotNullAndUndefined(this.props.createformdefaultfield)) {
        initialValues[this.props.createformdefaultfield] = this.searchedText;
      } else {
        let defaultField = await getDefaultFieldNameByModule(
          this.props.moduleName
        );
        initialValues[defaultField] = this.searchedText;
      }

      const createFormInitialValues = this.props.createFormInitialValues;
      if (isNotNullAndUndefined(createFormInitialValues)) {
        createFormInitialValues.forEach((item) => {
          initialValues[item.key] = item.value;
        });
      }

      if (this._isMounted) {
        this.setState({
          showCreateContent: true,
          initialValues: initialValues,
          createDialogHeader: getDisplayNameByModuleName(
            this.props.moduleName,
            isNotNullAndUndefined(entity) ? entity.DisplayName : null
          ),
        });
      }
    });
  };

  showCreateContent = () => {
    if (
      this.state.showCreateContent &&
      isNotNullAndUndefined(this.state.initialValues) &&
      isNotNullAndUndefined(this.props.createform)
    ) {
      const Createform1 = this.props.createform;
      let initialValues = this.state.initialValues;
      // if (isNotNullAndUndefined(this.props.createformdefaultfield)) {
      //   initialValues[this.props.createformdefaultfield] = this.searchedText;
      // } else {
      //   let defaultField = await getDefaultFieldNameByModule(this.props.moduleName);
      //   initialValues[defaultField] = this.searchedText;
      // }
      // /*if (isNotNullAndUndefined(props.createformaccountfield)) {
      //               initialValues[props.createformaccountfield] = props.account;
      //       }
      //           if (isNotNullAndUndefined(props.createformaccountUIDfield)) {
      //               initialValues[props.createformaccountUIDfield] = props.accountUID;
      //       }*/
      // const createFormInitialValues = this.props.createFormInitialValues;
      // if (isNotNullAndUndefined(createFormInitialValues)) {
      //   createFormInitialValues.forEach((item) => {
      //     initialValues[item.key] = item.value;
      //   });
      // }
      return (
        <div>
          <Createform1
            as2={this.props.createform}
            isActive
            moduleName={this.props.moduleName}
            onSave={this.refreshLookupControl.bind(this)}
            onClose={() => this.closeCreateDialog()}
            {...(initialValues ? { initialValues: initialValues } : {})}
          ></Createform1>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  closeCreateDialog = () => {
    if (this._isMounted) {
      this.setState({
        initialValues: null,
        showCreateContent: false,
        createDialogHeader: "",
      });
    }
  };

  // create dialog ends

  // lookUp dialog starts
  showLookUpGridDialogOpen = () => {
    let openTooltips = document.querySelectorAll("div.e-ddl.e-popup-open");
    if (isNotNullAndUndefined(openTooltips)) {
      openTooltips.forEach((x) => {
        x.classList.add("hidden");
      });
    }
    if (isNotNullAndUndefined(this.dropDownListComponent)) {
      this.dropDownListComponent.hidePopup();
    }
    if (this._isMounted) {
      this.setState({ showLookupGridContent: true });
    }
  };

  showLookupGridContent = () => {
    let { showLookupGridContent } = this.state;
    if (
      showLookupGridContent === true &&
      isNotNullAndUndefined(this.props.moduleName)
    ) {
      return (
        <RAFLookupGrid
          rowclicked={(value) => this.setLookUpValue(value)}
          onClose={() => this.showLookupGridDialogClose()}
          customFilter={this.props.customFilter}
          moduleName={this.props.moduleName}
          mode="input"
          isActive
          {...this.props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  async setLookUpValue(value) {
    if (isNotNullAndUndefined(value)) {
      let objectName = !IsNullOrWhiteSpace(this.props.selectedField)
        ? value[this.props.selectedField]
        : null;
      let uid = value.UID;
      if (IsNullOrWhiteSpace(objectName)) {
        let defaultField = await getDefaultFieldNameByModule(
          this.props.moduleName
        );
        objectName = value[defaultField];
      }
      if (isNotNullAndUndefined(uid) && isNotNullAndUndefined(objectName)) {
        this.refreshLookupControl(uid, objectName);
      } else {
        this.showLookupGridDialogClose();
      }
    } else {
      this.showLookupGridDialogClose();
    }
  }

  refreshLookupControl = (entityId, objectName) => {
    //console.log('entityId', entityId, 'objectName', objectName, 'rafFormContextValue', rafFormContextValue, 'dropDownListComponent', dropDownListComponent, isNotNullAndUndefined(dropDownListComponent));

    if (this._isMounted) {
      this.setState(
        {
          initialValues: null,
          showCreateContent: false,
          showLookupGridContent: false,
        },
        () => {
          if (isNotNullAndUndefined(this.dropDownListComponent)) {
            const ddd = this.dropDownListComponent;
            setTimeout(() => {
              //console.log('dropDownListComponent2', ddd);
              this.searchedText = "";
              let filterQuery = new Query();
              this.filteredSkip = 0;
              this.customerData.adaptor = new RAFUrlAdaptor({
                Skip: this.filteredSkip,
                Take: this.filteredTake,
                CustomFilter: this.props.customFilter,
                entityName: this.props.moduleName,
              });
              ddd.dataSource = this.customerData;
              ddd.query = filterQuery;
              ddd.value = objectName;
              ddd.text = objectName;
              ddd.refresh();
            }, 100);

            if (
              isNotNullAndUndefined(entityId) &&
              isNotNullAndUndefined(objectName) &&
              isNotNullAndUndefined(this.rafFormContextValue) &&
              isNotNullAndUndefined(this.rafFormContextValue.form) &&
              isNotNullAndUndefined(this.rafFormContextValue.form.mutators)
            ) {
              this.rafFormContextValue.form.mutators.setValue(
                this.field + "UID",
                entityId
              );
              this.rafFormContextValue.form.mutators.setValue(
                this.field,
                objectName
              );
            }
            //thisInput.onChange((entityId !== null ? objectName : null));

            if (isNotNullAndUndefined(this.props.onChanged)) {
              if (
                isNotNullAndUndefined(entityId) &&
                isNotNullAndUndefined(objectName)
              ) {
                this.props.onChanged(objectName, entityId);
              } else {
                this.props.onChanged(null, null);
              }
            }
          }
        }
      );
    }
  };

  showLookupGridDialogClose = () => {
    if (this._isMounted) {
      this.setState({ showLookupGridContent: false });
    }
  };
  // lookUp dialog end

  render() {
    let {
      showLabel,
      required,
      label,
      field,
      initialValue,
      validate,
      validators,
      width,
      disabled,
      placeholder,
      showClearButton,
      SearchCreateOptionMode,
      description,
      descriptionAsLabel,
      titleLocation,
    } = this.props;
    let { showLookupGridContent, showCreateContent } = this.state;

    let labelClassName = isNotNullAndUndefined(this.props.labelClassName)
      ? this.props.labelClassName
      : "";
    let rowClassName = isNotNullAndUndefined(this.props.rowClassName)
      ? `${this.props.rowClassName} row`
      : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(this.props.inputFieldClassName)
      ? this.props.inputFieldClassName
      : 'col-12';
    if (titleLocation === 'Right') {
      rowClassName = `${rowClassName} flex-nowrap gx-2`;
      labelClassName = 'col-auto order-last';
      inputFieldClassName = 'col';
    }
    else if (titleLocation === 'Left') {
      rowClassName = `${rowClassName} flex-nowrap gx-2`;
      labelClassName = 'col-3';
      inputFieldClassName = 'col';
    }
    else if (titleLocation === 'Bottom') {
      labelClassName = 'order-last';
    }

    return (
      <div>
        <RAFFormContext.Consumer>
          {(rafFormContextValue) => {
            this.rafFormContextValue = rafFormContextValue;
            return (
              <div
                className={
                  isNotNullAndUndefined(this.props.formGroupClassName)
                    ? this.props.formGroupClassName + " form-group"
                    : "form-group"
                }
              >
                <div className={rowClassName} id={"rafdiv" + field.toString()}>
                  {showLabel && showLabel === true && (
                    <RAFFieldLabel
                      field={field}
                      label={label}
                      required={required}
                      labelClassName={labelClassName}
                      description={description}
                      descriptionAsLabel={descriptionAsLabel}
                    ></RAFFieldLabel>
                  )}
                  <div className={inputFieldClassName}>
                    <Field
                      name={field.toString()}
                      {...(initialValue ? { initialValue: initialValue } : {})}
                      //validate={validate === true ? (required && isRequired) : null}
                      {...(validators
                        ? {
                          validate:
                            validate === true
                              ? composeValidators(
                                required === true ? isRequired : null,
                                ...validators
                              )
                              : null,
                        }
                        : {
                          validate:
                            validate === true
                              ? composeValidators(
                                required === true ? isRequired : null
                              )
                              : null,
                        })}
                      allowNull
                      parse={(value) => (value === "" ? null : value)}
                    >
                      {({ input, meta }) => {
                        //thisInput = input;
                        this.setValueDelay(input.value);
                        return (
                          <div className="row g-2">
                            <div className="col">
                              <DropDownListComponent
                                id={`ddl_${ConvertToElementID(
                                  field.toString()
                                )}`}
                                name={input.name}
                                value={
                                  isNotNullAndUndefined(input.value) &&
                                    !IsNullOrWhiteSpace(input.value)
                                    ? input.value
                                    : null
                                }
                                change={(e) => {
                                  if (e.isInteracted) {
                                    this.onChange(e, input);
                                  }
                                }}
                                width={width}
                                readonly={disabled}
                                fields={this.fields}
                                dataSource={this.customerData}
                                //noRecordsTemplate={this.noRecordsTemplate.bind(this)}
                                noRecordsTemplate={this.noRecordsTemplate()}
                                footerTemplate={this.footerTemplate}
                                allowFiltering
                                filtering={this.onFiltering.bind(this)}
                                actionBegin={this.actionBegin.bind(this)}
                                actionComplete={(e) =>
                                  this.actionComplete(e, input.value)
                                }
                                ref={(g) => (this.dropDownListComponent = g)}
                                created={(e) => this.created()}
                                close={this.onClose.bind(this)}
                                open={(e) => this.opened()}
                                showClearButton={showClearButton}
                                placeholder={placeholder}
                                cssClass={`
                        ${meta.error && meta.touched ? "inputFieldError" : null}${isNotNullAndUndefined(this.props.componentCssClass) ? ` ${this.props.componentCssClass}` : ''}
                      `}
                              />

                              {this.props.hideRequiredMessage !== true ? (
                                <RAFFieldError name={field.toString()} />
                              ) : (
                                ""
                              )}
                            </div>
                            {SearchCreateOptionMode === "Default" &&
                              this.showFullList === true && (
                                <div className="col-auto">
                                  <TooltipComponent
                                    content="Show more"
                                    position="BottomCenter"
                                  >
                                    <ButtonComponent
                                      type="button"
                                      cssClass="input-icon-btn"
                                      iconCss="fas fa-magnifying-glass pt-1"
                                      onClick={() =>
                                        this.showLookUpGridDialogOpen()
                                      }
                                    ></ButtonComponent>
                                  </TooltipComponent>
                                </div>
                              )}
                          </div>
                        );
                      }}
                    </Field>

                    <div className="d-none">
                      <Field
                        name={field.toString() + "UID"}
                        component="input"
                      ></Field>
                    </div>
                  </div>
                </div>
                {showLookupGridContent === true && (
                  <DialogComponent
                    visible
                    //cssClass="centerDialog-lg dlg-new-style fixed-height"
                    cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
                    //ref={dialog => this.showLookupGridDialog = dialog}
                    content={this.showLookupGridContent.bind(this)}
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={() => this.showLookupGridDialogClose.bind(this)}
                    zIndex={1200}
                    open={PreventFocusOnDialogOpen}
                  //ref={lookUpDialogRef}
                  ></DialogComponent>
                )}
                {showCreateContent === true && (
                  <DialogComponent
                    header={"Create " + this.state.createDialogHeader}
                    visible
                    cssClass="rightDialog createEditForm full-height form-center-dialog" //ref={dialog => this.showLookupGridDialog = dialog}
                    content={this.showCreateContent.bind(this)}
                    showCloseIcon
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={this.closeCreateDialog.bind(this)}
                    zIndex={1200}
                    open={PreventFocusOnDialogOpen}
                  ></DialogComponent>
                )}
              </div>
            );
          }}
        </RAFFormContext.Consumer>
      </div>
    );
  }
}

export default RAFLookupCC;
