import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { isNotNullAndUndefined, IsNotNullOrWhiteSpace, IsNullOrWhiteSpace } from "../../RAFComponents/helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators, RAFFieldError } from "./RAFForm";
import { isRequired, RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFNumberProps } from "./RFFUtils";

//const RAFTextBox = ({ field, label, required }) => {

function RAFNumber<T>({
  field,
  label,
  width,
  onChanged,
  children,
  minValue,
  maxValue,
  description,
  descriptionAsLabel,
  titleLocation,


  showClearButton = false,
  showSpinButton = true,
  decimalsPoints = 0,
  valueType = "int",

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFNumberProps<T>>) {
  //let defaultMaxValue = valueType === 'long' ? 9223372036854775807 : 2147483647;
  //let defaultMinValue = valueType === 'long' ? -9223372036854775808 : -2147483648;
  let defaultMaxValue =
    valueType === "long" ? 9111111111111111111 : 2111111111;
  let defaultMinValue =
    valueType === "long" ? -9111111111111111111 : -2111111111;

  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : 'col-12';
  if (titleLocation === 'Right') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-auto order-last';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Left') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-3';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Bottom') {
    labelClassName = 'order-last';
  }

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className={inputFieldClassName}>
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (IsNullOrWhiteSpace(value) ? null : decimalsPoints > 0 ? parseFloat(value) : parseInt(value))}
          >
            {({ input, meta }) => {
              return (
                <div>
                  <NumericTextBoxComponent
                    id={`ntx_${field.toString()}`}
                    {...(decimalsPoints > 0
                      ? {
                        decimals: decimalsPoints,
                      }
                      : {
                        format: "#",
                      })}
                    value={input.value}
                    name={input.name}
                    change={(e) => {
                      if (e.isInteracted) {
                        const newValue = IsNotNullOrWhiteSpace(e.value) ? e.value : null;
                        input.onChange(newValue);
                        if (isNotNullAndUndefined(onChanged)) {
                          onChanged(newValue);
                        }
                      }
                    }}
                    showSpinButton={disabled === true ? false : showSpinButton}
                    readonly={props.readonly}
                    showClearButton={showClearButton}
                    width={width && width !== null ? width : "100%"}
                    placeholder={props.placeholder}
                    style={{ background: props.textAreaColorCode }}
                    disabled={disabled}
                    cssClass={
                      `${meta.error && meta.touched ? "inputFieldError" : ''}${IsNotNullOrWhiteSpace(props.componentCssClass) ? (meta.error && meta.touched ? ` ${props.componentCssClass}` : props.componentCssClass) : ''}`
                    }
                    {...(isNotNullAndUndefined(minValue) &&
                      minValue >= defaultMinValue
                      ? {
                        min: minValue,
                      }
                      : {
                        min: defaultMinValue,
                      })}
                    {...(isNotNullAndUndefined(maxValue) &&
                      maxValue <= defaultMaxValue
                      ? {
                        max: maxValue,
                      }
                      : {
                        max: defaultMaxValue,
                      })}
                  />
                  {/* <TextBoxComponent
                  value={input.value}
                  name={input.name}
                  change={(e) => {
                    if (e.isInteracted) {
                      input.onChange(e.value);
                      if (isNotNullAndUndefined(onChanged)) {
                        onChanged(e.value);
                      }
                    }
                  }}
                  type="number"
                  readonly={props.readonly}
                  showClearButton={props.showClearButton}
                  width={width && width !== null ? width : "100%"}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  cssClass={
                    meta.error && meta.touched ? "inputFieldError" : null
                  }
                /> */}

                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFNumber;
