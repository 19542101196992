import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { MentionComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  Audio,
  HtmlEditor,
  Image,
  Inject,
  IToolbarItems,
  Link,
  PasteCleanup,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  ToolbarSettingsModel,
  Video,
} from "@syncfusion/ej2-react-richtexteditor";
import DOMPurify from "dompurify";
import { get, set, debounce } from "lodash";
import * as React from "react";
import { PropsWithChildren, useContext } from "react";
import { Field, useForm } from "react-final-form";
import { getAllUsers, getFields } from "../helpers/AppHelper";
import {
  ConvertSystemName,
  ConvertToElementID,
  Guid,
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace
} from "../helpers/utils";
import RAFTabGroupBtn from "../Navigation/RAFTabGroupBtn";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators, RAFFieldError } from "./RAFForm";
import {
  createRAFHTMLValidator,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFTextAreaProps
} from "./RFFUtils";
import RAFButtonComponent from "../Navigation/RAFButtonComponent";

interface RichTextEditorContextValue {
  inlineMode: boolean;
  onChangeHTMLEditMode: (id: string) => void;
}
export const RichTextEditorContext = React.createContext<RichTextEditorContextValue | undefined>(undefined);
const items: (string | IToolbarItems)[] = [
  "Bold",
  "Italic",
  "Underline",
  "StrikeThrough",
  "FontName",
  "FontSize",
  "FontColor",
  "BackgroundColor",
  "LowerCase",
  "UpperCase",
  "|",
  "Formats",
  "Alignments",
  "OrderedList",
  "UnorderedList",
  "Outdent",
  "Indent",
  "|",
  "CreateLink",
  "Image",
  "Audio",
  "Video",
  "|",
  "ClearFormat",
  "Print",
  "SourceCode",
  "|",
  "Undo",
  "Redo",
  // {
  //     tooltipText: 'Insert Video',
  //     template: "<button type='button' class='e-tbar-btn e-btn' tabindex='-1' id='custom_tbar_" + field.toString() + "' style ='width:100%'><span class='e-icons e-entity e-entity-video'></span></button>"
  // }
];

interface IProps {
  prefixText?: string;
  fullHeight?: boolean;
  hideToolbarSettings?: boolean;
  mentionsDataSource?: { key: string; value: string; id: string; }[];
  mentionsMode?: "user" | "fields";
  fieldForMailMergeEntityName?: string;
  mailMergeEntityName?: string;
  editorMode?: 'advanced' | 'simple';
  showTabGroupBtn?: boolean;
}


const RichTextEditorField = ({
  input, meta, richtexteditorID,

  mentionChar = "@",
  fullHeight = false,
  useMentions = false,
  hideToolbarSettings = false,

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  showTabGroupBtn = true,
  ...props }) => {
  const [lastValue, setLastValue] = React.useState(input.value);
  const [timeoutId, setTimeoutId] = React.useState(null);
  //const richtexteditorID = ConvertToElementID(`${input.name}`);
  //console.log('richtexteditorID', { RichTextEditorContext });

  const { inlineMode } = useContext(RichTextEditorContext);
  const form = useForm();

  const toolbarSettings: ToolbarSettingsModel = hideToolbarSettings
    ? {
      items: [],
      enable: false,
      enableFloating: true
    }
    : {
      items: items,
      enableFloating: true
    };

  function getSelectedUserIdArray(value) {
    let selectedUserIdArray: string[] = [];
    if (!IsNullOrWhiteSpace(value)) {
      let innerHTML = value;
      const div = document.createElement("div");
      div.innerHTML = innerHTML.trim();
      let tributeItem = div.getElementsByClassName("tribute-mention");
      if (isNotNullAndUndefined(tributeItem) && tributeItem.length > 0) {
        for (var i = 0; i < tributeItem.length; i++) {
          const objFirstItem = tributeItem[i];
          if (
            isNotNullAndUndefined(objFirstItem) &&
            isNotNullAndUndefined(objFirstItem.children) &&
            isNotNullAndUndefined(objFirstItem.children[0]) &&
            isNotNullAndUndefined(objFirstItem.children[0].id)
          ) {
            const objUserId = objFirstItem.children[0].id;
            if (isNotNullAndUndefined(objUserId)) {
              let userExist = false;
              if (isNotNullAndUndefined(selectedUserIdArray)) {
                //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                userExist =
                  selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                    ? true
                    : false;
              }
              if (!userExist) {
                //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                selectedUserIdArray.push(objUserId);
              }
            }
          }
        }
      }
    }
    return selectedUserIdArray;
  }


  const handleContentChange1 = React.useCallback(debounce((event) => {
    if (event.value !== lastValue) {
      // input.onChange(event.value);
      // setLastValue(event.value);

      // Clear the previous timeout if it exists
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to update the field value
      const newTimeoutId = setTimeout(() => {
        input.onChange(event.value);
        const selectedUserIdArray = getSelectedUserIdArray(event.value);
        if (isNotNullAndUndefined(props.mentionsField)) {
          form.change(props.mentionsField, selectedUserIdArray);
        }
        if (isNotNullAndUndefined(props.onChanged)) {
          props.onChanged(event.value !== null ? event.value : null);
        }
        if (isNotNullAndUndefined(props.onInputs)) {
          props.onInputs(DOMPurify.sanitize(event.value));
        }
        setLastValue(event.value);
      }, 300); // 300ms delay

      setTimeoutId(newTimeoutId);
    }
  }, 300) // 300ms delay
    ,
    []
  );


  const handleContentChange = React.useCallback((event) => {
    if (event.value !== lastValue) {
      input.onChange(event.value);
      const selectedUserIdArray = getSelectedUserIdArray(event.value);
      if (isNotNullAndUndefined(props.mentionsField)) {
        form.change(props.mentionsField, selectedUserIdArray);
      }
      if (isNotNullAndUndefined(props.onChanged)) {
        props.onChanged(event.value !== null ? event.value : null);
      }
      if (isNotNullAndUndefined(props.onInputs)) {
        props.onInputs(DOMPurify.sanitize(event.value));
      }
      setLastValue(event.value);

      // // Clear the previous timeout if it exists
      // if (timeoutId) {
      //   clearTimeout(timeoutId);
      // }

      // // Set a new timeout to update the field value
      // const newTimeoutId = setTimeout(() => {
      //   input.onChange(event.value);
      //   setLastValue(event.value);
      // }, 300); // 300ms delay

      // setTimeoutId(newTimeoutId);
    }
  }, [input, lastValue]);

  function actionBegineHandler(args) {
    if (args.requestType === 'EnterAction') {
      args.cancel = true;
    }
  }


  return (
    <RichTextEditorComponent
      //id="defaultRTE"
      id={`mention_integration${richtexteditorID}`}
      value={input.value}
      change={handleContentChange}
      placeholder={props.placeholder}
      className="rafRichTextEditorComponent tribute-demo-input e-control e-textbox e-lib e-input raf_prevent_submit"
      toolbarSettings={toolbarSettings}
      pasteCleanupSettings={{
        prompt: true,
        plainText: false,
        keepFormat: false,
      }}
      //inlineMode={{ enable: false }}
      inlineMode={{ enable: inlineMode }}
      {...(props.htmlEditorHeight
        ? { height: props.htmlEditorHeight }
        : props.fullHeight === true
          ? { height: "500px" }
          : {})}
      {...(props.width ? { width: props.width } : {})}
      {...(props.maxlength ? { maxLength: props.maxlength } : {})}
      {...(props.disabled === true ? { readonly: true } : {})}
      insertImageSettings={{ saveFormat: "Base64" }}

      enterKey="BR"
      shiftEnterKey="BR"
      //actionBegin={actionBegineHandler.bind(this, input)}
      actionBegin={actionBegineHandler.bind(this)}
      htmlAttributes={
        isNotNullAndUndefined(props.mentionsField)
          ? { 'data-rafMentionsField': props.mentionsField }
          : null
      }
      style={{ minHeight: '100px' }}

    >
      <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar, Audio, Video, PasteCleanup]} />
    </RichTextEditorComponent>
  );
};
const DisabledRichTextEditorField = ({
  input, meta, richtexteditorID,

  mentionChar = "@",
  fullHeight = false,
  useMentions = false,
  hideToolbarSettings = false,

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  showTabGroupBtn = true,
  ...props }) => {
  const [lastValue, setLastValue] = React.useState(input.value);
  const [timeoutId, setTimeoutId] = React.useState(null);
  //const richtexteditorID = ConvertToElementID(`${input.name}`);
  //console.log('richtexteditorID', { RichTextEditorContext });

  const { inlineMode } = useContext(RichTextEditorContext);
  const form = useForm();

  const toolbarSettings: ToolbarSettingsModel = hideToolbarSettings
    ? {
      items: [],
      enable: false,
      enableFloating: true
    }
    : {
      items: items,
      enableFloating: true
    };

  function getSelectedUserIdArray(value) {
    let selectedUserIdArray: string[] = [];
    if (!IsNullOrWhiteSpace(value)) {
      let innerHTML = value;
      const div = document.createElement("div");
      div.innerHTML = innerHTML.trim();
      let tributeItem = div.getElementsByClassName("tribute-mention");
      if (isNotNullAndUndefined(tributeItem) && tributeItem.length > 0) {
        for (var i = 0; i < tributeItem.length; i++) {
          const objFirstItem = tributeItem[i];
          if (
            isNotNullAndUndefined(objFirstItem) &&
            isNotNullAndUndefined(objFirstItem.children) &&
            isNotNullAndUndefined(objFirstItem.children[0]) &&
            isNotNullAndUndefined(objFirstItem.children[0].id)
          ) {
            const objUserId = objFirstItem.children[0].id;
            if (isNotNullAndUndefined(objUserId)) {
              let userExist = false;
              if (isNotNullAndUndefined(selectedUserIdArray)) {
                //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                userExist =
                  selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                    ? true
                    : false;
              }
              if (!userExist) {
                //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                selectedUserIdArray.push(objUserId);
              }
            }
          }
        }
      }
    }
    return selectedUserIdArray;
  }


  const handleContentChange1 = React.useCallback(debounce((event) => {
    if (event.value !== lastValue) {
      // input.onChange(event.value);
      // setLastValue(event.value);

      // Clear the previous timeout if it exists
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to update the field value
      const newTimeoutId = setTimeout(() => {
        input.onChange(event.value);
        const selectedUserIdArray = getSelectedUserIdArray(event.value);
        if (isNotNullAndUndefined(props.mentionsField)) {
          form.change(props.mentionsField, selectedUserIdArray);
        }
        if (isNotNullAndUndefined(props.onChanged)) {
          props.onChanged(event.value !== null ? event.value : null);
        }
        if (isNotNullAndUndefined(props.onInputs)) {
          props.onInputs(DOMPurify.sanitize(event.value));
        }
        setLastValue(event.value);
      }, 300); // 300ms delay

      setTimeoutId(newTimeoutId);
    }
  }, 300) // 300ms delay
    ,
    []
  );


  const handleContentChange = React.useCallback((event) => {
    if (event.value !== lastValue) {
      input.onChange(event.value);
      const selectedUserIdArray = getSelectedUserIdArray(event.value);
      if (isNotNullAndUndefined(props.mentionsField)) {
        form.change(props.mentionsField, selectedUserIdArray);
      }
      if (isNotNullAndUndefined(props.onChanged)) {
        props.onChanged(event.value !== null ? event.value : null);
      }
      if (isNotNullAndUndefined(props.onInputs)) {
        props.onInputs(DOMPurify.sanitize(event.value));
      }
      setLastValue(event.value);

      // // Clear the previous timeout if it exists
      // if (timeoutId) {
      //   clearTimeout(timeoutId);
      // }

      // // Set a new timeout to update the field value
      // const newTimeoutId = setTimeout(() => {
      //   input.onChange(event.value);
      //   setLastValue(event.value);
      // }, 300); // 300ms delay

      // setTimeoutId(newTimeoutId);
    }
  }, [input, lastValue]);

  function actionBegineHandler(args) {
    if (args.requestType === 'EnterAction') {
      args.cancel = true;
    }
  }


  return (
    <RichTextEditorComponent
      //id="defaultRTE"
      id={`mention_integration${richtexteditorID}`}
      value={input.value}
      change={handleContentChange}
      placeholder={props.placeholder}
      className="rafRichTextEditorComponent tribute-demo-input e-control e-textbox e-lib e-input raf_prevent_submit"
      toolbarSettings={toolbarSettings}
      pasteCleanupSettings={{
        prompt: true,
        plainText: false,
        keepFormat: false,
      }}
      //inlineMode={{ enable: false }}
      inlineMode={{ enable: true }}
      {...(props.htmlEditorHeight
        ? { height: props.htmlEditorHeight }
        : props.fullHeight === true
          ? { height: "500px" }
          : {})}
      {...(props.width ? { width: props.width } : {})}
      {...(props.maxlength ? { maxLength: props.maxlength } : {})}
      readonly={true}
      insertImageSettings={{ saveFormat: "Base64" }}

      enterKey="BR"
      shiftEnterKey="BR"
      //actionBegin={actionBegineHandler.bind(this, input)}
      actionBegin={actionBegineHandler.bind(this)}
      htmlAttributes={
        isNotNullAndUndefined(props.mentionsField)
          ? { 'data-rafMentionsField': props.mentionsField }
          : null
      }
      style={{ minHeight: '100px' }}

    >
      <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar, Audio, Video, PasteCleanup]} />
    </RichTextEditorComponent>
  );
};

const MemoizedRichTextEditorField = React.memo(RichTextEditorField);
const DisabledMemoizedRichTextEditorField = React.memo(DisabledRichTextEditorField);

//export default RichTextEditorField;

function RAFHtmlEditor<T>({
  field,
  label,
  onChanged,
  //children,
  onInputs,
  cssClass,
  description,
  descriptionAsLabel,
  hideToolbarSettings,
  validators,
  editorMode = 'advanced',

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFTextAreaProps<T> & IProps>) {
  let rteObj: RichTextEditorComponent;
  let mentionComponent: MentionComponent;
  let textBoxComponentObj: TextBoxComponent;

  //const [selectedItem, setSelectedItem] = React.useState('advanced');
  const [inlineMode, setInlineMode] = React.useState(editorMode === 'advanced' ? false : true);

  //const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const mailMergeEntityName = null;
  // const mailMergeEntityName = isNotNullAndUndefined(props.mailMergeEntityName)
  //   ? props.mailMergeEntityName
  //   : isNotNullAndUndefined(props.fieldForMailMergeEntityName)
  //     ? getFormValue(rafFormContextValue, props.fieldForMailMergeEntityName)
  //     : null;

  const objGuid = Guid.newGuid();
  const toolbarSettings: ToolbarSettingsModel = hideToolbarSettings
    ? {
      items: [],
      enable: false,
      enableFloating: true
    }
    : {
      items: items,
      enableFloating: true
    };

  const pasteCleanupSettings = {
    prompt: true,
    plainText: false,
    keepFormat: false,
  };

  //const richtexteditorID = ConvertToElementID(`${field.toString()}_${objGuid}`);
  //const richtexteditorID = ConvertToElementID(`${field.toString()}`);
  //const [richtexteditorID] = React.useState(ConvertToElementID(`${field.toString()}_${objGuid}`));
  const [richtexteditorID] = React.useState(ConvertToElementID(`${field.toString()}`));
  //console.log('RAFHtmlEditor2 richtexteditorID', { richtexteditorID });

  const richTextEditorInject = hideToolbarSettings
    ? [
      HtmlEditor,
      Toolbar,
      //Link,
      //QuickToolbar,
      PasteCleanup
    ]
    : [HtmlEditor, Toolbar, Image, Link, QuickToolbar, Audio, Video, PasteCleanup];

  const mentionfields = { text: "value", value: "value" };
  const labelClassName = `flex-wrap ${isNotNullAndUndefined(props.labelClassName) ? ` ${props.labelClassName}` : ""
    } ${props.fullHeight === true ? "col-auto" : "col-12"}`;

  const form = useForm();
  const currValue = form.getState().values[field.toString()];

  const checkContent = () => {
    //console.log('checkContent');
    const values = form.getState().values;
    //console.log('handlesubmit2', { ...values });
    //loop through all richTextEditorElements and convert as ej2 RTE
    const richTextEditorElement = document.querySelector(`#mention_integration${richtexteditorID}`);
    if (
      isNotNullAndUndefined(richTextEditorElement["ej2_instances"]) &&
      isNotNullAndUndefined(richTextEditorElement["ej2_instances"][0]) &&
      isNotNullAndUndefined(
        richTextEditorElement[
        "ej2_instances"
        ][0] as RichTextEditorComponent
      )
    ) {
      const rte = richTextEditorElement[
        "ej2_instances"
      ][0] as RichTextEditorComponent;
      const rteName = rte["name"];
      //console.log('rte', { rte });
      if (rte.getHtml() === "<br>") {
        //set(values, rteName, undefined);
        form.change(field.toString(), undefined);
      } else if (get(values, rteName) !== rte.getHtml()) {
        form.change(field.toString(), rte.getHtml());

        const mentionsField = rte.htmlAttributes["data-rafMentionsField"];

        if (isNotNullAndUndefined(mentionsField)) {
          const selectedUserIdArray = getSelectedUserIdArray(rte.getHtml());
          form.change(mentionsField, selectedUserIdArray);

        }
      }
    }
  };

  React.useEffect(() => {
    if (isNotNullAndUndefined(disabled) && disabled === false) {
      //refresh();
      (async () => {
        await refresh();
      })();
    }
  });

  async function refresh() {
    if (
      props.useMentions &&
      isNotNullAndUndefined(disabled) &&
      disabled === false
    ) {
      let object: { key: string; value: string; id: string; }[] = [];
      if (isNotEmptyArray(props.mentionsDataSource)) {
        props.mentionsDataSource.forEach((item) => {
          object.push({
            key: item.key,
            value: item.value,
            id: item.id,
          });
        });
      } else {
        if (props.mentionsMode === "fields") {
          if (isNotNullAndUndefined(mailMergeEntityName)) {
            const moduleName = ConvertSystemName(mailMergeEntityName);
            const allFields = await getFields(moduleName, null);
            if (isNotEmptyArray(allFields)) {
              allFields.forEach((item) => {
                object.push({
                  key: item.DisplayName,
                  value: item.PropertyName,
                  id: item.AttributeUID,
                });
              });
            }
          }
        } else if (props.mentionsMode === "user") {
          let users = await getAllUsers();
          users &&
            users.forEach((item) => {
              object.push({
                key: item.UID,
                value: item.Value,
                id: item.UID,
              });
            });
        }
      }

      if (isNotNullAndUndefined(mentionComponent))
        mentionComponent.dataSource = object;
    }
  }

  //VideoSettings start
  const onCreated = () => {
    if (disabled) {
      if (isNotNullAndUndefined(rteObj)) rteObj.readonly = true;
    }
    if (isNotNullAndUndefined(rteObj))
      rteObj.insertImageSettings.saveFormat = "Base64";
    // let customBtn = document.getElementById(
    //   "custom_tbar_" + field.toString()
    // ) as HTMLElement;

    // if (isNotNullAndUndefined(customBtn)) {
    //   customBtn.onclick = () => {
    //     //(rteObj.contentModule.getEditPanel() as HTMLElement).focus();
    //     VideoSettingsOpen(field.toString());
    //   };
    // }
  };

  const InsertVideo = (field) => {
    let urlVal = textBoxComponentObj.value;
    //rteObj.inputElement.innerHTML = "<iframe width='420' height='320' src='" + urlVal + "' frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video'/>";
    //rteObj.executeCommand('insertHTML', "<p><iframe width='420' height='320' src='" + urlVal + "' frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video'/>");
    rteObj.executeCommand(
      "insertHTML",
      "<p><video width='320' height='240' controls><source src='" +
      urlVal +
      "' type='video/mp4'></video></p><br/>"
    );
    VideoSettingsClose(field);
  };

  const VideoSettingsClose = (field) => {
    let fieldName = field.replace(/[^a-zA-Z0-9]/g, "_");

    let retVal = document.querySelectorAll('div[id^="videoDialogDiv_"]');
    document.body.classList.remove("overflow-hidden");
    let overlayDiv = document.querySelector(
      "#videoDialogOverlayDiv_" + fieldName
    );
    if (isNotNullAndUndefined(overlayDiv)) {
      overlayDiv.remove();
    }
    let videoDialogDiv = document.querySelectorAll(
      'div[id^="videoDialogDiv_"]'
    );
    if (isNotNullAndUndefined(retVal)) {
      retVal.forEach((x) => {
        x.classList.remove("active");
        if (
          isNotNullAndUndefined(fieldName) &&
          x.id === "videoDialogDiv_" + fieldName
        ) {
          x.classList.remove("active");
        }
      });
    }
    if (isNotNullAndUndefined(videoDialogDiv)) {
      videoDialogDiv.forEach((x) => {
        x.classList.remove("active");
        if (
          isNotNullAndUndefined(fieldName) &&
          x.id === "videoDialogDiv_" + fieldName
        ) {
          x.classList.remove("active");
        }
      });
    }
  };

  const VideoSettingsOpen = (field: string) => {
    let fieldName = field.replace(/[^a-zA-Z0-9]/g, "_");
    let retVal = document.querySelectorAll('div[id^="videoDialogDiv_"]');
    document.body.classList.add("overflow-hidden");
    let overlayDiv = document.createElement("div");
    overlayDiv.className = "e-dlg-overlay settingsOverlay";
    overlayDiv.id = "videoDialogOverlayDiv_" + fieldName;
    document.body.appendChild(overlayDiv);

    let videoDialogDiv = document.querySelectorAll(
      'div[id^="videoDialogDiv_"]'
    );
    if (isNotNullAndUndefined(retVal)) {
      retVal.forEach((x) => {
        x.classList.remove("active");
        if (
          isNotNullAndUndefined(fieldName) &&
          x.id === "videoDialogDiv_" + fieldName
        ) {
          x.classList.add("active");
        }
      });
    }
    if (isNotNullAndUndefined(videoDialogDiv)) {
      videoDialogDiv.forEach((x) => {
        x.classList.remove("active");
        if (
          isNotNullAndUndefined(fieldName) &&
          x.id === "videoDialogDiv_" + fieldName
        ) {
          x.classList.add("active");
        }
      });
    }
  };
  //VideoSettings end

  function actionBegineHandler(input, args) {
    if (args.requestType === "EnterAction") {
      args.cancel = true;
    } else if (args.requestType === "Paste") {
      setTimeout(() => {
        if (isNotNullAndUndefined(rteObj)) {
          // const fieldInPutValue = input.value;
          // const rteObjValue = rteObj.getHtml();
          // if (IsNotNullOrWhiteSpace(rteObjValue) && rteObjValue !== fieldInPutValue) {
          //   setFormValue(rafFormContextValue, field.toString(), rteObj.getHtml());
          // }



          // form.change(field.toString(), rteObj.getHtml());

          // const mentionsField = rteObj.htmlAttributes["data-rafMentionsField"];

          // if (isNotNullAndUndefined(mentionsField)) {
          //   const selectedUserIdArray = getSelectedUserIdArray(rteObj.getHtml());
          //   form.change(mentionsField, selectedUserIdArray);

          // }
        }
      }, 100);
    }
  }

  function displayTemplate(data) {
    if (props.mentionsMode === "fields") {
      const moduleName = ConvertSystemName(mailMergeEntityName);
      const prefixText = isNotNullAndUndefined(props.prefixText)
        ? props.prefixText
        : moduleName;
      const displayText = !IsNullOrWhiteSpace(prefixText)
        ? `${prefixText}_${data.value}`
        : `${data.value}`;
      return (
        <span contentEditable={false} id={data.id}>{`{{${displayText}}}`}</span>
      );
    } else {
      let displayText = `@${data.value}`;
      return (
        <span className="tribute-mention" contentEditable={false}>
          <span id={data.id}> {displayText}</span>
        </span>
      );
    }
  }

  function onChangeRichTextEditor(value, input) {
    if (isNotNullAndUndefined(props.mentionsField)) {
      const selectedUserIdArray = getSelectedUserIdArray(value);
      setMentionUsers(selectedUserIdArray);
    }

    input.onChange(value !== null ? value : null);
    if (isNotNullAndUndefined(onChanged)) {
      onChanged(value !== null ? value : null);
    }
    if (isNotNullAndUndefined(onInputs)) {
      onInputs(DOMPurify.sanitize(value));
    }
  }

  function getSelectedUserIdArray(value) {
    let selectedUserIdArray: string[] = [];
    if (!IsNullOrWhiteSpace(value)) {
      let innerHTML = value;
      const div = document.createElement("div");
      div.innerHTML = innerHTML.trim();
      let tributeItem = div.getElementsByClassName("tribute-mention");
      if (isNotNullAndUndefined(tributeItem) && tributeItem.length > 0) {
        for (var i = 0; i < tributeItem.length; i++) {
          const objFirstItem = tributeItem[i];
          if (
            isNotNullAndUndefined(objFirstItem) &&
            isNotNullAndUndefined(objFirstItem.children) &&
            isNotNullAndUndefined(objFirstItem.children[0]) &&
            isNotNullAndUndefined(objFirstItem.children[0].id)
          ) {
            const objUserId = objFirstItem.children[0].id;
            if (isNotNullAndUndefined(objUserId)) {
              let userExist = false;
              if (isNotNullAndUndefined(selectedUserIdArray)) {
                //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                userExist =
                  selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                    ? true
                    : false;
              }
              if (!userExist) {
                //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                selectedUserIdArray.push(objUserId);
              }
            }
          }
        }
      }
    }
    return selectedUserIdArray;
  }

  const setMentionUsers = (selectedUsersArray) => {
    //console.log("setMentionUsers1", selectedUsersArray);
    if (isNotNullAndUndefined(props.mentionsField)) {
      // setFormValue(
      //   rafFormContextValue,
      //   props.mentionsField,
      //   selectedUsersArray
      // );
    }
  };

  const groupBtnItems = [
    {
      iconClass: 'fas fa-text',
      id: 'simple',
      title: 'Text'
    },
    {
      iconClass: 'fasr fa-align-center',
      id: 'advanced',
      title: 'Advanced'
    }
  ];

  const onChangeHTMLEditMode1 = (id) => {
    //setSelectedItem(id);
    if (isNotNullAndUndefined(id) && isNotNullAndUndefined(rteObj)) {
      if (id === "simple") {
        rteObj.inlineMode.enable = true;
      } else {
        rteObj.inlineMode.enable = false;
      }
    }
  };


  const onChangeHTMLEditMode = (id) => {
    setInlineMode(id === "simple");
  };

  const groupBtnContent = () => {
    return (
      <div className="d-flex align-items-center ms-auto">
        {isNotNullAndUndefined(props.labelRightSection) ?
          <div>{props.labelRightSection}</div> : ""}
        {props.showTabGroupBtn &&
          <>
            <div className="group_btn_separator"></div>
            <RAFTabGroupBtn
              field={field}
              items={groupBtnItems}
              mode="IconOnly"
              // selectedItem={selectedItem}
              selectedItem={inlineMode === true ? 'simple' : 'advanced'}
              onSelect={(item) => onChangeHTMLEditMode(item.id)}
            />
          </>}
      </div>
    );
  };

  const validateFunc = React.useMemo(() => {
    if (isNotNullAndUndefined(validators)) {
      return validate === true ? composeValidators(...validators, createRAFHTMLValidator(`mention_integration${richtexteditorID}`, required)) : null;
    }
    else {
      return validate === true ? composeValidators(createRAFHTMLValidator(`mention_integration${richtexteditorID}`, required)) : null;
    }
  }, [validate, validators, richtexteditorID, required]
  );


  return (
    // {/* <div key={inlineMode === true ? 'simple' : 'advanced'}> */}
    <RichTextEditorContext.Provider value={{ inlineMode, onChangeHTMLEditMode }}>
      <div
        className={`${props.fullHeight === true ? "h-100" : ""} ${isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName
          : ""
          } 
        ${isNotNullAndUndefined(cssClass) ? cssClass : ""
          } form-group align-items-center py-0`}
      >
        <div
          className={`${props.fullHeight === true ? "h-100" : ""} ${props.rowClassName
            ? props.rowClassName
            : RAFDefaultFieldClassName.rowClassName
            }`}
          id={"rafdiv" + field.toString() + objGuid}
        >
          {showLabel && showLabel === true && (
            <RAFFieldLabel
              field={field}
              label={label}
              required={required}
              labelClassName={labelClassName}
              description={description}
              descriptionAsLabel={descriptionAsLabel}
              rightSection={groupBtnContent()}
            ></RAFFieldLabel>
          )}
          <div
            className={
              props.inputFieldClassName
                ? props.inputFieldClassName
                : props.fullHeight === true
                  ? "col"
                  : "col-12"
            }
          >
            <div className="text_input_area h-100 w-100">
              <div className={`${showLabel === false ? 'row gy-2' : ''}${showLabel === false && props.fullHeight ? ' ' : ''}${(props.fullHeight ? "h-100" : "")}`}>
                {showLabel === false &&(isNotNullAndUndefined(props.labelRightSection) || isNotNullAndUndefined(props.showTabGroupBtn)) &&(
                  <div className="col-12 d-flex justify-content-end">
                    {groupBtnContent()}
                  </div>
                )}
                <div className={showLabel === false ? (props.fullHeight ? "col-12" : "col-12") : ""}
                  {...props.fullHeight === true ? { style: { minHeight: 'calc(100% - 32px)' } } : {}}
                >
                  <Field
                    name={field.toString()}
                    component={disabled ? DisabledMemoizedRichTextEditorField : MemoizedRichTextEditorField}
                    // {...(validators
                    //   ? {
                    //     validate:
                    //       validate === true
                    //         ? composeValidators(
                    //           //required === true ? isRequired : null,
                    //           ...validators,
                    //           createRAFHTMLValidator(`mention_integration${richtexteditorID}`, required)
                    //         )
                    //         : null,
                    //   }
                    //   : {
                    //     validate:
                    //       validate === true
                    //         ? composeValidators(
                    //           //required === true ? isRequired : null,
                    //           createRAFHTMLValidator(`mention_integration${richtexteditorID}`, required)
                    //         )
                    //         : null,
                    //   })}
                    validate={validateFunc}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                    richtexteditorID={richtexteditorID}
                    hideToolbarSettings={hideToolbarSettings}
                    {...props}
                  />
                </div>
              </div>
              {props.useMentions === true && (
                <MentionComponent
                  ref={(m) => (mentionComponent = m)}
                  id={`mentionEditor${field.toString()}`}
                  target={`#mention_integration${richtexteditorID}_rte-edit-view`}
                  showMentionChar={false}
                  // allowSpaces={true}
                  allowSpaces
                  //dataSource={object}
                  fields={mentionfields}
                  popupWidth="250px"
                  popupHeight="200px"
                  displayTemplate={displayTemplate}
                  mentionChar={props.mentionChar ?? "@"}
                  //created={onMentionCreated}
                  sortOrder={"Ascending"}
                  suffixText={"&nbsp;"}
                ></MentionComponent>
              )}
              {hideToolbarSettings === false && (
                <div
                  id={
                    "videoDialogDiv_" +
                    field.toString().replace(/[^a-zA-Z0-9]/g, "_")
                  }
                  className="custom-dialog center-dialog"
                >
                  <div className="custom-dialog-content">
                    <div className="custom-dialog-header">
                      <div className="e-dlg-header">Insert Video</div>
                      <RAFButtonComponent
                        displayMode="DialogCloseBtn"
                        onClick={() => VideoSettingsClose(field.toString())}
                      />
                      {/* <ButtonComponent
                          type="button"
                          className="primary-custom-button"
                          iconCss="fas fa-xmark"
                          onClick={() => VideoSettingsClose(field.toString())}
                        ></ButtonComponent> */}
                    </div>
                    <div className="custom-dialog-body-content">
                      <div className="w-100">
                        <div className="row w-auto">
                          <div className="col-md-12 form-group align-items-center py-0">
                            <label className="form-label">URL</label>
                            <div className="w-100">
                              <TextBoxComponent
                                name={field.toString() + ".VideoURL"}
                                value=""
                                ref={(t) => (textBoxComponentObj = t)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="custom-dialog-footer">
                      <ButtonComponent
                        type="button"
                        cssClass="e-flat e-primary primary-btn me-2"
                        onClick={() => InsertVideo(field.toString())}
                      >
                        Insert
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              )}

              {props.hideRequiredMessage !== true ? (
                <div className="mt-1">
                  <RAFFieldError name={field.toString()} />
                </div>
              ) : (
                ""
              )}
            </div>

          </div>
        </div>
      </div>
    </RichTextEditorContext.Provider>
  );
};

export default RAFHtmlEditor;
