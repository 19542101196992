import { DialogComponent, DialogUtility } from '@syncfusion/ej2-react-popups';
import { DropDownButtonComponent, MenuEventArgs } from '@syncfusion/ej2-react-splitbuttons';
import React, { Reducer, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { getCompressedImage } from '../../RAFModules/ActiveContacts/Document/Library/DocumentHelper';
import { Constants, ContentType, RAFButtonConstant } from '../../constants/Common/Constants';
import RAFIconImage from '../Navigation/RAFIconImage';
import { showWarningToast } from '../Utility/RAFToastComponent';
import { RafClearDialogContent, hideProgress, showProgress } from '../helpers/AppHelper';
import { ConvertToElementID, IsNotNullOrWhiteSpace, isNotNullAndUndefined } from '../helpers/utils';
import * as repositoryActions from "../store/actions/repositoryActions";
import RAFProfilePhotoImageEditor from './RAFProfilePhotoImageEditor';

const getEmptyBlobResponse = (profilPhotoURL) => {
    return new Response(profilPhotoURL, { status: 404, statusText: 'No Image Found' }).clone();
};

interface IProps {
    uid: string;
    relatedToType: string;
    getURL: string;
    uploadURL?: string;
    removeURL?: string;
    profileImgCss?: 'profileImg-container-xxsm' | 'profileImg-container-xsm' | 'profileImg-container-xmd' | 'profileImg-container-sm' | 'profileImg-container-normal-sm' | 'profileImg-container-normal' | 'profileImg-container-md' | 'profileImg-container-xxl' | 'profileImg-container-xxxl';
    canEdit?: boolean;
    isAnonymous?: boolean;
    additionalParamsGet: { key: string, value?: string; }[],
    additionalParamsSave?: { key: string, value?: string; }[],
    onChange?: () => void;
    title?: string;
    progressDivID?: string;
    emptyAvatar?: React.ReactNode;
    emptyAvatarType?: 'Initial' | 'Icon';
    iconSize?: string;
    fontSize?: string;
    backgroundColor?: string;
    color?: string;
    border?: string;
    emptyIconCss?: string;
}

interface IState {
    showViewProfilePhotoContent: boolean;
    showProfilePhotoEditorDlg: boolean;

    selectedFileName: string;
    selectedProfilePhoto: string;
}

const defaultProps: Partial<IProps> = {
    // Your default prop values here
    profileImgCss: "profileImg-container-sm",
};

//function RAFProfilePhoto({ ...props }: PropsWithChildren<IProps>) {
const RAFProfilePhoto: React.FC<IProps> = (props) => {
    props = { ...defaultProps, ...props };

    const profilePhotoOuterDivID = `profil_PhotoOuter_Div_${ConvertToElementID(props.uid)}`;
    const profilePhotoDivID = props.progressDivID ?? `#${profilePhotoOuterDivID}`;
    const [profilePhoto, setProfilePhoto] = useState(null);
    const imageUpload = useRef(null);
    const uploadPhotoRef = useRef<DialogComponent>(null);

    let removeProfileDialog: any;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            showViewProfilePhotoContent: false,
            showProfilePhotoEditorDlg: false,

            selectedFileName: null,
            selectedProfilePhoto: null,
        }
    );

    useEffect(() => {
        refresh();
    }, [props.uid]);

    const refresh = () => {
        if (isNotNullAndUndefined(props.getURL) && isNotNullAndUndefined(props.uid)) {
            getProfilePhoto();
        } else {
            setProfilePhoto(null);
        }
    };

    const updateProfilePhoto = async (profilePhotoURL) => {
        setState({ showProfilePhotoEditorDlg: false, selectedProfilePhoto: null, selectedFileName: null });
        if (isNotNullAndUndefined(profilePhotoURL)) {
            let progressDiv = showProgress(profilePhotoDivID);
            let url = props.uploadURL;
            const compressedImg = await getCompressedImage(profilePhotoURL);

            const formData = new FormData();
            formData.append('file', isNotNullAndUndefined(compressedImg) ? compressedImg : profilePhotoURL);

            if (isNotNullAndUndefined(props.additionalParamsSave) &&
                props.additionalParamsSave.length > 0 &&
                isNotNullAndUndefined(props.uploadURL) && isNotNullAndUndefined(props.uid)) {
                props.additionalParamsSave.forEach((objProp, index) => {
                    if (IsNotNullOrWhiteSpace(objProp.key) && IsNotNullOrWhiteSpace(objProp.value)) {
                        formData.append(objProp.key, objProp.value);
                    }
                });
            } else {
                hideProgress(progressDiv);
                return;
            }

            repositoryActions.postDataAndGetResponse(url, formData, null, ContentType.applicationFormUrlEncoded)
                .then(async (response) => {
                    hideProgress(progressDiv);
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {

                        let getprofilPhotoURL = getProfilePhotURL();
                        const cache = await caches.open('profile-photos');
                        const cachedResponse = await cache.match(getprofilPhotoURL);
                        if (cachedResponse) {
                            cache.delete(getprofilPhotoURL);
                        }
                        setProfilePhoto(null);

                        if (props.onChange) {
                            props.onChange();
                        }
                    } else {
                        showWarningToast("Sorry something went wrong !");
                    }
                })
                .catch((error) => error);
        } else {
            showWarningToast("Sorry something went wrong !");
        }
    };

    const getProfilePhotURL = useCallback(() => {
        let getprofilPhotoURL = props.getURL;
        if (isNotNullAndUndefined(props.additionalParamsGet) && props.additionalParamsGet.length > 0) {
            //loop through additional paramsget and create string to send as querystring for api call
            let queryString = "";
            props.additionalParamsGet.forEach((objProp, index) => {
                if (IsNotNullOrWhiteSpace(objProp.key) && IsNotNullOrWhiteSpace(objProp.value)) {
                    if (index === 0) {
                        queryString = `?${objProp.key}=${objProp.value}`;
                    } else {
                        queryString = `${queryString}&${objProp.key}=${objProp.value}`;
                    }
                }
            });
            getprofilPhotoURL = `${getprofilPhotoURL}${queryString}`;
        }

        return `${Constants.baseAPIUrl}${getprofilPhotoURL}`;
    }, [props.getURL, props.additionalParamsGet]);

    const getProfilePhoto = async () => {
        let getprofilPhotoURL = getProfilePhotURL();

        //let progressDiv = showProgress(profilePhotoDivID);

        const cache = await caches.open('profile-photos');
        const cachedResponse = await cache.match(getprofilPhotoURL);
        const cachedResponseStatus = cachedResponse ? cachedResponse.status : null;

        if (isNotNullAndUndefined(cachedResponseStatus)) {
            //hideProgress(progressDiv);
            //const arrayBuffer = cachedResponse;
            if (cachedResponseStatus === 200) {
                const blob = await cachedResponse.blob();
                const objectUrl = URL.createObjectURL(blob);
                setProfilePhoto(objectUrl);
            } else {
                setProfilePhoto(null);
            }
        } else {
            if (props.isAnonymous !== true) {
                //repositoryActions.postDataAndGetResponse(getprofilPhotoURL, listRequest, null, ContentType.applicationJson, false, "arraybuffer")
                repositoryActions.getData(getprofilPhotoURL, null, null, ContentType.applicationJson, false, "arraybuffer")
                    .then((response) => {
                        //hideProgress(progressDiv);
                        if (response.status === 200 && isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                            const arrayBuffer = response.data;
                            const blob = new Blob([arrayBuffer], { type: response.headers['content-type'] });
                            const objectUrl = URL.createObjectURL(blob);
                            setProfilePhoto(objectUrl);
                            cache.put(getprofilPhotoURL, new Response(blob));
                        } else {
                            cache.put(getprofilPhotoURL, getEmptyBlobResponse(getprofilPhotoURL));
                            setProfilePhoto(null);
                        }
                    })
                    .catch((error) => {
                        cache.put(getprofilPhotoURL, getEmptyBlobResponse(getprofilPhotoURL));
                        setProfilePhoto(null);
                        //hideProgress(progressDiv);
                    });
            } else {
                //repositoryActions.postDataAnonymous(getprofilPhotoURL, listRequest, { ...props }, ContentType.applicationFormUrlEncoded, false, "arraybuffer")
                repositoryActions.getData(getprofilPhotoURL, null, null, ContentType.applicationJson, false, "arraybuffer")
                    .then((response) => {
                        // hideProgress(progressDiv);
                        if (response.status === 200 && isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                            const arrayBuffer = response.data;
                            const blob = new Blob([arrayBuffer], { type: response.headers['content-type'] });
                            const objectUrl = URL.createObjectURL(blob);
                            setProfilePhoto(objectUrl);
                            cache.put(getprofilPhotoURL, new Response(blob));
                        } else {
                            cache.put(getprofilPhotoURL, getEmptyBlobResponse(getprofilPhotoURL));
                            setProfilePhoto(null);
                        }
                    })
                    .catch((error) => {
                        cache.put(getprofilPhotoURL, getEmptyBlobResponse(getprofilPhotoURL));
                        setProfilePhoto(null);
                        //hideProgress(progressDiv);
                    });
            }
        }
    };

    const profilePhotoBtnOnclick = (args: MenuEventArgs) => {
        if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.item)) {
            const selectedOption = args.item.id;
            if (selectedOption === "UploadPhoto") {
                onClickUploadPhoto();
            }
            else if (selectedOption === "RemovePhoto") {
                RemoveProfileClicked();
            }
            else if (selectedOption === "ViewPhoto") {
                viewProfilePhoto();
            }
        }
    };

    //showUpload profile photo start

    const onClickUploadPhoto = () => {
        imageUpload.current.click();
    };

    const fileChanged = (args) => {
        const fileData = isNotNullAndUndefined(args) && isNotNullAndUndefined(args.target) && isNotNullAndUndefined(args.target.files) ? args.target.files[0] : null;
        let selectedProfilePhoto = null;
        let selectedFileName = null;
        if (isNotNullAndUndefined(fileData)) {
            const URL = window.URL;
            const url = URL.createObjectURL(fileData);
            selectedFileName = fileData.name;
            selectedProfilePhoto = url;
        }
        imageUpload.current.value = null;
        setState({ selectedProfilePhoto, selectedFileName, showProfilePhotoEditorDlg: true });
    };

    // const showProfilePhotoEditorDlg = () => {
    //     setState({ showProfilePhotoEditorDlg: true });
    // };

    const profilePhotoEditorDlgContent = () => {
        if (state.showProfilePhotoEditorDlg) {
            return (
                <RAFProfilePhotoImageEditor
                    srcProfilePhoto={null}
                    selectedFileName={state.selectedFileName}
                    selectedProfilePhoto={state.selectedProfilePhoto}
                    updateProfilePhoto={updateProfilePhoto}
                    removeProfileClicked={RemoveProfileClicked}
                    fileName={null}
                    isActive />
            );
        } else {
            return <div></div>;
        }
    };

    const closeProfilePhotoEditorDlg = async () => {
        await RafClearDialogContent(uploadPhotoRef);
        setState({
            showProfilePhotoEditorDlg: false,
            selectedProfilePhoto: null, selectedFileName: null
        });
    };
    //showUpload profile photo end

    //remove photo start
    function RemoveProfileClicked() {
        removeProfileDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No", cssClass: 'form-custom-button' },
            closeOnEscape: false,
            content: "Are you sure want to remove profile photo?",
            okButton: { text: "Yes", click: removeProfilePhoto.bind(this), cssClass: 'form-custom-button' },
            title: " Remove profile photo",
            position: { X: "center", Y: "center" },
            cssClass: "raf-delete_alert_dialog alert-dialog",
        });
    }

    function removeProfilePhoto() {
        if (isNotNullAndUndefined(props.removeURL) && isNotNullAndUndefined(props.uid) && isNotNullAndUndefined(props.relatedToType)) {
            let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
            repositoryActions
                .postDataAndGetResponse(
                    props.removeURL,
                    { RelatedUID: props.uid, Entity: props.relatedToType },
                    { ...props },
                    ContentType.applicationJson
                )
                .then(async (response) => {
                    hideProgress(progressDiv);
                    removeProfileDialog.hide();
                    if (isNotNullAndUndefined(response) && response.data === true) {
                        let getprofilPhotoURL = getProfilePhotURL();
                        const cache = await caches.open('profile-photos');
                        const cachedResponse = await cache.match(getprofilPhotoURL);
                        if (cachedResponse) {
                            cache.delete(getprofilPhotoURL);
                        }
                        setProfilePhoto(null);

                        if (props.onChange) {
                            props.onChange();
                        }
                    }
                });
        } else {
            removeProfileDialog.hide();
        }
    };
    //remove photo end

    //view photo start
    const viewProfilePhoto = () => {
        setState({ showViewProfilePhotoContent: true });
    };

    const profilePhotoDlgContent = () => {
        if (state.showViewProfilePhotoContent) {
            return (
                <div className={`p-3 bg-white w-100 h-auto profileImg-container ${props.profileImgCss}`}
                >
                    <img src={profilePhoto} alt="" className="profileImage" />
                </div>
            );
        } else {
            return (<div></div>);
        }
    };

    const closeViewProfilePhotoDlg = () => {
        setState({ showViewProfilePhotoContent: false });
    };
    //view photo end

    const emptyAvatar = () => {

        if (isNotNullAndUndefined(props.emptyAvatar)) {
            return props.emptyAvatar;
        }
        else {
            if (props.emptyAvatarType === 'Initial') {
                return (
                    <RAFIconImage
                        value={props.title}
                        // avatarInitialClass="avatar-initial"
                        // moduleavatar="dark-border-avatar"
                        iconSize={props.iconSize}
                        fontSize={props.fontSize}
                        enableTwoLetters={true}
                        color={props.color}
                        backgroundColor={props.backgroundColor}
                        border={props.border}
                    ></RAFIconImage>
                );
            }
            else {
                return (
                    <div className={`profileImg-container empty-profile-img ${props.profileImgCss}`}
                    >
                        <span className="font-weight-bold logoText justify-content-center">
                            <span className={IsNotNullOrWhiteSpace(props.emptyIconCss) ? props.emptyIconCss : "fa-icon fa-Camera"} style={{ fontSize: '24px' }}></span>
                        </span>
                    </div>
                );
            }
        }
    };

    return (
        <div className='d-flex' id={profilePhotoOuterDivID}>
            {props.canEdit === true ? (
                <div className="profilPhotoDiv profilPhotoDiv-container with_shadow">
                    <div>
                        {isNotNullAndUndefined(profilePhoto) ?
                            <div className={`profileImg-container ${props.profileImgCss}`}
                            >
                                <img src={profilePhoto} alt="" className="profileImage" />
                            </div>
                            :
                            emptyAvatar()
                        }
                    </div>
                    <div className={`profileImg-container-Overlay ${props.profileImgCss}`}  >
                        {isNotNullAndUndefined(profilePhoto) ?
                            <div>
                                <DropDownButtonComponent
                                    cssClass={'profilePhotoDropdown customScrollBar e-caret-hide'}
                                    // content={"Upload Photo"}
                                    iconCss={RAFButtonConstant.Edit.IconCss}
                                    items={[
                                        { id: 'ViewPhoto', text: 'View Photo' },
                                        { id: 'UploadPhoto', text: 'Upload Photo' },
                                        { id: 'RemovePhoto', text: 'Remove Photo' }
                                    ]}
                                    select={profilePhotoBtnOnclick.bind(this)}
                                />
                            </div>
                            :
                            <div className="d-flex align-item-center">
                                <span className="font-weight-bold logoText justify-content-center">
                                    <span style={{ fontSize: '12px', cursor: "pointer" }}>
                                        <div className="text-center" onClick={onClickUploadPhoto}>
                                            {/* <input className="hidden" type="file" name="image-upload" id="input" accept="image/*" onChange={profilePhotoUpload} /> */}
                                            <label htmlFor="input" className="profile-image-upload-label" style={{ fontWeight: 400, cursor: "pointer", color: '#fff' }}>Upload Photo</label>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        }
                        <div className="d-none">
                            <input
                                type="file"
                                accept="image/*"
                                id="img-upload"
                                className="e-custom-file"
                                onChange={fileChanged} ref={imageUpload} />
                        </div>
                    </div>
                </div>
            )
                :
                (
                    <div className="profilPhotoDiv-container with_shadow">
                        {isNotNullAndUndefined(profilePhoto) ?
                            <div className={`profileImg-container ${props.profileImgCss}`}
                            >
                                <img src={profilePhoto} alt="" className="profileImage" />
                            </div>
                            :
                            emptyAvatar()
                        }
                    </div>
                )
            }
            {state.showViewProfilePhotoContent && (
                <DialogComponent
                    //header="Zoom"  
                    header={isNotNullAndUndefined(props.title) ? props.title : 'Photo'}
                    cssClass="centerDialog-sm fixed-header"
                    visible={state.showViewProfilePhotoContent}
                    content={profilePhotoDlgContent.bind(this)}
                    isModal target='#root' closeOnEscape
                    close={closeViewProfilePhotoDlg.bind(this)}
                    overlayClick={closeViewProfilePhotoDlg.bind(this)}
                    showCloseIcon={true}
                    zIndex={1200}
                />
            )}
            {state.showProfilePhotoEditorDlg && (
                <div className='e-img-editor-profile' id='id="profile-dialog"'>
                    <DialogComponent
                        header={'Upload Photo'}
                        cssClass="centerDialog-sm centerDialog-height-lg fixed-header dlg-new-style image_editor_dialog"
                        visible={state.showProfilePhotoEditorDlg}
                        content={profilePhotoEditorDlgContent.bind(this)}
                        isModal
                        target='#root'
                        id="profile-dialog"
                        ref={uploadPhotoRef}
                        close={closeProfilePhotoEditorDlg.bind(this)}
                        //overlayClick={closeProfilePhotoEditorDlg.bind(this)}
                        showCloseIcon={true}
                        zIndex={1200}
                    />
                </div>
            )}
        </div>
    );
};

// RAFProfilePhoto.defaultProps = {
//     profileImgCss: "profileImg-container-sm",
// };

export default React.memo(RAFProfilePhoto);