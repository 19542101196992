import { debounce } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AutoCompleteComponent,
  ChangeEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import {
  DialogComponent,
  Tooltip,
  TooltipComponent,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import * as React from "react";
import { Field, FieldInputProps, FormRenderProps } from "react-final-form";
import { getEntityByName } from "../../RAFMaster/helpers/RMutils";
import { createInstance } from "../Utility/FormUtility";
import { Constants } from "../../constants/Common/Constants";
import {
  getDefaultFieldNameByModule,
  getDisplayNameByModuleName,
} from "../helpers/RAFMenuHelper";
import {
  Guid,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../helpers/utils";
import { ILookupRow } from "../models/CompositeTypes/ILookupRow";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import RAFLookupGrid from "./RAFLookupGrid";
import { RAFDataManager, RAFUrlAdaptor } from "./RAFUrlAdaptor";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFLookupFieldProps,
  isRequired,
  setFormValue,
} from "./RFFUtils";

interface IState {
  initialValues: {};
  showCreateContent?: boolean;
  showLookupGridContent?: boolean;
  createDialogHeader?: string;
}

class RAFLookupAutoCompleteCC<T> extends React.Component<
  RAFLookupFieldProps<T>,
  IState
> {
  //static defaultProps: Partial<RAFLookupFieldProps<T>>;

  static defaultProps = {
    ...RAFDefaultFieldProps,
    showFullList: false,
    showCreateButton: false,
    closeToolTip: true,
    SearchCreateOptionMode: "Default",
  };

  _isMounted = false;

  private field = this.props.field.toString();
  private fields = { text: "Value", value: "Value" };
  private objLookupRow = isNotNullAndUndefined(this.props.type)
    ? (createInstance(this.props.type) as ILookupRow)
    : null;
  private searchedText;
  private isFiltering = false;
  private tooltip: Tooltip;
  private skip = 0;
  private take: number = Constants.DropdownFetchCount;
  private filteredSkip = 0;
  private filteredTake: number = Constants.DropdownFetchCount;
  private autoCompleteComponent: AutoCompleteComponent;
  //private dropDownListComponent: DropDownListComponent;
  private rafFormContextValue: FormRenderProps;
  //private SearchCreateOptionMode: "Footer" | "Default" = isNotNullAndUndefined(this.props.SearchCreateOptionMode) ? this.props.SearchCreateOptionMode : "Default";

  //private showlookUpGrid = isNotNullAndUndefined(this.props.showFullList) ? this.props.showFullList : true;
  private showFullList =
    this.props.showFullList && isNotNullAndUndefined(this.props.moduleName);
  private showCreateBtn =
    (this.props.showCreateButton === true ||
      (isNotNullAndUndefined(this.objLookupRow) &&
        this.objLookupRow.isOptionCreatable() === true)) &&
    isNotNullAndUndefined(this.props.createform);

  constructor(props) {
    super(props);
    if (isNotNullAndUndefined(this.props.textField)) {
      this.fields.text = this.props.textField;
    }
    if (isNotNullAndUndefined(this.props.valueField)) {
      this.fields.value = this.props.valueField;
    }
    this.state = {
      initialValues: null,
      showCreateContent: false,
      showLookupGridContent: false,
      createDialogHeader: "",
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  private customerData: RAFDataManager = new RAFDataManager({
    adaptor: new RAFUrlAdaptor({
      Skip: this.skip,
      Take: this.take,
      CustomFilter: this.props.customFilter,
      entityName: this.props.moduleName,
    }),
    crossDomain: true,
    url: isNotNullAndUndefined(this.props.url)
      ? Constants.baseAPIUrl + this.props.url
      : Constants.baseAPIUrl + this.objLookupRow.getLookupUrl(),
    requestType: "POST",
    //enableCaching: true,
    //offline: true
    headers: [],
  });

  onFiltering = (args) => {
    args.preventDefaultAction = true;
    this.setFilteringDebounce(args);
  };

  setFilteringDebounce = debounce((args) => {
    this.isFiltering = true;
    this.filteredSkip = 0;
    this.filteredTake = Constants.DropdownFetchCount;
    this.customerData.adaptor = new RAFUrlAdaptor({
      Skip: this.filteredSkip,
      Take: this.filteredTake,
      CustomFilter: this.props.customFilter,
      entityName: this.props.moduleName,
    });
    let query = new Query();
    query =
      args.text !== ""
        ? query.where(this.field, "contains", args.text, true)
        : query;
    this.searchedText = args.text;
    setTimeout(() => {
      args.updateData(this.customerData, query);
    }, 500);
  }, 300);

  actionBegin = (args) => {
    if (args.query === undefined) {
      //temp fix to prevent repeated api calls
      args.cancel = true;
    }
  };

  actionComplete = (e, inputValue) => {
    if (isNotNullAndUndefined(inputValue)) {
      if (!IsNullOrWhiteSpace(inputValue) && !this.isFiltering) {
        if (isNotNullAndUndefined(e.result)) {
          const objIndex = e.result.findIndex(
            (x) => x[this.fields.value] === inputValue
          );
          if (objIndex < 0) {
            let selectedValue = {
              UID: Guid.newGuid(),
              [this.fields.value]: inputValue,
            };
            e.result.push(selectedValue);
          }
        }
      }
    }

    let { addEmpty, emptyString } = this.props;
    let emptyValue = {
      UID: null,
      [this.fields.value]: isNotNullAndUndefined(emptyString)
        ? emptyString
        : "None",
    };
    let dropdownItems = isNotNullAndUndefined(e.result) ? [...e.result] : [];

    if (addEmpty === true && IsNullOrWhiteSpace(this.searchedText)) {
      if (isNotNullAndUndefined(dropdownItems)) {
        dropdownItems.splice(0, 0, emptyValue);
      } else {
        dropdownItems = [];
        dropdownItems.splice(0, 0, emptyValue);
      }
    }
    //let isDropdownValueExist = isNotNullAndUndefined(dropdownItems) && dropdownItems.length > 0 ? dropdownItems.filter(x => x[this.field]);
    //if ()
    e.result = R.uniq(dropdownItems);
    setTimeout(() => {
      //hidepopup if there are no data
      const eNoData =
        this.autoCompleteComponent &&
          this.autoCompleteComponent["popupObj"] &&
          this.autoCompleteComponent["popupObj"]["element"] &&
          this.autoCompleteComponent["popupObj"]["element"].querySelector(
            ".e-nodata"
          )
          ? this.autoCompleteComponent["popupObj"]["element"].querySelector(
            ".e-nodata"
          )
          : null;

      if (isNotNullAndUndefined(eNoData)) {
        this.autoCompleteComponent.hidePopup();
      }
    }, 100);
  };

  created = () => {
    if (this.autoCompleteComponent) {
      this.tooltip = new Tooltip({
        beforeRender: this.onBeforeRender,
        content: "Loading...",
        position: "TopCenter",
        target: isNotNullAndUndefined(this.autoCompleteComponent.element)
          ? `#${this.autoCompleteComponent.element.id}_popup .e-list-item`
          : ".e-list-item",
      });
      this.tooltip.appendTo("body");
    }
  };

  onBeforeRender = (args: TooltipEventArgs) => {
    if (isNotNullAndUndefined(this.tooltip))
      this.tooltip.content = args.target.textContent;
  };

  opened = () => {
    //args.preventDefaultAction = true;
    //let start = 7;
    //let end = 12;
    let listElement: HTMLElement = (this.autoCompleteComponent as any).list;
    listElement.addEventListener("scroll", (e) => {
      if (
        listElement.scrollTop + listElement.offsetHeight + 1 >=
        listElement.scrollHeight
      ) {
        this.setScrollDebounce(e);
      }
    });
  };

  setScrollDebounce = debounce(() => {
    if (isNotNullAndUndefined(this.autoCompleteComponent)) {
      let filterQuery = isNotNullAndUndefined(this.autoCompleteComponent.query)
        ? this.autoCompleteComponent.query.clone()
        : new Query();
      const searchedText = isNotNullAndUndefined(
        this.autoCompleteComponent.value
      )
        ? this.autoCompleteComponent.value.toString()
        : null;
      //this.autoCompleteComponent["searchKeyModule"]["element"]["value"];
      if (isNotNullAndUndefined(searchedText) && searchedText !== "") {
        this.filteredSkip += this.filteredTake;
        filterQuery = filterQuery.where(
          this.field,
          "contains",
          searchedText,
          true
        );
        this.customerData.adaptor = new RAFUrlAdaptor({
          Skip: this.filteredSkip,
          Take: this.filteredTake,
          CustomFilter: this.props.customFilter,
          entityName: this.props.moduleName,
        });
      } else {
        this.skip += this.take;
        this.customerData.adaptor = new RAFUrlAdaptor({
          Skip: this.skip,
          Take: this.take,
          CustomFilter: this.props.customFilter,
          entityName: this.props.moduleName,
        });
      }
      this.customerData
        //.executeQuery(filterQuery.range(start, end))
        .executeQuery(filterQuery)
        .then((event) => {
          //start = end;
          //end += 5;
          if (
            isNotNullAndUndefined(event) &&
            isNotNullAndUndefined((event as any).result) &&
            isNotNullAndUndefined(this.autoCompleteComponent)
          ) {
            this.autoCompleteComponent.addItem(
              (event as any).result as { [key: string]: Object; }[]
            );
          }
        })
        .catch();
    }
  }, 500);

  onClose = () => {
    //let openTooltips = document.querySelectorAll('div.e-tooltip-wrap.e-popup-open[id^="tooltip_"]');
    let openTooltips = document.querySelectorAll(
      "div.e-tooltip-wrap.e-popup-open"
    );
    //if (closeToolTip === true) {
    if (isNotNullAndUndefined(openTooltips)) {
      openTooltips.forEach((x) => {
        x.classList.add("hidden");
      });
    }
    if (isNotNullAndUndefined(this.tooltip)) {
      this.tooltip.close();
    }
  };

  onChange = (e: ChangeEventArgs, input: FieldInputProps<any, HTMLElement>) => {
    input.onChange(e.itemData !== null ? e.itemData[this.fields.value] : null);
    if (isNotNullAndUndefined(this.props.onChanged)) {
      if (isNotNullAndUndefined(e.itemData)) {
        this.props.onChanged(e.itemData[this.fields.value], e.itemData["UID"]);
      } else {
        this.props.onChanged(null, null);
      }
    }
  };

  setValueDelay = (inputValue) => {
    if (isNotNullAndUndefined(inputValue) && !IsNullOrWhiteSpace(inputValue)) {
      setTimeout(() => {
        if (isNotNullAndUndefined(this.autoCompleteComponent)) {
          this.autoCompleteComponent.value = inputValue;
        }
      }, 500);
    }
  };

  noRecordsTemplate1 = (): JSX.Element => {
    return this.showCreateBtn === true &&
      this.props.SearchCreateOptionMode === "Default" ? (
      <div>
        <button
          type="button"
          className="link-button"
          onClick={() => this.openCreateDialog()}
        >
          {" "}
          {'Create "' + this.searchedText + '"'}{" "}
        </button>
      </div>
    ) : (
      <span className="foot">No record found</span>
    );
  };

  noRecordsTemplate = () => {
    let noRecordsTemplate = "<span class='foot'>No record found</span>";
    return noRecordsTemplate;
  };

  footerTemplate = () => {
    return (
      <div className="row g-0 justify-content-between border-top flex-nowrap">
        {this.props.SearchCreateOptionMode === "Footer" &&
          this.showCreateBtn === true && (
            <div className="col-auto d-flex">
              <ButtonComponent
                type="button"
                iconCss="fas fa-plus"
                className="link-button custom-link-btn custom-button-sm"
                onClick={() => this.openCreateDialog()}
                id={`btn_create_lookupData${this.field.toString()}`}
              >
                Create
              </ButtonComponent>
            </div>
          )}
        {this.props.SearchCreateOptionMode === "Footer" &&
          this.showFullList === true && (
            <div className="col d-flex">
              <ButtonComponent
                type="button"
                className="link-button custom-link-btn ms-auto custom-button-sm"
                onClick={() => this.showLookUpGridDialogOpen()}
                id={`btn_show_lookupGrid${this.field.toString()}`}
              >
                <span className="ecllipseFirstLine work-break-all text-wrap">
                  Show full list
                </span>
              </ButtonComponent>
            </div>
          )}
      </div>
    );
  };

  // create dialog starts

  openCreateDialog = () => {
    let openTooltips = document.querySelectorAll("div.e-ddl.e-popup-open");
    if (isNotNullAndUndefined(openTooltips)) {
      openTooltips.forEach((x) => {
        x.classList.add("hidden");
      });
    }
    if (isNotNullAndUndefined(this.autoCompleteComponent)) {
      this.autoCompleteComponent.hidePopup();
    }

    getEntityByName(this.props.moduleName).then(async (entity) => {
      let initialValues = {};
      if (isNotNullAndUndefined(this.props.createformdefaultfield)) {
        initialValues[this.props.createformdefaultfield] = this.searchedText;
      } else {
        let defaultField = await getDefaultFieldNameByModule(
          this.props.moduleName
        );
        initialValues[defaultField] = this.searchedText;
      }

      const createFormInitialValues = this.props.createFormInitialValues;
      if (isNotNullAndUndefined(createFormInitialValues)) {
        createFormInitialValues.forEach((item) => {
          initialValues[item.key] = item.value;
        });
      }

      if (this._isMounted) {
        this.setState({
          showCreateContent: true,
          initialValues: initialValues,
          createDialogHeader: getDisplayNameByModuleName(
            this.props.moduleName,
            isNotNullAndUndefined(entity) ? entity.DisplayName : null
          ),
        });
      }
    });
  };

  showCreateContent = () => {
    if (
      this.state.showCreateContent &&
      isNotNullAndUndefined(this.state.initialValues) &&
      isNotNullAndUndefined(this.props.createform)
    ) {
      const Createform1 = this.props.createform;
      let initialValues = this.state.initialValues;

      return (
        <div>
          <Createform1
            as2={this.props.createform}
            isActive
            moduleName={this.props.moduleName}
            onSave={this.refreshLookupControl.bind(this)}
            onClose={() => this.closeCreateDialog()}
            {...(initialValues ? { initialValues: initialValues } : {})}
          ></Createform1>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  closeCreateDialog = () => {
    if (this._isMounted) {
      this.setState({
        initialValues: null,
        showCreateContent: false,
        createDialogHeader: "",
      });
    }
  };

  // create dialog ends

  // lookUp dialog starts
  showLookUpGridDialogOpen = () => {
    let openTooltips = document.querySelectorAll("div.e-ddl.e-popup-open");
    if (isNotNullAndUndefined(openTooltips)) {
      openTooltips.forEach((x) => {
        x.classList.add("hidden");
      });
    }
    if (isNotNullAndUndefined(this.autoCompleteComponent)) {
      this.autoCompleteComponent.hidePopup();
    }
    if (this._isMounted) {
      this.setState({ showLookupGridContent: true });
    }
  };

  showLookupGridContent = () => {
    let { showLookupGridContent } = this.state;
    if (
      showLookupGridContent === true &&
      isNotNullAndUndefined(this.props.moduleName)
    ) {
      return (
        <RAFLookupGrid
          rowclicked={(value) => this.setLookUpValue(value)}
          onClose={() => this.showLookupGridDialogClose()}
          customFilter={this.props.customFilter}
          moduleName={this.props.moduleName}
          mode="input"
          isActive
          {...this.props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  async setLookUpValue(value) {
    if (isNotNullAndUndefined(value)) {
      let objectName = !IsNullOrWhiteSpace(this.props.selectedField)
        ? value[this.props.selectedField]
        : null;
      let uid = value.UID;
      if (IsNullOrWhiteSpace(objectName)) {
        let defaultField = await getDefaultFieldNameByModule(
          this.props.moduleName
        );
        objectName = value[defaultField];
      }
      if (isNotNullAndUndefined(uid) && isNotNullAndUndefined(objectName)) {
        this.refreshLookupControl(uid, objectName);
      }
    }
  }

  refreshLookupControl = (entityId, objectName) => {
    if (this._isMounted) {
      this.setState(
        {
          initialValues: null,
          showCreateContent: false,
          showLookupGridContent: false,
        },
        () => {
          if (isNotNullAndUndefined(this.autoCompleteComponent)) {
            const ddd = this.autoCompleteComponent;
            setTimeout(() => {
              this.searchedText = "";
              let filterQuery = new Query();
              this.filteredSkip = 0;
              this.customerData.adaptor = new RAFUrlAdaptor({
                Skip: this.filteredSkip,
                Take: this.filteredTake,
                CustomFilter: this.props.customFilter,
                entityName: this.props.moduleName,
              });
              ddd.dataSource = this.customerData;
              ddd.query = filterQuery;
              ddd.value = objectName;
              ddd.text = objectName;
              ddd.refresh();
            }, 100);

            setFormValue(this.rafFormContextValue, this.field, objectName);

            if (isNotNullAndUndefined(this.props.onChanged)) {
              if (
                isNotNullAndUndefined(entityId) &&
                isNotNullAndUndefined(objectName)
              ) {
                this.props.onChanged(objectName, entityId);
              } else {
                this.props.onChanged(null, null);
              }
            }
          }
        }
      );
    }
  };

  showLookupGridDialogClose = () => {
    if (this._isMounted) {
      this.setState({ showLookupGridContent: false });
    }
  };
  // lookUp dialog end

  render() {
    let {
      showLabel,
      required,
      label,
      field,
      initialValue,
      validate,
      validators,
      width,
      disabled,
      placeholder,
      showClearButton,
      SearchCreateOptionMode,
      description,
      descriptionAsLabel,
    } = this.props;
    let { showLookupGridContent, showCreateContent } = this.state;
    let labelClassName = isNotNullAndUndefined(this.props.labelClassName)
      ? this.props.labelClassName
      : "";

    return (
      <RAFFormContext.Consumer>
        {(rafFormContextValue) => {
          this.rafFormContextValue = rafFormContextValue;
          return (
            <div
              className={
                isNotNullAndUndefined(this.props.formGroupClassName)
                  ? this.props.formGroupClassName + " form-group"
                  : "form-group"
              }
            >
              <div className={RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                  <RAFFieldLabel
                    field={field}
                    label={label}
                    required={required}
                    labelClassName={labelClassName}
                    description={description}
                    descriptionAsLabel={descriptionAsLabel}
                  ></RAFFieldLabel>
                )}
                <div className="col-12">
                  <Field
                    name={field.toString()}
                    {...(initialValue ? { initialValue: initialValue } : {})}
                    //validate={validate === true ? (required && isRequired) : null}
                    {...(validators
                      ? {
                        validate:
                          validate === true
                            ? composeValidators(
                              required === true ? isRequired : null,
                              ...validators
                            )
                            : null,
                      }
                      : {
                        validate:
                          validate === true
                            ? composeValidators(
                              required === true ? isRequired : null
                            )
                            : null,
                      })}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                  >
                    {({ input, meta }) => {
                      //thisInput = input;
                      this.setValueDelay(input.value);
                      return (
                        <div className="row g-2">
                          <div className="col">
                            <AutoCompleteComponent
                              id={`ddl_${field.toString()}`}
                              ref={(g) => (this.autoCompleteComponent = g)}
                              name={input.name}
                              value={
                                isNotNullAndUndefined(input.value) &&
                                  !IsNullOrWhiteSpace(input.value)
                                  ? input.value
                                  : null
                              }
                              change={(e) => {
                                if (e.isInteracted) {
                                  this.onChange(e, input);
                                }
                              }}
                              //showPopupButton
                              autofill
                              width={width}
                              readonly={disabled}
                              fields={this.fields}
                              dataSource={this.customerData}
                              noRecordsTemplate={this.noRecordsTemplate()}
                              footerTemplate={this.footerTemplate}
                              allowFiltering
                              filtering={this.onFiltering.bind(this)}
                              actionBegin={this.actionBegin.bind(this)}
                              actionComplete={(e) =>
                                this.actionComplete(e, input.value)
                              }
                              created={(e) => this.created()}
                              close={this.onClose.bind(this)}
                              open={(e) => this.opened()}
                              showClearButton={showClearButton}
                              placeholder={placeholder}
                              cssClass={
                                meta.error && meta.touched
                                  ? "inputFieldError"
                                  : null
                              }
                              highlight={true}
                            />

                            {this.props.hideRequiredMessage !== true ? (
                              <RAFFieldError name={field.toString()} />
                            ) : (
                              ""
                            )}
                          </div>
                          {SearchCreateOptionMode === "Default" &&
                            this.showFullList === true && (
                              <div className="col-auto">
                                <TooltipComponent
                                  content="Show more"
                                  position="BottomCenter"
                                >
                                  <ButtonComponent
                                    type="button"
                                    cssClass="input-icon-btn"
                                    iconCss="fas fa-magnifying-glass pt-1"
                                    onClick={() =>
                                      this.showLookUpGridDialogOpen()
                                    }
                                  ></ButtonComponent>
                                </TooltipComponent>
                              </div>
                            )}
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </div>
              {showLookupGridContent === true && (
                <DialogComponent
                  visible
                  //cssClass="centerDialog-lg dlg-new-style fixed-height"
                  cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
                  //ref={dialog => this.showLookupGridDialog = dialog}
                  content={this.showLookupGridContent.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={() => this.showLookupGridDialogClose.bind(this)}
                ></DialogComponent>
              )}
              {showCreateContent === true && (
                <DialogComponent
                  header={"Create " + this.state.createDialogHeader}
                  visible
                  cssClass="rightDialog createEditForm full-height form-center-dialog" //ref={dialog => this.showLookupGridDialog = dialog}
                  content={this.showCreateContent.bind(this)}
                  showCloseIcon
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={this.closeCreateDialog.bind(this)}
                ></DialogComponent>
              )}
            </div>
          );
        }}
      </RAFFormContext.Consumer>
    );
  }
}

export default RAFLookupAutoCompleteCC;
