import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { isNotNullAndUndefined } from "../helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators } from "./RAFForm";
import {
  isRequired,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFTextBoxWithIconFieldProps,
} from "./RFFUtils";

interface IProps {
  onKeyPressed?: (value) => void;
}
function RAFTextBoxWithIcon<T>({
  field,
  label,
  width,
  created,
  onChanged,
  children,
  fieldInnerText,
  iconCss,
  formGroupClassName,
  labelClassName,
  description,
  descriptionAsLabel,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFTextBoxWithIconFieldProps<T> & IProps>) {
  return (
    <div
      className={
        isNotNullAndUndefined(formGroupClassName)
          ? formGroupClassName + " form-group"
          : "form-group"
      }
      id={"rafdiv" + field.toString()}
    >
      <div className={RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className={"col-12"}>
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => (
              <div className="e-input-group e-float-icon-left">
                {isNotNullAndUndefined(iconCss) ? (
                  <span className={"e-input-group-icon " + iconCss}></span>
                ) : null}
                <div className="e-input-in-wrap">
                  <TextBoxComponent
                    id={`tta_${field.toString()}`}
                    value={input.value}
                    name={input.name}
                    change={(e) => {
                      if (e.isInteracted) {
                        input.onChange(e.value);
                        if (isNotNullAndUndefined(onChanged)) {
                          onChanged(e.value);
                        }
                      }
                    }}
                    type="text"
                    created={(e) =>
                      isNotNullAndUndefined(created)
                        ? created(input.name)
                        : null
                    }
                    width={width && width !== null ? width : "100%"}
                    showClearButton={showClearButton}
                    placeholder={props.placeholder}
                    disabled={disabled}
                    cssClass={
                      meta.error && meta.touched ? "inputFieldError" : null
                    }
                    {...(isNotNullAndUndefined(props.onKeyPressed)
                      ? { input: (e) => props.onKeyPressed(e.value) }
                      : {})}
                  />
                  {isNotNullAndUndefined(props.customButtons) ? (
                    <div className="me-3">{props.customButtons}</div>
                  ) : null}
                </div>
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFTextBoxWithIcon;
