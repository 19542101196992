import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import * as React from "react";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { Guid, IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import CustomCardWidget from "../Navigation/CustomCardWidget";
import RAFCollapse from "../Navigation/RAFCollapse";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import { GetFieldsDisplayName } from "../helpers/AppHelper";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFRadioButtonListFieldProps,
  isRequired,
} from "./RFFUtils";

const getDataFromChildren = (children?) => {
  let retVal = [];
  if (isNotNullAndUndefined(children)) {
    React.Children.forEach(children, (child) => {
      retVal.push({
        ...child["props"],
        label: child["props"]["children"] || child["props"]["label"],
        iconCss: child["props"]["IconCss"],
        icon: child["props"]["Icon"],
      });
    });
  }
  return retVal;
};

//const RAFRadioButtonList = ({ field, label, required, children }) => {
function RAFRadioButtonList<T>({
  field,
  label,
  onChanged,
  children,
  labelPosition,
  description,
  descriptionAsLabel,
  radioBtnClassName,
  titleLocation,


  uitype = "default",
  editorStyle = 'Default',
  radioBtnRowClassName = 'gx-2',

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFRadioButtonListFieldProps<T>>) {
  let items = getDataFromChildren(React.Children.toArray(children));
  const [checkedIndex, setCheckedIndex] = useState(null);
  const [isOpen, setStateIsOpen] = React.useState(false);
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : "col-12";
  if (titleLocation === "Right") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-auto order-last";
    inputFieldClassName = "col";
  } else if (titleLocation === "Left") {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = "col-3";
    inputFieldClassName = "col";
  } else if (titleLocation === "Bottom") {
    labelClassName = "order-last";
  }

  const radioChecked = (value, input, index) => {
    setCheckedIndex(index);
    //input.onChange(e.target.value);
    input.onChange(value);
    if (isNotNullAndUndefined(onChanged)) {
      //onChanged(e.target.value);
      onChanged(value);
    }
    if (editorStyle === 'Collapsible') {
      toggleCardContent('Close');
    }

  };

  useEffect(() => {
    const initTribute = () => {
      if (
        isNotNullAndUndefined(rafFormContextValue) &&
        isNotNullAndUndefined(rafFormContextValue.values)
      ) {
        let selectedValue = rafFormContextValue.values[field.toString()];
        let settingsJson = rafFormContextValue.values["SettingsJson"];
        let isPrivate = settingsJson && settingsJson["IsPrivate"];
        if (isNotNullAndUndefined(isPrivate))
          setCheckedIndex(isPrivate && isPrivate.toString() === "true" ? 0 : 1);
        else
          setCheckedIndex(
            selectedValue && selectedValue.toString() === "true" ? 0 : 1
          );
      }
    };
    initTribute();
  });

  const chioceOptionContent = (input) => {
    return (
                                <div className={`radio_button_${isNotNullAndUndefined(props.colorStyle) ? props.colorStyle : ''}`}>
                                {
          // (editorStyle === "Collapsible") ? (
          //   <CustomCardWidget
          //     removeContentPadding
          //   >
          //     <RAFCollapse
          //       customTitle={<div className="header-text-sm-light">{isNotNullAndUndefined(input.value) ? input.value : 'Not set'}</div>}
          //       showCardSeparator
          //       IsCollapsed
          //       removeContentCardPadding
          //     >
          //       {items.map((item, index) => {
          //         let indexVal: string = Guid.newGuid();
          //         return (
          //           <div
          //             className={
          //               isNotNullAndUndefined(radioBtnClassName)
          //                 ? radioBtnClassName
          //                 : items.length > 2 ? "col-auto" : "col"
          //             }
          //             key={index}
          //             //moving onclick to parent as it is not working inside dialog

          //             onClick={() => {
          //               radioChecked(item.value, input, index);
          //             }}
          //           >
          //             <input
          //               style={{ display: "none" }}
          //               type="radio"
          //               id={"radio_" + indexVal}
          //               name={item.name}
          //               value={item.value}
          //               onChange={(e) => {
          //                 //radioChecked(item.value, input, index);
          //                 //moving onclick to parent as it is not working inside dialog
          //               }}
          //               checked={input.value === item.value}
          //               disabled={disabled}
          //             />
          //             <label
          //               className={
          //                 input.value === item.value
          //                   ? `custom-radio-btn list-radio-btn active`
          //                   : `custom-radio-btn list-radio-btn`
          //               }
          //               htmlFor={"radio_" + indexVal}
          //               {...isNotNullAndUndefined(item.colorCode) ? { style: { backgroundColor: item.colorCode, color: "#fff" } } : {}}

          //             >
          //               {isNotNullAndUndefined(item.IconCss) ? (
          //                 <i className={item.IconCss + " pe-1"}></i>
          //               ) : null}
          //               {item.label}
          //             </label>
          //           </div>
          //         );
          //       })}
          //     </RAFCollapse>
          //   </CustomCardWidget>
          // ) :
          uitype === "userToggle" ? (
            <div className="groupedBtn">
              {items.map((item, index) => {
                let indexVal: string = Guid.newGuid();
                return (
                  <TooltipComponent
                    key={index}
                    //  target={"#radio_" + index}
                    content={item.label}
                    position="TopCenter"
                  >
                    <div
                      className={
                        checkedIndex === index
                          ? "e-radioBtn_" + index + " active"
                          : "e-radioBtn_" + index
                      }
                      id={"radio_" + index}
                      title={item.label}
                    >
                      <label className="customToggle">
                        {/*id = { item.value.toString() }*/}
                        <input
                          style={{ display: "none" }}
                          id={item.value.toString() + indexVal}
                          type="radio"
                          name={input.name}
                          value={item.value}
                          onChange={(e) => {
                            radioChecked(item.value, input, index);
                          }}
                          checked={input.value === item.value}
                          disabled={disabled}
                        />
                        <span
                          style={{ cursor: "pointer" }}
                          className={
                            item.value === true
                              ? checkedIndex === index
                                ? "fas fa-user active"
                                : "fas fa-user"
                              : checkedIndex === index
                                ? "fas fa-users active"
                                : "fas fa-users"
                          }
                        ></span>
                      </label>
                    </div>
                  </TooltipComponent>
                );
              })}
            </div>
          ) : uitype === "yesNoToggle" ? (
            <div className={items.length > 0 ? `row g-0${IsNotNullOrWhiteSpace(radioBtnRowClassName) ? ` ${radioBtnRowClassName}` : ''}` : `row g-0 gx-2`}>
              {items.map((item, index) => {
                let indexVal: string = Guid.newGuid();
                return (
                  <div
                    className={
                      isNotNullAndUndefined(radioBtnClassName)
                        ? radioBtnClassName
                        : "col"
                    }
                    key={index}
                    //moving onclick to parent as it is not working inside dialog

                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (disabled) return;
                      radioChecked(item.value, input, index);
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      id={"radio_" + indexVal}
                      name={item.name}
                      value={item.value}
                      onChange={(e) => {
                      }}
                      checked={input.value === item.value}
                      disabled={disabled}
                    />
                    <label
                      className={
                        input.value === item.value
                          ? `yes_no_btn custom-radio-btn active`
                          : `yes_no_btn custom-radio-btn`
                      }
                      htmlFor={"radio_" + indexVal}
                      {...isNotNullAndUndefined(item.colorCode) ? { style: { backgroundColor: item.colorCode, color: "#fff" } } : {}}

                    >
                      {input.value === item.value ? (
                        <i className={`${isNotNullAndUndefined(item.IconCss) ? item.IconCss : 'fas fa-check'} me-2`}></i>
                      ) : null}
                      {item.label}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : uitype === "button" ||
            uitype === "quickselect" ? (
            <div
              className={`${isNotNullAndUndefined(props.groupBtnClassName)
                ? props.groupBtnClassName
                : ""
                } ${uitype === "quickselect"
                  ? "cssradio buttonWithSeparator"
                  : "cssradio"
                }`}
            >
              {items.map((item, index) => {
                let indexVal: string = Guid.newGuid();
                return (
                  <div
                    className="d-flex align-items-center w-100"
                    key={index}
                  >
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      id={"radio_" + indexVal}
                      name={item.name}
                      value={item.value}
                      onChange={(e) => {
                        radioChecked(item.value, input, index);
                      }}
                      checked={input.value === item.value}
                      disabled={disabled}
                    />
                    {item.IconCss ? (
                      <label
                        className="d-flex align-items-center"
                        htmlFor={"radio_" + indexVal}
                      >
                        <i className={`pe-1 ${item.IconCss}`}></i>

                        <span>{item.label}</span>
                      </label>
                    ) : (
                      <label htmlFor={"radio_" + indexVal}>
                        {item.label}
                      </label>
                    )}
                  </div>
                );
              })}
            </div>
          ) : uitype === "groupButton" ? (
            <div
              className={
                isNotNullAndUndefined(props.groupBtnClassName)
                  ? `${props.groupBtnClassName} cssradio groupButton`
                  : `cssradio groupButton`
              }
            >
              {items.map((item, index) => {
                let indexVal: string = Guid.newGuid();
                return (
                  <div
                    className="d-flex align-items-center groupButton-item w-100"
                    key={index}
                  >
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      id={"radio_" + indexVal}
                      name={item.name}
                      value={item.value}
                      onChange={(e) => {
                        radioChecked(item.value, input, index);
                      }}
                      checked={input.value === item.value}
                      disabled={disabled === true ? disabled : item.disabled === true ? item.disabled : false}
                    />
                    <label className={disabled === true ? "text-line-through" : item.disabled === true ? "text-line-through" : ''} htmlFor={"radio_" + indexVal}>
                      {item.label}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : (uitype === "customButton" || uitype === "customButtonWithColor") ? (
            <div className={items.length > 0 ? `row g-0${IsNotNullOrWhiteSpace(radioBtnRowClassName) ? ` ${radioBtnRowClassName}` : ''}${items.length > 4 ? ' flex-column' : ''}` : `row g-0 gx-2`}>
              {items.map((item, index) => {
                let indexVal: string = Guid.newGuid();
                return (
                  <div
                    className={
                      isNotNullAndUndefined(radioBtnClassName)
                        ? radioBtnClassName
                        : items.length > 2 ? "col-auto" : "col"
                    }
                    key={index}
                    //moving onclick to parent as it is not working inside dialog

                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (disabled) return;
                      radioChecked(item.value, input, index);
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      type="radio"
                      id={"radio_" + indexVal}
                      name={item.name}
                      value={item.value}
                      onChange={(e) => {
                        //radioChecked(item.value, input, index);
                        //moving onclick to parent as it is not working inside dialog
                      }}
                      checked={input.value === item.value}
                      disabled={disabled}
                    />
                    <label
                      className={
                        input.value === item.value
                          ? `custom-radio-btn active ${uitype === "customButtonWithColor" ? "custom-radio-btn-color" : ""}`
                          : `custom-radio-btn ${uitype === "customButtonWithColor" ? "custom-radio-btn-color" : ""}`
                      }
                      htmlFor={"radio_" + indexVal}
                      {...isNotNullAndUndefined(item.colorCode) ? { style: { backgroundColor: item.colorCode, color: "#fff" } } : {}}

                    >
                      {isNotNullAndUndefined(item.IconCss) ? (
                        <i className={item.IconCss + " pe-1"}></i>
                      ) : null}
                      {item.label}
                      {/* {input.value === item.value ?
                                                                            <span className="e-icon e-selection ps-2"></span> : null} */}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : (uitype === "customToggleButton") ? (
            <div className="customToggleButton_outer">
              <div className={`row g-0${IsNotNullOrWhiteSpace(radioBtnRowClassName) ? ` ${radioBtnRowClassName}` : ''}`}>
                {items.map((item, index) => {
                  let indexVal: string = Guid.newGuid();
                  return (
                    <div
                      className={
                        isNotNullAndUndefined(radioBtnClassName)
                          ? radioBtnClassName
                          : items.length > 2 ? "col-auto" : "col"
                      }
                      key={index}
                      //moving onclick to parent as it is not working inside dialog

                      onClick={() => {
                        if (disabled) return;
                        radioChecked(item.value, input, index);
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        id={"radio_" + indexVal}
                        name={item.name}
                        value={item.value}
                        onChange={(e) => {
                          //radioChecked(item.value, input, index);
                          //moving onclick to parent as it is not working inside dialog
                        }}
                        checked={input.value === item.value}
                        disabled={disabled}
                      />
                      <label
                        className={
                          input.value === item.value
                            ? `custom-radio-btn active customToggleButton`
                            : `custom-radio-btn customToggleButton`
                        }
                        htmlFor={"radio_" + indexVal}
                        {...isNotNullAndUndefined(item.colorCode) ? { style: { backgroundColor: item.colorCode, color: "#fff" } } : {}}

                      >
                        {isNotNullAndUndefined(item.IconCss) ? (
                          <i className={item.IconCss + " pe-1"}></i>
                        ) : null}
                        {item.label}
                        {/* {input.value === item.value ?
                                                                            <span className="e-icon e-selection ps-2"></span> : null} */}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          ) :
            (uitype === "tabButton") ? (
              <div className="tabButton_outer">
                <div className={`row g-0${IsNotNullOrWhiteSpace(radioBtnRowClassName) ? ` ${radioBtnRowClassName}` : ''}`}>
                  {items.map((item, index) => {
                    let indexVal: string = Guid.newGuid();
                    return (
                      <div
                        className={
                          isNotNullAndUndefined(radioBtnClassName)
                            ? radioBtnClassName
                            : items.length > 2 ? "col-auto" : "col"
                        }
                        key={index}
                        //moving onclick to parent as it is not working inside dialog

                        onClick={() => {
                          if (disabled) return;
                          radioChecked(item.value, input, index);
                        }}
                      >
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          id={"radio_" + indexVal}
                          name={item.name}
                          value={item.value}
                          onChange={(e) => {
                            //radioChecked(item.value, input, index);
                            //moving onclick to parent as it is not working inside dialog
                          }}
                          checked={input.value === item.value}
                          disabled={disabled}
                        />
                        <label
                          className={
                            input.value === item.value
                              ? `custom-radio-btn active customToggleButton`
                              : `custom-radio-btn customToggleButton`
                          }
                          htmlFor={"radio_" + indexVal}
                          {...isNotNullAndUndefined(item.colorCode) ? { style: { backgroundColor: item.colorCode, color: "#fff" } } : {}}

                        >
                          {isNotNullAndUndefined(item.IconCss) ? (
                            <i className={item.IconCss + " pe-1"}></i>
                          ) : null}
                          {item.label}
                          {/* {input.value === item.value ?
                                                                            <span className="e-icon e-selection ps-2"></span> : null} */}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
              : uitype === "smileyToggle" ? (
                <div className="row g-2">
                  {items.map((item, index) => {
                    let indexVal: string = Guid.newGuid();
                    return (
                      <div
                        className={
                          isNotNullAndUndefined(radioBtnClassName)
                            ? radioBtnClassName
                            : "col"
                        }
                        key={index}
                        //moving onclick to parent as it is not working inside dialog

                        onClick={() => {
                          if (disabled) return;
                          radioChecked(item.value, input, index);
                        }}
                      >
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          id={"radio_" + indexVal}
                          name={item.name}
                          value={item.value}
                          onChange={(e) => {
                            //radioChecked(item.value, input, index);
                            //moving onclick to parent as it is not working inside dialog
                          }}
                          checked={input.value === item.value}
                          disabled={disabled}
                        />
                        <label
                          className={
                            input.value === item.value
                              ? "custom-radio-btn active"
                              : "custom-radio-btn"
                          }
                          htmlFor={"radio_" + indexVal}
                        >
                          {isNotNullAndUndefined(item.Icon) ? (
                            <i className={item.Icon + " pe-1"}></i>
                          ) : null}
                          {item.label}
                          {/* {input.value === item.value ?
                                                                            <span className="e-icon e-selection ps-2"></span> : null} */}
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="row g-3 gy-2">
                  {items.map((item, index) => {
                    return (
                      <div
                        className={
                          isNotNullAndUndefined(radioBtnClassName)
                            ? radioBtnClassName
                            : "col-auto"
                        }
                        key={index}
                        //moving onclick to parent as it is not working inside dialog

                        onClick={() => {
                          if (disabled) return;
                          input.onChange(item.value);

                          if (isNotNullAndUndefined(onChanged)) {
                            onChanged(item.value);
                          }
                        }}
                      >
                        <RadioButtonComponent
                          className={
                            props.radioButtonSM === true
                              ? "radioButton-sm"
                              : ""
                          }
                          //id={`rbn_${item.value.toString()}`}
                          key={index}
                          label={item.label}
                          value={item.value}
                          name={item.name}
                          change={(e) => {
                            //moving onclick to parent as it is not working inside dialog
                            //  input.onChange(e !== null ? e.value : null);
                            //             if (isNotNullAndUndefined(onChanged)) {
                            //                 onChanged(e !== null ? e.value : null);
                            //             }
                          }}
                          checked={input.value === item.value}
                          disabled={disabled}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
        {/*{meta.error && meta.touched && <span className={props.hideRequiredMessage === true ? "hidden" : "fieldErrorMsg"}>{meta.error}</span>}*/}
        {props.hideRequiredMessage !== true && (
          <RAFFieldError name={field.toString()} />
        )}
      </div>
    );
  };

  const getSelectedOptionText = (input) => {
    let selectedOption = items.find(x => x.value === input.value);
    if (isNotNullAndUndefined(selectedOption)) {
      return (
        <div>
          {uitype === 'smileyToggle' && <i className={selectedOption.Icon + " pe-1"}></i>}
          {isNotNullAndUndefined(selectedOption) && isNotNullAndUndefined(selectedOption.label) ? selectedOption.label : 'Not set'}
        </div>
      );
    }
    else {
      return '';
    }
  };

  const toggleCardContent = (openState?: 'Open' | 'Close') => {
    if (openState === 'Open') {
      setStateIsOpen(true);
    }
    else if (openState === 'Close') {
      setStateIsOpen(false);
    }
    else {
      setStateIsOpen(!isOpen);
    }
  };

  const customTitle = (input) => {
    return (<div className="w-100 px-3 py-2 pointer"
      onClick={() => toggleCardContent()}
    >
      {isOpen ? <div className="row gx-3 gy-2 justify-content-between">
        <div className="col">
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
            labelClassName="col"
            labelClass="p-0"
            labelSecondaryClass='p-0'
          ></RAFFieldLabel>
        </div>
        <div className="col-auto">
          <i className="fas fa-chevron-up fa-xsm"></i>
        </div>
      </div>
        :
        <div className="row gx-3">
          <div className="col">
            <div className="row gx-3 gy-2 justify-content-between flex-wrap">
              {showLabel && showLabel === true && (
                <RAFFieldLabel
                  field={field}
                  label={label}
                  required={required}
                  description={description}
                  descriptionAsLabel={descriptionAsLabel}
                  showSecondLabel={false}
                  labelClass="p-0"
                  labelClassName="col"
                ></RAFFieldLabel>
              )}
              <div className="col-auto">
                <div className="header-text" style={{ lineHeight: '1.75rem' }}>{isNotNullAndUndefined(input.value) && isNotNullAndUndefined(items) ? getSelectedOptionText(input) : 'Not set'}
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto">
            <i className="fas fa-chevron-down fa-xsm"></i>
          </div>
        </div>
      }
    </div>);
  };

  return (
    <div className="w-100">
      <div
        className={
          props.radioButtonSM === true
            ? "radioButton-sm radio-button"
            : "radio-button"
        }
        id={"rafdiv" + field.toString()}
      >
        <div
          className={
            isNotNullAndUndefined(props.formGroupClassName)
              ? props.formGroupClassName + " form-group align-items-center py-0"
              : "form-group align-items-center py-0"
          }
        >
          {(editorStyle === "Collapsible") ? (
            <div className={inputFieldClassName}>
              <Field
                name={field.toString()}
                {...(props.initialValue
                  ? { initialValue: props.initialValue }
                  : {})}
                //validate={validate === true ? (required && isRequired) : null}
                {...(props.validators
                  ? {
                    validate:
                      validate === true
                        ? composeValidators(
                          required === true ? isRequired : null,
                          ...props.validators
                        )
                        : null,
                  }
                  : {
                    validate:
                      validate === true
                        ? composeValidators(
                          required === true ? isRequired : null
                        )
                        : null,
                  })}
                allowNull
                parse={(value) => (value === "" ? null : value)}
              >
                {({ input, meta }) => (
                  <div>
                    <CustomCardWidget
                      removeContentPadding
                      headerTemplate={customTitle(input)}
                      cardContentClassName={!isOpen ? "hidden" : "p-2 px-3"}
                      showCardSeparator={isOpen ? true : false}
                    >
                      {chioceOptionContent(input)}
                      {false && <RAFCollapse
                        customTitle={
                          <div className="w-100">
                            <div className="row gx-3 gy-2 justify-content-between">
                              {showLabel && showLabel === true && (
                                <div className="col-auto">
                                  <RAFFieldLabel
                                    field={field}
                                    label={label}
                                    required={required}
                                    description={description}
                                    descriptionAsLabel={descriptionAsLabel}
                                    labelClassName={labelClassName}
                                    showSecondLabel={false}
                                    unsetHeight
                                  ></RAFFieldLabel>
                                </div>
                              )}
                              <div className="col-auto">
                                <div className="header-text">{isNotNullAndUndefined(input.value) && isNotNullAndUndefined(items) ? getSelectedOptionText(input) : 'Not set'}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        customTitleOnExpand={
                          <RAFFieldLabel
                            field={field}
                            label={label}
                            required={required}
                            description={description}
                            descriptionAsLabel={descriptionAsLabel}
                            labelClassName={labelClassName}
                            labelClass="p-0"
                            labelSecondaryClass='p-0'
                          ></RAFFieldLabel>
                        }
                        showCardSeparator
                        IsCollapsed
                        removeContentCardPadding
                        contentCardClassName="p-2 px-3"
                      >
                        {chioceOptionContent(input)}
                      </RAFCollapse>
                      }
                    </CustomCardWidget>
                    {props.hideRequiredMessage !== true && (
                      <RAFFieldError name={field.toString()} />
                    )}
                  </div>
                )}
              </Field>
            </div>
          ) :
            <div
              className={rowClassName}
            // className={uitype === 'yesNoToggle' || uitype === "groupButton" ? "row g-1" : "row g-0"}
            >
              {showLabel && showLabel === true && (
                <RAFFieldLabel
                  field={field}
                  label={label}
                  required={required}
                  description={description}
                  descriptionAsLabel={descriptionAsLabel}
                  labelClassName={labelClassName}
                ></RAFFieldLabel>
              )}
              <div className={inputFieldClassName}>
                <Field
                  name={field.toString()}
                  {...(props.initialValue
                    ? { initialValue: props.initialValue }
                    : {})}
                  //validate={validate === true ? (required && isRequired) : null}
                  {...(props.validators
                    ? {
                      validate:
                        validate === true
                          ? composeValidators(
                            required === true ? isRequired : null,
                            ...props.validators
                          )
                          : null,
                    }
                    : {
                      validate:
                        validate === true
                          ? composeValidators(
                            required === true ? isRequired : null
                          )
                          : null,
                    })}
                  allowNull
                  parse={(value) => (value === "" ? null : value)}
                >
                  {({ input, meta }) => (
                    chioceOptionContent(input)
                  )}
                </Field>
              </div>
              {isNotNullAndUndefined(labelPosition) &&
                labelPosition === "right" ? (
                <div
                  className={
                    props.labelClassName ? props.labelClassName : "col-12"
                  }
                >
                  {showLabel && showLabel === true && (
                    <RAFAttributesContext.Consumer>
                      {({ queryAttributes }) => {
                        return (
                          <label
                            className={
                              required ? "form-label required" : "form-label"
                            }
                          >
                            {GetFieldsDisplayName(
                              queryAttributes,
                              field.toString(),
                              label
                            )}{" "}
                          </label>
                        );
                      }}
                    </RAFAttributesContext.Consumer>
                  )}
                </div>
              ) : null}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default RAFRadioButtonList;
