import { PropsWithChildren, useContext, useState } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { FaStar } from "react-icons/fa";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFStarRatingFieldProps,
  isRequired,
} from "./RFFUtils";

function RAFStartRatingInput<T>({
  field,
  label,
  fieldInnerText,
  length,
  allowSubmit,
  autoSave,
  labelClassName,
  description,
  descriptionAsLabel,
  children,
  titleLocation,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFStarRatingFieldProps<T>>) {
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  labelClassName = isNotNullAndUndefined(labelClassName)
    ? labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : 'col-12';
  if (titleLocation === 'Right') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-auto order-last';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Left') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-3';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Bottom') {
    labelClassName = 'order-last';
  }

  const colors = {
    fillColor: "#FFBA5A",
    defaultColor: "#a9a9a9",
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    stars: {
      display: "flex",
    },
  };

  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  let stars = Array(isNotNullAndUndefined(length) ? length : 5).fill(0);

  const handleClick = (value) => {
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      setTimeout(() => {
        rafFormContextValue.form.mutators.setValue(field, value);
        setCurrentValue(value);

        if (
          isNotNullAndUndefined(allowSubmit) &&
          allowSubmit === true &&
          isNotNullAndUndefined(autoSave) &&
          autoSave === true
        ) {
          rafFormContextValue.form.submit();
        }
      }, 500);
    }
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className={inputFieldClassName}>
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input }) => (
              <div className="w-100">
                <div
                  className={
                    isNotNullAndUndefined(fieldInnerText)
                      ? "fieldInnerText w-100"
                      : "w-100"
                  }
                >
                  <input
                    style={{ display: "none" }}
                    name={input.name}
                    value={input.value}
                    readOnly
                  />
                  <div style={styles.stars}>
                    {stars &&
                      stars.map((_, index) => {
                        return (
                          <FaStar
                            id={`fastar_${field.toString()}`}
                            key={index}
                            size={24}
                            onClick={() => handleClick(hoverValue)}
                            onMouseOver={() => handleMouseOver(index + 1)}
                            onMouseLeave={handleMouseLeave}
                            color={
                              (hoverValue || currentValue || input.value) >
                                index
                                ? colors.fillColor
                                : colors.defaultColor
                            }
                            style={{
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                          />
                        );
                      })}
                  </div>

                  {isNotNullAndUndefined(fieldInnerText) && (
                    <span className="fieldInnerLabel">{fieldInnerText}</span>
                  )}
                </div>

                {props.hideRequiredMessage !== true ? (
                  <RAFFieldError name={field.toString()} />
                ) : (
                  ""
                )}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFStartRatingInput;
