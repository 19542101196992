import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, useReducer, useRef } from "react";
import {
  DocumentImageAttachmentRow,
  getFileSize,
} from "../../RAFModules/ActiveContacts/Document/Library/DocumentHelper";
import { RAFButtonConstant } from "../../constants/Common/Constants";
import CustomCardWidget from "../Navigation/CustomCardWidget";
import RAFButtonComponent from "../Navigation/RAFButtonComponent";
import RAFIconImage from "../Navigation/RAFIconImage";
import { RafClearDialogContent } from "../helpers/AppHelper";
import { Guid, isNotEmptyArray, isNotNullAndUndefined } from "../helpers/utils";
import RAFProfilePhotoImageEditor from "./RAFProfilePhotoImageEditor";
import "./img_card_style.scss";

interface IProps {
  fileAttchements: DocumentImageAttachmentRow[];
  onChangeAttachment: (fileAttchements: DocumentImageAttachmentRow[]) => void;
}

interface IState {
  showAttachmentEditorDlg: boolean;
  showViewAttachmentContent: boolean;
  selectedAttachment: DocumentImageAttachmentRow;

  selectedAttachmentFileName: string;
  selectedAttachmentPhoto: string;
}

function RAFImageFileAttachment({ ...props }: PropsWithChildren<IProps>) {
  const imageUpload = useRef(null);
  const imageFileAttachmentRef = useRef<DialogComponent>(null);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showAttachmentEditorDlg: false,
      showViewAttachmentContent: false,
      selectedAttachment: null,

      selectedAttachmentFileName: null,
      selectedAttachmentPhoto: null,
    }
  );

  /*const updateAttachment = async (uploadAttachmentURL) => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setState({ showAttachmentEditorDlg: false });
    const newFileAttchements = isNotEmptyArray(props.fileAttchements)
      ? [...props.fileAttchements]
      : [];
    const fileData = uploadAttachmentURL;
    const newAttachment: DocumentImageAttachmentRow = {
      UID: Guid.newGuid(),
      Name: fileData.name,
      File: fileData,
    };
    const newUploadedFile = isNotEmptyArray(newFileAttchements)
      ? [...newFileAttchements, newAttachment]
      : [newAttachment];
    if (props.onChangeAttachment) {
      props.onChangeAttachment(newUploadedFile);
    }
  };*/

  const updateAttachment = async (uploadAttachmentURL) => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setState({ showAttachmentEditorDlg: false });
    const newFileAttchements = isNotEmptyArray(props.fileAttchements)
      ? [...props.fileAttchements]
      : [];
    const fileData = uploadAttachmentURL;

    let newAttachment: DocumentImageAttachmentRow;
    const existingAttachmentIndex = newFileAttchements.findIndex(
      (attachment) => attachment.Name === fileData.name
    );

    if (existingAttachmentIndex !== -1) {
      // File exists, check if it has been edited
      if (newFileAttchements[existingAttachmentIndex].File !== fileData) {
        // File has been edited, update it
        newAttachment = {
          ...newFileAttchements[existingAttachmentIndex],
          File: fileData,
        };
        newFileAttchements[existingAttachmentIndex] = newAttachment;
      }
    } else {
      // File does not exist, consider it as a new file
      newAttachment = {
        UID: Guid.newGuid(),
        Name: fileData.name,
        File: fileData,
      };
      newFileAttchements.push(newAttachment);
    }

    if (props.onChangeAttachment) {
      props.onChangeAttachment(newFileAttchements);
    }
  };

  //showUpload profile photo start
  // const showAttachmentEditorDlg = () => {
  //     setState({ showAttachmentEditorDlg: true });
  // };
  const onClickUploadPhoto = () => {
    imageUpload.current.click();
  };

  const fileChanged = (args) => {
    const fileData =
      isNotNullAndUndefined(args) &&
        isNotNullAndUndefined(args.target) &&
        isNotNullAndUndefined(args.target.files)
        ? args.target.files[0]
        : null;
    let selectedAttachmentPhoto = null;
    let selectedAttachmentFileName = null;
    if (isNotNullAndUndefined(fileData)) {
      const URL = window.URL;
      const url = URL.createObjectURL(fileData);
      selectedAttachmentFileName = fileData.name;
      selectedAttachmentPhoto = url;
    }
    imageUpload.current.value = null;
    updateAttachment(fileData);
    setState({
      selectedAttachmentPhoto,
      selectedAttachmentFileName,
      //showAttachmentEditorDlg: true
    });
  };

  const uploadAttachmentEditorDlgContent = () => {
    if (state.showAttachmentEditorDlg) {
      return (
        <RAFProfilePhotoImageEditor
          srcProfilePhoto={null}
          updateProfilePhoto={updateAttachment}
          removeProfileClicked={null}
          fileName={null}
          selectCropType={"custom"}
          uploadButtonText={"photo"}
          isActive
          selectedFileName={state.selectedAttachmentFileName}
          selectedProfilePhoto={state.selectedAttachmentPhoto}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeAttachmentEditorDlg = async () => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setState({ showAttachmentEditorDlg: false });
  };
  //showUpload profile photo end

  //view photo start
  const onclickViewAttachment = (
    selectedAttachment: DocumentImageAttachmentRow
  ) => {
    setState({ showViewAttachmentContent: true, selectedAttachment });
  };

  //edit photo start
  const onclickEditAttachment = (
    selectedAttachment: DocumentImageAttachmentRow
  ) => {
    let fileData = selectedAttachment.File;
    let selectedAttachmentPhoto = null;
    let selectedAttachmentFileName = null;
    if (isNotNullAndUndefined(fileData)) {
      const URL = window.URL;
      const url = URL.createObjectURL(fileData);
      selectedAttachmentFileName = fileData.name;
      selectedAttachmentPhoto = url;
    }
    //imageUpload.current.value = null;
    //updateAttachment(fileData);
    setState({
      selectedAttachmentPhoto,
      selectedAttachmentFileName,
      showAttachmentEditorDlg: true,
    });
  };

  const viewAttachmentDlgContent = () => {
    if (
      state.showViewAttachmentContent &&
      isNotNullAndUndefined(state.selectedAttachment)
    ) {
      const { selectedAttachment } = state;
      return (
        <div>
          <CustomCardWidget cardClassName="img_card" removeContentPadding>
            <div
              className="img_wrapper raf-h-xl"
              style={{
                backgroundImage: `url(${URL.createObjectURL(
                  selectedAttachment.File
                )})`,
              }}
            ></div>
          </CustomCardWidget>
          {/* <img
                        src={URL.createObjectURL(selectedAttachment.File)}
                        alt="" /> */}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const closeViewAttachmentDlg = async () => {
    await RafClearDialogContent(imageFileAttachmentRef);
    setState({ showViewAttachmentContent: false, selectedAttachment: null });
  };
  //view photo end

  const removeAttachmentClicked = (index: number) => {
    const newFileAttchements = isNotEmptyArray(props.fileAttchements)
      ? [...props.fileAttchements]
      : [];
    if (isNotEmptyArray(newFileAttchements) && isNotNullAndUndefined(index)) {
      newFileAttchements.splice(index, 1);
    }
    if (props.onChangeAttachment) {
      props.onChangeAttachment(newFileAttchements);
    }
  };

  const getImagesContent = () => {
    const { fileAttchements } = props;

    // if (isNotEmptyArray(fileAttchements)) {
    return (
      <div className="row g-2 gy-3">
        {isNotEmptyArray(fileAttchements) &&
          fileAttchements.map((item, index) => {
            const file = item.File;
            let fileSize = getFileSize(file.size);

            return (
              <div key={index} className="col-6 col-md-4">
                <div className="row gx-0 gy-2">
                  <div className="col-12">
                    <CustomCardWidget
                      cardClassName="img_card"
                      removeContentPadding
                    >
                      <div
                        className="img_wrapper"
                        style={{
                          backgroundImage: `url(${URL.createObjectURL(file)})`,
                        }}
                      >
                        <div className="img_card_btn_container">
                          <RAFButtonComponent
                            className="e-round"
                            onClick={() => {
                              onclickViewAttachment(item);
                            }}
                            action={RAFButtonConstant.Preview}
                            iconBtn
                          ></RAFButtonComponent>
                          <RAFButtonComponent
                            className="e-round"
                            onClick={() => {
                              onclickEditAttachment(item);
                            }}
                            action={RAFButtonConstant.Edit}
                            iconBtn
                          ></RAFButtonComponent>
                          <RAFButtonComponent
                            className="e-round"
                            onClick={() => {
                              removeAttachmentClicked(index);
                            }}
                            action={RAFButtonConstant.Delete}
                            iconBtn
                          ></RAFButtonComponent>
                        </div>
                      </div>
                    </CustomCardWidget>
                  </div>
                  <div className="col-12">
                    <div className="img_card_text">
                      <div className="header-text header-text-light text-truncate">
                        {file.name}
                      </div>
                      <div className="secondary-header-text">{fileSize}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="col-6 col-md-4">
          <CustomCardWidget
            cardClassName="img_card pointer"
            removeContentPadding
            onClick={() => onClickUploadPhoto()}
          >
            <div className="img_wrapper flex-column">
              <RAFIconImage
                iconCssClass="fa-icon fa-Camera"
                moduleavatar="border-avatar"
                iconSize="48"
                fontSize="20"
              ></RAFIconImage>
              <div className="header-text header-text-light mt-2">
                Select Photo
              </div>
              <div className="d-none">
                <input
                  type="file"
                  accept="image/*"
                  id="img-upload"
                  className="e-custom-file"
                  onChange={fileChanged}
                  ref={imageUpload}
                />
              </div>
            </div>
          </CustomCardWidget>
        </div>
      </div>
    );
    // } else {
    //     return null;
    // }
  };

  return (
    <div>
      {/* <ButtonComponent type="button"
                cssClass="e-small link-button p-0 mb-2"
                onClick={() => showAttachmentEditorDlg()}
            >Add Attachment(s)</ButtonComponent> */}
      <div className="header-text mb-2">Attachment(s)</div>
      {getImagesContent()}
      {state.showAttachmentEditorDlg && (
        <div className="e-img-editor-profile" id='id="profile-dialog"'>
          <DialogComponent
            header={"Add Attachment"}
            cssClass="centerDialog-lg fixed-header dlg-new-style image_editor_dialog"
            visible={state.showAttachmentEditorDlg}
            content={uploadAttachmentEditorDlgContent.bind(this)}
            isModal
            target="#root"
            id="profile-dialog"
            closeOnEscape
            close={closeAttachmentEditorDlg.bind(this)}
            overlayClick={closeAttachmentEditorDlg.bind(this)}
            showCloseIcon={true}
            ref={imageFileAttachmentRef}
          />
        </div>
      )}
      {state.showViewAttachmentContent && (
        <DialogComponent
          header={"View Attachment"}
          cssClass="centerDialog-md fixed-header"
          visible={state.showViewAttachmentContent}
          content={viewAttachmentDlgContent.bind(this)}
          isModal
          target="#root"
          closeOnEscape
          close={closeViewAttachmentDlg.bind(this)}
          overlayClick={closeViewAttachmentDlg.bind(this)}
          showCloseIcon={true}
          ref={imageFileAttachmentRef}
        />
      )}
    </div>
  );
}

export default React.memo(RAFImageFileAttachment);
