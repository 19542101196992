import React, { Fragment, PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { Frequency, RRule, Weekday } from "rrule";
import { ConvertSchedulerToCronExpression } from "../../RAFMaster/helpers/RMutils";
import { FrequencyValueType, RRDays, getRuleByWeekday, getRuleFrequency } from "../helpers/RRuleInputHelper";
import {
  Guid,
  isNotEmptyArray,
  isNotNullAndUndefined,
  stringInclues,
} from "../helpers/utils";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldProps,
  RAFFieldProps,
  RAFFormContext,
  getFormValue,
  hasEmoji,
  isRequired,
  setFormValue,
} from "./RFFUtils";

const frequencyItems: {
  DisplayName: FrequencyValueType;
  value: Frequency;
  ColorCode: string;
}[] = [
    {
      DisplayName: FrequencyValueType.Daily,
      value: RRule.DAILY,
      ColorCode: "#3598db",
    },
    {
      DisplayName: FrequencyValueType.Weekly,
      value: RRule.WEEKLY,
      ColorCode: "#3598db",
    },
  ];

interface IProps {
  setStartEndDate?: boolean;
  rRuleExpressionPropertyName?: string;
  showFrequency?: boolean;
}

function RAFFrequencyRRuleInput<T>({
  field,
  label,
  width,
  onChanged,

  setStartEndDate = true,
  rRuleExpressionPropertyName = "RruleExpression",
  showFrequency = true,

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<IProps & RAFFieldProps<T>>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const indexVal: string = Guid.newGuid();

  const onChangeByWeekday = (value) => {
    const rRuleExpression = getFormValue(
      rafFormContextValue,
      rRuleExpressionPropertyName
    );
    const frequencyType = getRuleFrequency(rRuleExpression);

    const ruleByWeekday = getRuleByWeekday(rRuleExpression);

    if (isNotNullAndUndefined(value)) {
      let byweekday = isNotEmptyArray(ruleByWeekday) ? ruleByWeekday : [];
      let isItemExists = isNotEmptyArray(byweekday)
        ? stringInclues(ruleByWeekday, value)
        : false;
      if (isItemExists === false) {
        byweekday.push(value);
      } else if (isItemExists === true) {
        byweekday = byweekday.filter((x) => x !== value);
      }
      updateRRuleValue(frequencyType, byweekday);
    } else {
      updateRRuleValue(frequencyType, []);
    }
  };

  const updateRRuleValue = (
    frequencyType: Frequency,
    byweekday: Weekday[],
    newStartDate?: Date
  ) => {
    let newRule: RRule;
    if (setStartEndDate === false) {
      const rule = new RRule({
        freq: isNotNullAndUndefined(frequencyType)
          ? frequencyType
          : RRule.DAILY,
        wkst: RRule.MO,
        byweekday: frequencyType === RRule.WEEKLY ? byweekday : null,
        interval: 1,
      });
      newRule = rule;
    } else {
      const startDate =
        newStartDate ?? getFormValue(rafFormContextValue, "StartDate");
      const endDate = getFormValue(rafFormContextValue, "EndDate");
      const rule = new RRule({
        dtstart: isNotNullAndUndefined(startDate) ? new Date(startDate) : null,
        until: isNotNullAndUndefined(endDate) ? new Date(endDate) : null,
        freq: isNotNullAndUndefined(frequencyType)
          ? frequencyType
          : RRule.DAILY,
        wkst: RRule.MO,
        byweekday: frequencyType === RRule.WEEKLY ? byweekday : null,
        interval: 1,
      });
      newRule = rule;
    }

    const cronExpression = ConvertSchedulerToCronExpression(newRule.toString());
    const ruleString = newRule.toString();
    setFormValue(rafFormContextValue, rRuleExpressionPropertyName, ruleString);
    setFormValue(rafFormContextValue, "CronExpression", cronExpression);
  };

  const onItemClicked = (value) => {
    updateRRuleValue(value, [], null);
  };

  const getFrequencyContent = (fieldValue) => {
    const frequencyType = getRuleFrequency(fieldValue);

    return (
      <div className="row g-2 align-items-center">
        <Fragment>
          <div className="col">
            <div className="row g-2">
              {frequencyItems.map((item, index1) => {
                return (
                  <div className="col" key={`${item.DisplayName}${index1}`}>
                    <div
                      id={`dropdown_item_${item.DisplayName
                        }_${field.toString()}_${indexVal}`}
                      className={
                        frequencyType === item.value
                          ? "custom-dropdown-btn active"
                          : "col custom-dropdown-btn"
                      }
                      style={{
                        background: item.ColorCode,
                        color:
                          isNotNullAndUndefined(item.ColorCode) &&
                            item.ColorCode !== "transparent" &&
                            item.ColorCode !== ""
                            ? "white"
                            : "black",
                      }}
                      onClick={() => {
                        if (frequencyType !== item.value) {
                          onItemClicked(item.value);
                        }
                      }}
                    >
                      <span className="w-100 px-2">{item.DisplayName}</span>
                      {frequencyType === item.value ? (
                        <span className="fas fa-check"></span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      </div>
    );
  };

  const getDaysOptionsContent = (fieldValue) => {
    const ruleByWeekday = getRuleByWeekday(fieldValue);
    return (
      <div className="row">
        <RAFFieldLabel
          field={rRuleExpressionPropertyName}
          label={"Select days"}
        />
        <div className="col-12">
          <div className="row g-2">
            {RRDays.map((item) => {
              return (
                <div className="col-auto" key={item.id}>
                  <button
                    type="button"
                    onClick={() => onChangeByWeekday(item.value)}
                    className={
                      isNotEmptyArray(ruleByWeekday) &&
                        stringInclues(ruleByWeekday, item.value)
                        ? "btn toggle-btn"
                        : "btn toggle-btn e-outline"
                    }
                  >
                    {item.text}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className="row align-items-center gx-1 gx-md-2 gx-xl-3"
        id={"rafdiv" + field.toString()}
      >
        {showLabel && showLabel === true && (
          <div
            className={
              props.labelClassName
                ? props.labelClassName + " d-flex"
                : "col-12 d-flex"
            }
          >
            <div className="form-label-container form-label-container-height">
              <label
                className={required ? "form-label required" : "form-label"}
              >
                Frequency
              </label>
            </div>
          </div>
        )}
        <div
          className={
            props.inputFieldClassName
              ? props.inputFieldClassName + " d-flex"
              : "col-12 d-flex"
          }
        >
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      hasEmoji,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      hasEmoji
                    )
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input }) => {
              const frequencyType = getRuleFrequency(input.value);
              return (
                <div className="w-100">
                  <div className="row gy-3">
                    <div className="col-md-12">
                      <div className={showFrequency === true ? "" : "hidden"}>
                        {getFrequencyContent(input.value)}
                      </div>
                      {props.hideRequiredMessage !== true ? (
                        <RAFFieldError name={field.toString()} />
                      ) : (
                        ""
                      )}
                    </div>
                    {frequencyType === RRule.WEEKLY && (
                      <div className="col-md-12">
                        {getDaysOptionsContent(input.value)}
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RAFFrequencyRRuleInput);
