import { PropsWithChildren } from 'react';
import { Field } from 'react-final-form';
import { isNotNullAndUndefined } from '../../RAFComponents/helpers/utils';
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators, RAFFieldError } from './RAFForm';
import { isRequired, RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFMediaFieldProps } from './RFFUtils';


function RAFVideoInput<T>(
    { field, label, fieldInnerText, children, labelClassName, titleLocation,
        description,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFMediaFieldProps<T>>,
) {
    labelClassName = isNotNullAndUndefined(labelClassName)
        ? labelClassName
        : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : 'col-12';
    if (titleLocation === 'Right') {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = 'col-auto order-last';
        inputFieldClassName = 'col';
    }
    else if (titleLocation === 'Left') {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = 'col-3';
        inputFieldClassName = 'col';
    }
    else if (titleLocation === 'Bottom') {
        labelClassName = 'order-last';
    }
    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName +
                    " form-group"
                    : "form-group"
            }
        >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                    <RAFFieldLabel field={field} label={label} required={required} labelClassName={labelClassName}
                        description={description}
                    ></RAFFieldLabel>
                )}
                <div
                    className={inputFieldClassName
                    }
                >
                    <Field name={field.toString()}
                        {...props.initialValue ? { initialValue: props.initialValue } : {}}
                        //validate={validate === true ? (required && isRequired) : null}
                        {...props.validators ?
                            { validate: validate === true ? composeValidators(required === true ? isRequired : null, ...props.validators) : null }
                            :
                            { validate: validate === true ? composeValidators(required === true ? isRequired : null) : null }
                        }
                        allowNull parse={value => (value === "" ? null : value)}>
                        {({ }) => (
                            <div className="w-100" >
                                <div className={isNotNullAndUndefined(fieldInnerText) ? "fieldInnerText w-100" : "w-100"} >
                                    <iframe
                                        id={`iframe_${field.toString()}`}
                                        width={props.mediaWidth ? props.mediaWidth : "420"} height={props.mediaHeight ? props.mediaHeight : "320"}
                                        src={props.url}
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="video"
                                        sandbox=""
                                    />
                                    {isNotNullAndUndefined(fieldInnerText) && <span className="fieldInnerLabel">{fieldInnerText}</span>}
                                </div>

                                {props.hideRequiredMessage !== true ?
                                    <RAFFieldError name={field.toString()} /> :
                                    ""}
                            </div>
                        )}
                    </Field>

                </div>
            </div>
        </div>
    );
}

export default RAFVideoInput;