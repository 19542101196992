import { PropsWithChildren, useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import RAFGoogleMaps from '../Navigation/RAFGoogleMaps';
import { isNotNullAndUndefined } from '../helpers/utils';
import RAFFieldLabel from './RAFFieldLabel';
import { RAFFieldError } from './RAFForm';
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFTextBoxProps } from './RFFUtils';

interface MapProps {
    latitudeField: string;
    longitudeField: string;
}

//const RAFLatitudeLongitude: React.FC<MapProps> = ({ latitudeField, longitudeField }) => {
function RAFLatitudeLongitude<T>({
    latitudeField,
    longitudeField,
    field,
    label,
    width,
    created,
    onChanged,
    onInputBlur,
    fieldInnerText,
    children,
    maxlength,
    description,
    descriptionAsLabel,
    titleLocation,
    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,
    ...props
}: PropsWithChildren<RAFTextBoxProps<T>> & MapProps) {

    let labelClassName = isNotNullAndUndefined(props.labelClassName)
        ? props.labelClassName
        : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : "col-12";
    if (titleLocation === "Right") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-auto order-last";
        inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-3";
        inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
        labelClassName = "order-last";
    }

    //get field values from form and send to rafgooglemaps. onlocationchange from googlemaps will update the form values
    const form = useForm(); // assuming you are using react-final-form
    const values = form.getState().values;

    const handleLocationChange = ({ lat, lng }) => {
        form.change(latitudeField, lat);
        form.change(longitudeField, lng);
    };

    useEffect(() => {
        form.registerField(latitudeField, () => { }, {});
        form.registerField(longitudeField, () => { }, {});
    }, []);

    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName + " form-group"
                    : "form-group"
            }
        >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                        descriptionAsLabel={descriptionAsLabel}
                    ></RAFFieldLabel>
                )}
                <Field name="location">
                    {({ input }) => (
                        <div className={inputFieldClassName}>

                            <RAFGoogleMaps
                                lat={values[latitudeField]}
                                lng={values[longitudeField]}
                                onLocationChange={handleLocationChange}
                            />

                            {props.hideRequiredMessage !== true ? (
                                <RAFFieldError name={field.toString()} />
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                </Field>
            </div>
        </div>
    );


};

export default RAFLatitudeLongitude;