import { PropsWithChildren } from 'react';
import { IconPicker } from 'react-fa-icon-picker';
import { Field } from 'react-final-form';
import { isNotNullAndUndefined } from '../helpers/utils';
import "./InputStyle.scss";
import RAFFieldLabel from './RAFFieldLabel';
import { composeValidators, RAFFieldError } from './RAFForm';
import { isRequired, RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFIconPickerFieldProps } from './RFFUtils';

//const RAFTextBox = ({ field, label, required }) => {

function RAFIconPicker<T>(
    { field, label, onChanged, containerPosition, children,
        iconPickerSize, removeRowClass,
        description,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFIconPickerFieldProps<T>>,
) {
    let fontSize = "24px";
    if (iconPickerSize === "sm") {
        fontSize = "16px";
    }

    let labelClassName = isNotNullAndUndefined(props.labelClassName) ? `${props.labelClassName}` : "";

    function customIconPickerClick(e) {
        setTimeout(() => {
            // let iconPickerDiv = document.getElementById(`customIconPicker${field.toString()}`);
            // let iconPickerDiv = document.getElementById(`customIconPicker${field.toString()}`);
            let iconPickerDiv = e.target.closest('.custom-icon-picker');
            let reactIconPickerDiv = iconPickerDiv.getBoundingClientRect();
            let xAxisValue = reactIconPickerDiv.x;
            let yAxisValue = reactIconPickerDiv.y + reactIconPickerDiv.height;
            let iconPickerContainer = document.querySelector(".sc-gswNZR");
            let iconPickerContainerHTML = (((iconPickerDiv as HTMLElement).firstElementChild).lastElementChild) as HTMLElement;

            if (isNotNullAndUndefined(iconPickerContainerHTML)) {
                iconPickerContainerHTML.style.display = `flex`;
                iconPickerContainer = ((iconPickerDiv as HTMLElement).firstElementChild).lastElementChild;
                if ((yAxisValue + iconPickerContainer.clientHeight) > window.innerHeight) {
                    iconPickerContainerHTML.style.top = `unset`;
                    iconPickerContainerHTML.style.bottom = `1rem`;

                }
                else {
                    iconPickerContainerHTML.style.top = `${yAxisValue}px`;
                }
                if ((xAxisValue + iconPickerContainer.clientWidth) > window.innerWidth) {
                    iconPickerContainerHTML.style.right = `1rem`;
                    iconPickerContainerHTML.style.left = `unset`;
                }
                else {
                    iconPickerContainerHTML.style.left = `${xAxisValue}px`;
                }
            }
        }, 200);

    }

    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName +
                    " form-group"
                    : "form-group"
            }
        >
            <div className={removeRowClass ? "" : RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()} >
                {showLabel && showLabel === true && (
                    <RAFFieldLabel field={field} label={label} required={required} labelClassName={labelClassName}
                        description={description}
                    ></RAFFieldLabel>
                )}
                <div className={`${removeRowClass ? "" : "col-12"}${removeRowClass && isNotNullAndUndefined(props.inputFieldClassName) ? ' ' : ''}${isNotNullAndUndefined(props.inputFieldClassName) ? props.inputFieldClassName : ''}`}>
                    <div className="d-flex align-items-center">
                        <Field name={field.toString()}
                            {...props.initialValue ? { initialValue: props.initialValue } : {}}
                            //validate={validate === true ? (required && isRequired) : null}
                            {...props.validators ?
                                { validate: validate === true ? composeValidators(required === true ? isRequired : null, ...props.validators) : null }
                                :
                                { validate: validate === true ? composeValidators(required === true ? isRequired : null) : null }
                            }
                            allowNull parse={value => (value === "" ? null : value)}>
                            {({ input }) => (
                                <div
                                    className=
                                    {iconPickerSize === "sm" ? "w-100 custom-icon-picker custom-icon-picker-sm" :
                                        "w-100 custom-icon-picker"}
                                    id={`customIconPicker${field.toString()}`}
                                    onClick={(e) => customIconPickerClick(e)}
                                >
                                    <IconPicker
                                        value={input.value}
                                        buttonIconStyles={{ fontSize: fontSize, display: "flex", alignItems: "center", justifyContent: "center" }}
                                        // containerStyles={{
                                        //     ...isNotNullAndUndefined(containerPosition) ?
                                        //         containerPosition === "right" ?
                                        //             { zIndex: 1047, position: 'fixed', right: 0 }
                                        //             :
                                        //             containerPosition === "left" ?
                                        //                 { zIndex: 1047, position: 'fixed', left: 0 }
                                        //                 :
                                        //                 containerPosition === "rightTop" ?
                                        //                     { zIndex: 1047, position: 'fixed', left: "52px", top: 0 }
                                        //                     :
                                        //                     { zIndex: 1047, position: 'fixed' }
                                        //         :
                                        //         { zIndex: 1047, position: 'fixed' }
                                        // }

                                        // }
                                        containerStyles={{ zIndex: 1047, position: 'fixed', display: 'none', justifyContent: 'space-between' }}
                                        onChange={(v) => {
                                            input.onChange(v);
                                            if (isNotNullAndUndefined(onChanged)) {
                                                onChanged(v);
                                            }

                                        }}

                                    />


                                    {props.hideRequiredMessage !== true ?
                                        <RAFFieldError name={field.toString()} /> :
                                        ""}
                                </div>
                            )}
                        </Field>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default RAFIconPicker;