import { ButtonComponent, ChipDirective, ChipListComponent, ChipsDirective } from '@syncfusion/ej2-react-buttons';
import { PropsWithChildren } from "react";
import { Field } from 'react-final-form';
import { showWarningToast } from '../Utility/RAFToastComponent';
import { Guid, IsNotNullOrWhiteSpace, isEmptyOrNull, isNotEmptyArray, isNotNullAndUndefined } from '../helpers/utils';
import RAFFieldLabel from './RAFFieldLabel';
import { RAFFieldError, composeValidators } from './RAFForm';
import { RAFDefaultFieldProps, RAFTagInputFieldProps, hasEmoji, isRequired } from './RFFUtils';

function RAFTagInput<T>(
    { field, onChanged, showAddBtn, moduleName, children, label,
        description,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFTagInputFieldProps<T>>,
) {
    const objGuid = Guid.newGuid();
    let labelClassName = (isNotNullAndUndefined(props.labelClassName) && isEmptyOrNull(props.labelClassName)) ? props.labelClassName : "col-auto";

    const addTagData = (event, input, inputValue) => {
        if (IsNotNullOrWhiteSpace(event.target.value)) {
            if (!hasEmoji(event.target.value)) {
                const tagData = inputValue;
                let tagUpdatedData = [];
                if (isNotNullAndUndefined(tagData)) {
                    tagUpdatedData = [...tagData, event.target.value];
                } else {
                    tagUpdatedData[0] = event.target.value;
                }
                input.onChange(tagUpdatedData);
                event.target.value = '';
                if (isNotNullAndUndefined(onChanged)) {
                    onChanged(tagUpdatedData);
                }
                hideTagsInputField();
            } else {
                showWarningToast(`${hasEmoji(event.target.value)}`);
            }
        }
        event.preventDefault();
    };

    const removeTagData = (indexToRemove, input, inputValue) => {
        if (disabled === false) {
            const tagData = inputValue;
            let tagUpdatedData = [];
            if (isNotNullAndUndefined(tagData)) {
                tagUpdatedData = tagData && [...tagData.filter((_, index) => index !== indexToRemove)];
            }
            if (!isNotEmptyArray(tagUpdatedData)) {
                tagUpdatedData = null;
            }
            input.onChange(tagUpdatedData);
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(tagUpdatedData);
            }
        }

    };

    const removeItemOnBackSpaceClick = (indexToRemove, input) => {
        return; // on pressing back space inside a non empty text box, previous value getting removed 
        const tagData = input.value;
        let tagUpdatedData = [];
        if (isNotNullAndUndefined(tagData) && tagData.length > 0) {
            tagUpdatedData = tagData.slice(0, -1);
            input.onChange(tagUpdatedData);
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(tagUpdatedData);
            }
        }
    };

    const addTagsInputField = () => {
        let buttonElement = document.getElementById(`tagButton_${objGuid}`);
        let inputElement = document.getElementById(`tagInputField_${objGuid}`);
        if (isNotNullAndUndefined(buttonElement) && isNotNullAndUndefined(inputElement)) {
            buttonElement.classList.add("hidden");
            inputElement.classList.remove("hidden");
        }
    };

    const hideTagsInputField = () => {
        let buttonElement = document.getElementById(`tagButton_${objGuid}`);
        let inputElement = document.getElementById(`tagInputField_${objGuid}`);
        if (isNotNullAndUndefined(buttonElement) && isNotNullAndUndefined(inputElement)) {
            buttonElement.classList.remove("hidden");
            inputElement.classList.add("hidden");
        }
    };

    return (
        <div className="raftags app-container p-0"
            //id={"rafdiv" + field.toString()}
            id={`rafdiv_${field.toString()}_${objGuid}`}
        >
            <Field name={field.toString()}
                {...props.initialValue ? { initialValue: props.initialValue } : {}}
                //validate={validate === true ? (required && isRequired) : null}
                {...props.validators ?
                    { validate: validate === true ? composeValidators(required === true ? isRequired : null, ...props.validators) : null }
                    :
                    { validate: validate === true ? composeValidators(required === true ? isRequired : null) : null }
                }
                allowNull parse={value => (value === "" ? null : value)}>
                {({ input }) => {
                    let inputValue = isNotNullAndUndefined(input) && isNotEmptyArray(input.value) ? input.value : [];
                    return (
                        <div>
                            {showAddBtn ?
                                <div className="tag-input">
                                    <div className={`row align-items-center ${isNotNullAndUndefined(props.rowClassName) ? props.rowClassName : "g-1"}`}>
                                        {showLabel ?
                                            <RAFFieldLabel field={field} label={label} required={required}
                                                description={description}
                                                labelClassName={labelClassName}></RAFFieldLabel>
                                            :
                                            ""}
                                        <div className='col-12'>
                                            <div className='row g-2'>
                                                {isNotNullAndUndefined(inputValue) && inputValue.length > 0 &&
                                                    <div className='col-auto'>
                                                        <ChipListComponent id="chip-avatar" enableDelete={disabled === true ? false : true} deleted={e => removeTagData(e.index, input, inputValue)}>
                                                            <ChipsDirective>
                                                                {inputValue.map((tag, index) => (
                                                                    <ChipDirective key={index} text={tag} ></ChipDirective>
                                                                ))}
                                                            </ChipsDirective>
                                                        </ChipListComponent>
                                                    </div>
                                                }
                                                {!disabled &&
                                                    <div id={`tagInputField_${objGuid}`} className="col-auto hidden d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            //onKeyUp={event => (event.key === 'Enter' ? addTagData(event, input) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null)}
                                                            onKeyDown={(event) => {
                                                                event.stopPropagation();
                                                                event.key === 'Enter' ? addTagData(event, input, inputValue) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null;
                                                            }}
                                                            placeholder={isNotNullAndUndefined(props.placeholder) ? props.placeholder : "Type and press enter to add a tag"}
                                                            id={`input_addTag_${objGuid}_${field.toString()}`}
                                                        />
                                                        <ButtonComponent type="button" iconCss='fas fa-xmark'
                                                            id={`btn_closeTag_${objGuid}_${field.toString()}`}
                                                            onClick={() => hideTagsInputField()} title="Click to remove" cssClass="primary-custom-button e-outline border-0 ms-2">
                                                        </ButtonComponent>
                                                    </div>
                                                }
                                                {!disabled &&
                                                    <div id={`tagButton_${objGuid}`}
                                                        className="col-auto"
                                                    >
                                                        <ButtonComponent type="button"
                                                            cssClass="e-small link-button p-0"
                                                            onClick={() => addTagsInputField()}
                                                            id={`btn_addTag_${objGuid}_${field.toString()}`}
                                                        >Add Tag</ButtonComponent>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                :
                                <div className="tag-input hideAddBtn">
                                    {isNotNullAndUndefined(inputValue) && inputValue.length > 0 &&
                                        <ChipListComponent id="chip-avatar" enableDelete click={e => removeTagData(e.index, input, inputValue)}>
                                            <ChipsDirective>
                                                {inputValue.map((tag, index) => (
                                                    <ChipDirective key={index} text={tag} ></ChipDirective>
                                                ))}
                                            </ChipsDirective>
                                        </ChipListComponent>
                                    }
                                    {!disabled &&
                                        <input
                                            type="text"
                                            //onKeyUp={event => (event.key === 'Enter' ? addTagData(event, input) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null)}
                                            onKeyDown={(event) => {
                                                event.stopPropagation();
                                                event.key === 'Enter' ? addTagData(event, input, inputValue) : event.key === 'Backspace' ? removeItemOnBackSpaceClick(event, input) : null;
                                            }}
                                            placeholder={isNotNullAndUndefined(props.placeholder) ? props.placeholder : "Type and press enter to add a tag"}
                                        />
                                    }
                                </div>
                            }
                            {props.hideRequiredMessage !== true ? (
                                <RAFFieldError name={field.toString()} />
                            ) : (
                                ""
                            )}
                        </div>
                    );
                }}
            </Field>
        </div >
    );
}

export default RAFTagInput;