import { debounce } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  DialogComponent,
  Tooltip,
  TooltipComponent,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import * as React from "react";
import { Field, FieldInputProps, FormRenderProps } from "react-final-form";
import { Constants } from "../../constants/Common/Constants";
import {
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
} from "../../RAFComponents/helpers/utils";
import { ILookupRow } from "../../RAFComponents/models/CompositeTypes/ILookupRow";
import { createInstance } from "../../RAFComponents/Utility/FormUtility";
import { getEntityByName } from "../../RAFMaster/helpers/RMutils";
import { GetFieldsDisplayName } from "../helpers/AppHelper";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import "./InputStyle.scss";
import { composeValidators, RAFFieldError } from "./RAFForm";
import RAFLookupGrid from "./RAFLookupGrid";
import { RAFDataManager, RAFUrlAdaptor } from "./RAFUrlAdaptor";
import {
  isRequired,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  RAFLookupFieldProps,
} from "./RFFUtils";
import RAFFieldLabel from "./RAFFieldLabel";

interface IState {
  showCreateContent: boolean;
  showLookupGridContent: boolean;
  createDialogHeader?: string;
}

class RAFMultiSelectLookupCC<T> extends React.Component<
  RAFLookupFieldProps<T>,
  IState
> {
  static defaultProps = {
    ...RAFDefaultFieldProps,
    showFullList: true,
    closeToolTip: true,
    SearchCreateOptionMode: "Default",
  };

  _isMounted = false;

  private textField = this.props.textField;
  private field = this.props.field.toString();
  private fields = { text: "Value", value: "UID" };

  private dropdownList: MultiSelectComponent | null;
  private rafFormContextValue: FormRenderProps;
  private objLookupRow = isNotNullAndUndefined(this.props.type)
    ? (createInstance(this.props.type) as ILookupRow)
    : null;
  private searchedText;
  private tooltip: Tooltip;
  private skip = 0;
  private take: number = Constants.DropdownFetchCount;
  private filteredSkip = 0;
  private filteredTake: number = Constants.DropdownFetchCount;
  private allItems = [];
  private selectedItems = [];
  //private SearchCreateOptionMode: "Footer" | "Default" = isNotNullAndUndefined(this.props.SearchCreateOptionMode) ? this.props.SearchCreateOptionMode : "Default";

  //private showlookUpGrid = isNotNullAndUndefined(this.props.showFullList) ? this.props.showFullList : true;
  private showFullList =
    this.props.showFullList && isNotNullAndUndefined(this.props.moduleName);
  private showCreateBtn =
    (this.props.showCreateButton === true ||
      (isNotNullAndUndefined(this.objLookupRow) &&
        this.objLookupRow.isOptionCreatable() === true)) &&
    isNotNullAndUndefined(this.props.createform);

  constructor(props) {
    super(props);
    if (isNotNullAndUndefined(this.textField)) {
      this.fields.text = this.textField;
    }
    this.state = {
      showCreateContent: false,
      showLookupGridContent: false,
      createDialogHeader: "",
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  customerData: RAFDataManager = new RAFDataManager({
    adaptor: new RAFUrlAdaptor({
      Skip: this.skip,
      Take: this.take,
      CustomFilter: this.props.customFilter,
      entityName: this.props.moduleName,
    }),
    crossDomain: true,
    url: isNotNullAndUndefined(this.props.url)
      ? Constants.baseAPIUrl + this.props.url
      : Constants.baseAPIUrl + this.objLookupRow.getLookupUrl(),
    requestType: "POST",
    //enableCaching: true,
    //offline: true,
    headers: [],
  });

  noRecordsTemplate1 = (): JSX.Element => {
    return (this.props.showCreateButton === true ||
      (isNotNullAndUndefined(this.objLookupRow) &&
        this.objLookupRow.isOptionCreatable() === true)) &&
      isNotNullAndUndefined(this.props.createform) &&
      this.props.SearchCreateOptionMode === "Default" &&
      isNotNullAndUndefined(this.searchedText) ? (
      <div>
        <button
          type="button"
          className="link-button"
          onClick={() => this.openCreateDialog()}
        >
          {" "}
          {'Create "' + this.searchedText + '"'}{" "}
        </button>
      </div>
    ) : (
      <span className="foot">No record found</span>
    );
  };

  noRecordsTemplate = () => {
    let noRecordsTemplate = "<span class='foot'>No record found</span>";
    return noRecordsTemplate;
  };

  footerTemplate = () => {
    return (
      <div className="row g-0 justify-content-between border-top flex-nowrap">
        {this.props.SearchCreateOptionMode === "Footer" &&
          this.showCreateBtn === true && (
            <div className="col-auto d-flex">
              <ButtonComponent
                type="button"
                iconCss="fas fa-plus"
                className="link-button custom-link-btn custom-button-sm"
                onClick={() => this.openCreateDialog()}
                id={`btn_create_msLookupData${this.field.toString()}`}
              >
                Create
              </ButtonComponent>
            </div>
          )}
        {this.props.SearchCreateOptionMode === "Footer" &&
          this.showFullList === true && (
            <div className="col d-flex">
              <ButtonComponent
                type="button"
                className="link-button custom-link-btn ms-auto custom-button-sm"
                onClick={() => this.showLookUpGridDialogOpen()}
                id={`btn_show_lookupGrid${this.field.toString()}`}
              >
                <span className="ecllipseFirstLine work-break-all text-wrap">
                  Show full list
                </span>
              </ButtonComponent>
            </div>
          )}
      </div>
    );
  };

  // create dialog starts

  openCreateDialog = () => {
    // let openTooltips = document.querySelectorAll('div.e-ddl.e-popup-open');
    //if (isNotNullAndUndefined(openTooltips)) {
    //    openTooltips.forEach((x) => {
    //        x.classList.add('hidden');
    //    });
    //}
    //if (isNotNullAndUndefined(this.dropdownList)) {
    //    this.dropdownList.hidePopup();
    //}
    getEntityByName(this.props.moduleName).then((entity) => {
      if (this._isMounted) {
        this.setState({
          showCreateContent: true,
          createDialogHeader: entity.DisplayName,
        });
      }
    });
  };

  showCreateContent = () => {
    if (this.state.showCreateContent) {
      const Createform1 = this.props.createform;
      let initialValues = {};
      if (isNotNullAndUndefined(this.props.createformdefaultfield)) {
        initialValues[this.props.createformdefaultfield] = this.searchedText;
      }
      /*if (isNotNullAndUndefined(props.createformaccountfield)) {
                    initialValues[props.createformaccountfield] = props.account;
            }
                if (isNotNullAndUndefined(props.createformaccountUIDfield)) {
                    initialValues[props.createformaccountUIDfield] = props.accountUID;
            }*/
      const createFormInitialValues = this.props.createFormInitialValues;
      if (isNotNullAndUndefined(createFormInitialValues)) {
        createFormInitialValues.forEach((item) => {
          initialValues[item.key] = item.value;
        });
      }
      return (
        <div>
          <Createform1
            as2={this.props.createform}
            isActive
            onSave={this.refreshLookupControl.bind(this)}
            onClose={() => this.closeCreateDialog()}
            {...(initialValues ? { initialValues: initialValues } : {})}
          ></Createform1>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  closeCreateDialog = () => {
    if (this._isMounted) {
      this.setState({ showCreateContent: false, createDialogHeader: "" });
    }
  };

  // create dialog ends

  // lookUp dialog starts
  showLookUpGridDialogOpen = () => {
    // let openTooltips = document.querySelectorAll('div.e-ddl.e-popup-open');
    //if (isNotNullAndUndefined(openTooltips)) {
    //    openTooltips.forEach((x) => {
    //        x.classList.add('hidden');
    //    });
    //}
    //if (isNotNullAndUndefined(this.dropdownList)) {
    //    this.dropdownList.hidePopup();
    //}
    if (this._isMounted) {
      this.setState({ showLookupGridContent: true });
    }
  };

  showLookupGridContent = () => {
    let { showLookupGridContent } = this.state;
    if (
      showLookupGridContent === true &&
      isNotNullAndUndefined(this.props.moduleName)
    ) {
      return (
        <RAFLookupGrid
          rowclicked={(value) => this.setLookUpValue(value)}
          onClose={() => this.showLookupGridDialogClose()}
          customFilter={this.props.customFilter}
          moduleName={this.props.moduleName}
          mode="input"
          isActive
          {...this.props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  setLookUpValue(value) {
    if (value) {
      let objectName = value[this.props.selectedField];
      let uid = value.UID;
      if (isNotNullAndUndefined(uid) && isNotNullAndUndefined(objectName)) {
        this.refreshLookupControl(uid, objectName);
      }
    }
    this.showLookupGridDialogClose();
  }

  refreshLookupControl = (entityId, objectName) => {
    this.closeCreateDialog();
    //console.log('entityId', entityId, 'objectName', objectName, 'rafFormContextValue', rafFormContextValue, 'dropdownList', dropdownList, isNotNullAndUndefined(dropdownList));

    if (isNotNullAndUndefined(this.dropdownList)) {
      const ddd = this.dropdownList;
      setTimeout(() => {
        this.searchedText = "";
        let filterQuery = new Query();
        this.filteredSkip = 0;
        this.customerData.adaptor = new RAFUrlAdaptor({
          Skip: this.filteredSkip,
          Take: this.filteredTake,
          CustomFilter: this.props.customFilter,
          entityName: this.props.moduleName,
        });
        ddd.dataSource = this.customerData;
        ddd.query = filterQuery;
        //ddd.value = objectName;
        //ddd.text = objectName;
        ddd.refresh();
      }, 100);

      const selectedItems =
        isNotNullAndUndefined(this.rafFormContextValue) &&
          isNotNullAndUndefined(this.rafFormContextValue.values)
          ? this.rafFormContextValue.values[this.field]
          : [];
      if (
        isNotNullAndUndefined(selectedItems) &&
        !IsNullOrWhiteSpace(selectedItems)
      ) {
        let isItemExist =
          selectedItems && selectedItems.findIndex((x) => x.UID === entityId);
        if (isItemExist === -1) {
          const updatedItems = [
            ...selectedItems,
            { UID: entityId, Value: objectName },
          ];
          if (
            isNotNullAndUndefined(this.rafFormContextValue) &&
            isNotNullAndUndefined(this.rafFormContextValue.form) &&
            isNotNullAndUndefined(this.rafFormContextValue.form.mutators)
          ) {
            this.rafFormContextValue.form.mutators.setValue(
              this.field,
              updatedItems
            );
          }
          if (isNotNullAndUndefined(this.props.onChanged)) {
            this.props.onChanged(JSON.stringify(updatedItems));
          }
        }
      } else {
        const updatedItems = [{ UID: entityId, Value: objectName }];
        //setSelectedItems(updatedItems);
        if (
          isNotNullAndUndefined(this.rafFormContextValue) &&
          isNotNullAndUndefined(this.rafFormContextValue.form) &&
          isNotNullAndUndefined(this.rafFormContextValue.form.mutators)
        ) {
          this.rafFormContextValue.form.mutators.setValue(
            this.field,
            updatedItems
          );
        }
        if (isNotNullAndUndefined(this.props.onChanged)) {
          this.props.onChanged(JSON.stringify(updatedItems));
        }
      }
    }
  };

  showLookupGridDialogClose = () => {
    if (this._isMounted) {
      this.setState({ showLookupGridContent: false });
    }
  };
  // lookUp dialog end

  onFiltering = (args) => {
    args.preventDefaultAction = true;
    this.setFilteringDebounce(args);
  };

  setFilteringDebounce = debounce((args) => {
    this.filteredSkip = 0;
    this.filteredTake = Constants.DropdownFetchCount;
    this.customerData.adaptor = new RAFUrlAdaptor({
      Skip: this.filteredSkip,
      Take: this.filteredTake,
      CustomFilter: this.props.customFilter,
      entityName: this.props.moduleName,
    });
    this.searchedText = args.text;
    let query = new Query();
    query =
      args.text !== ""
        ? query.where(this.field, "contains", args.text, true)
        : query;
    args.updateData(this.customerData, query);
    this.searchedText = args.text;
  }, 300);

  actionBegin = (args) => {
    if (args.query === undefined) {
      //temp fix to prevent repeated api calls
      args.cancel = true;
    }
  };

  opened = () => {
    //args.preventDefaultAction = true;
    //let start = 7;
    //let end = 12;
    if (isNotNullAndUndefined(this.dropdownList)) {
      let listElement: HTMLElement = (this.dropdownList as any).list;
      listElement.addEventListener("scroll", (e) => {
        if (
          listElement.scrollTop + listElement.offsetHeight + 1 >=
          listElement.scrollHeight
        ) {
          this.setScrollDebounce(e);
        }
      });
    }
  };

  setScrollDebounce = debounce(() => {
    if (isNotNullAndUndefined(this.dropdownList)) {
      let filterQuery = isNotNullAndUndefined(this.dropdownList.query)
        ? this.dropdownList.query.clone()
        : new Query();
      if (
        isNotNullAndUndefined(this.searchedText) &&
        this.searchedText !== ""
      ) {
        this.filteredSkip += this.filteredTake;
        filterQuery = filterQuery.where(
          this.field,
          "contains",
          this.searchedText,
          true
        );
        this.customerData.adaptor = new RAFUrlAdaptor({
          Skip: this.filteredSkip,
          Take: this.filteredTake,
          CustomFilter: this.props.customFilter,
          entityName: this.props.moduleName,
        });
      } else {
        this.skip += this.take;
        this.customerData.adaptor = new RAFUrlAdaptor({
          Skip: this.skip,
          Take: this.take,
          CustomFilter: this.props.customFilter,
          entityName: this.props.moduleName,
        });
      }
      this.customerData
        //.executeQuery(filterQuery.range(start, end))
        .executeQuery(filterQuery)
        .then((event) => {
          //start = end;
          //end += 5;
          this.dropdownList.addItem(
            (event as any).result as { [key: string]: Object }[]
          );
          this.allItems.push((event as any).result);
        })
        .catch();
    }
  }, 500);

  created = (args, inputValue) => {
    //console.log((isNotNullAndUndefined(dropdownList) && isNotNullAndUndefined(dropdownList.element)) ? `#${dropdownList.element.id}_popup .e-list-item` : '.e-list-item');
    if (this.dropdownList) {
      this.tooltip = new Tooltip({
        beforeRender: this.onBeforeRender,
        content: "Loading...",
        position: "TopCenter",
        //target: (isNotNullAndUndefined(dropdownList) && isNotNullAndUndefined(dropdownList.element)) ? `#${dropdownList.element.id}_popup .e-list-item` : '.e-list-item'
        target:
          isNotNullAndUndefined(this.dropdownList) &&
            isNotNullAndUndefined(this.dropdownList.element)
            ? `#_popup .e-list-item`
            : ".e-list-item",
      });
      this.tooltip.appendTo("body");
      const that = this.dropdownList;
      setTimeout(() => {
        //console.log('that created ', field.toString(), 'inputValue', inputValue, that, (isNotNullAndUndefined(inputValue) && IsNullOrWhiteSpace(that.text)));
        if (
          isNotNullAndUndefined(inputValue) &&
          IsNullOrWhiteSpace(that.text)
        ) {
          //that.text = inputValue;
        }
      }, 100);
    }
  };

  onBeforeRender = (args: TooltipEventArgs) => {
    if (isNotNullAndUndefined(this.tooltip))
      this.tooltip.content = args.target.textContent;
  };

  actionComplete = (e, inputValue) => {
    if (isNotNullAndUndefined(inputValue)) {
      inputValue &&
        inputValue.forEach((item) => {
          if (isNotNullAndUndefined(e.result)) {
            const objIndexForUID = e.result.findIndex(
              (x) => x["UID"] === item.UID
            );
            if (objIndexForUID < 0) {
              e.result.push({
                [this.fields.value]: item.UID,
                [this.fields.text]: item.Value,
              });
            }
          }
        });
    }
    e.result = R.uniq(e.result);
    this.allItems = e.result;
  };

  onClose = () => {
    let openTooltips = document.querySelectorAll(
      "div.e-tooltip-wrap.e-popup-open"
    );
    if (isNotNullAndUndefined(openTooltips)) {
      openTooltips.forEach((x) => {
        x.classList.add("hidden");
      });
    }
    this.tooltip && this.tooltip.close();
  };

  onChange = (e, input: FieldInputProps<any, HTMLElement>) => {
    const myArrayFiltered = this.allItems.filter((el) => {
      return e.value.some((f) => {
        return f === el["UID"];
      });
    });
    this.selectedItems = myArrayFiltered.map((e) => {
      return {
        UID: e["UID"],
        Value:
          e[isNotNullAndUndefined(this.textField) ? this.textField : "Value"],
      };
    });
    input.onChange(this.selectedItems);
    if (isNotNullAndUndefined(this.props.onChanged)) {
      this.props.onChanged(JSON.stringify(this.selectedItems));
    }
  };

  addItem = (uid, value, input: FieldInputProps<any, HTMLElement>) => {
    return;
    const selectedItems =
      isNotNullAndUndefined(this.rafFormContextValue) &&
        isNotNullAndUndefined(this.rafFormContextValue.values)
        ? this.rafFormContextValue.values[input.name]
        : [];
    if (
      isNotNullAndUndefined(selectedItems) &&
      !IsNullOrWhiteSpace(selectedItems)
    ) {
      const updatedItems = [...selectedItems, { UID: uid, Value: value }];
      input.onChange(updatedItems);
      if (isNotNullAndUndefined(this.props.onChanged)) {
        this.props.onChanged(JSON.stringify(updatedItems));
      }
    } else {
      const updatedItems = [{ UID: uid, Value: value }];
      //setSelectedItems(updatedItems);
      input.onChange(updatedItems);
      if (isNotNullAndUndefined(this.props.onChanged)) {
        this.props.onChanged(JSON.stringify(updatedItems));
      }
    }
  };

  deleteItemById = (uid, input: FieldInputProps<any, HTMLElement>) => {
    //return;
    const selectedItems =
      isNotNullAndUndefined(this.rafFormContextValue) &&
        isNotNullAndUndefined(this.rafFormContextValue.values)
        ? this.rafFormContextValue.values[input.name]
        : [];
    const items = selectedItems;
    const updatedItems = items.filter((obj) => obj.UID !== uid);
    input.onChange(updatedItems);
    if (isNotNullAndUndefined(this.props.onChanged)) {
      this.props.onChanged(JSON.stringify(updatedItems));
    }
  };

  render() {
    let {
      showLabel,
      required,
      label,
      field,
      initialValue,
      validate,
      validators,
      width,
      disabled,
      placeholder,
      showClearButton,
      SearchCreateOptionMode,
      description,
      descriptionAsLabel,
      titleLocation,
    } = this.props;
    let { showLookupGridContent, showCreateContent } = this.state;

    let labelClassName = isNotNullAndUndefined(this.props.labelClassName)
      ? this.props.labelClassName
      : "";
    let rowClassName = isNotNullAndUndefined(this.props.rowClassName)
      ? `${this.props.rowClassName} row`
      : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(this.props.inputFieldClassName)
      ? this.props.inputFieldClassName
      : 'col-12'
    if (titleLocation === 'Right') {
      rowClassName = `${rowClassName} flex-nowrap gx-2`
      labelClassName = 'col-auto order-last'
      inputFieldClassName = 'col'
    }
    else if (titleLocation === 'Left') {
      rowClassName = `${rowClassName} flex-nowrap gx-2`
      labelClassName = 'col-3'
      inputFieldClassName = 'col'
    }
    else if (titleLocation === 'Bottom') {
      labelClassName = 'order-last'
    }

    return (
      <RAFFormContext.Consumer>
        {(rafFormContextValue) => {
          this.rafFormContextValue = rafFormContextValue;
          return (
            <div
              className={
                isNotNullAndUndefined(this.props.formGroupClassName)
                  ? this.props.formGroupClassName + " form-group"
                  : "form-group"
              }
            >
              <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                  <RAFFieldLabel
                    field={field}
                    label={label}
                    required={required}
                    labelClassName={labelClassName}
                    description={description}
                    descriptionAsLabel={descriptionAsLabel}
                  ></RAFFieldLabel>
                )}
                <div className={inputFieldClassName}>
                  <Field
                    name={field.toString()}
                    {...(initialValue ? { initialValue: initialValue } : {})}
                    //validate={validate === true ? (required && isRequired) : null}
                    {...(validators
                      ? {
                        validate:
                          validate === true
                            ? composeValidators(
                              required === true ? isRequired : null,
                              ...validators
                            )
                            : null,
                      }
                      : {
                        validate:
                          validate === true
                            ? composeValidators(
                              required === true ? isRequired : null
                            )
                            : null,
                      })}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                  >
                    {({ input, meta }) => {
                      return (
                        <div>
                          <div>
                            <MultiSelectComponent
                              id={`msc_${field.toString()}`}
                              ref={(dropdowList) =>
                                (this.dropdownList = dropdowList)
                              }
                              name={input.name}
                              value={
                                input.value
                                  ? input.value.map(
                                    (x) =>
                                      isNotNullAndUndefined(x.UID) && x.UID
                                  )
                                  : input.value
                              }
                              showDropDownIcon
                              change={(e) => {
                                this.onChange(e, input);
                              }}
                              select={(e) => {
                                if (e.itemData !== null) {
                                  this.addItem(
                                    e.itemData["UID"],
                                    e.itemData["Value"],
                                    input
                                  );
                                }
                              }}
                              removed={(e) => {
                                this.deleteItemById(e.itemData["UID"], input);
                              }}
                              closePopupOnSelect={false}
                              dataSource={this.customerData}
                              fields={this.fields}
                              //noRecordsTemplate={this.noRecordsTemplate.bind(this)}
                              noRecordsTemplate={this.noRecordsTemplate()}
                              footerTemplate={this.footerTemplate}
                              filterType="Contains"
                              filtering={this.onFiltering.bind(this)}
                              actionBegin={this.actionBegin.bind(this)}
                              actionComplete={(e) =>
                                this.actionComplete(e, input.value)
                              }
                              allowFiltering
                              created={(e) => this.created(e, input.value)}
                              close={this.onClose.bind(this)}
                              open={(e) => this.opened()}
                              width={width}
                              readonly={disabled}
                              showClearButton={showClearButton}
                              placeholder={placeholder}
                              //mode="CheckBox"
                              cssClass={
                                meta.error && meta.touched
                                  ? "inputFieldError"
                                  : null
                              }
                            >
                              <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>

                            {this.props.hideRequiredMessage !== true ? (
                              <RAFFieldError name={field.toString()} />
                            ) : (
                              ""
                            )}
                          </div>
                          {SearchCreateOptionMode === "Default" &&
                            this.showFullList === true && (
                              <div className="col-auto">
                                <TooltipComponent
                                  content="Show more"
                                  position="BottomCenter"
                                >
                                  <ButtonComponent
                                    type="button"
                                    cssClass="input-icon-btn"
                                    iconCss="fas fa-magnifying-glass pt-1"
                                    onClick={() =>
                                      this.showLookUpGridDialogOpen()
                                    }
                                  ></ButtonComponent>
                                </TooltipComponent>
                              </div>
                            )}
                        </div>
                      );
                    }}
                  </Field>
                  {showLookupGridContent === true && (
                    <DialogComponent
                      visible
                      cssClass="centerDialog-lg dlg-new-style fixed-height dlg-new-style" //ref={dialog => this.showLookupGridDialog = dialog}
                      content={this.showLookupGridContent.bind(this)}
                      isModal
                      target="body"
                      closeOnEscape={false}
                      close={() => this.showLookupGridDialogClose.bind(this)}
                    ></DialogComponent>
                  )}
                  {showCreateContent === true && (
                    <DialogComponent
                      header={"Create " + this.state.createDialogHeader}
                      visible
                      cssClass="rightDialog createEditForm full-height form-center-dialog" //ref={dialog => this.showLookupGridDialog = dialog}
                      content={this.showCreateContent.bind(this)}
                      showCloseIcon
                      isModal
                      target="body"
                      closeOnEscape={false}
                      close={this.closeCreateDialog.bind(this)}
                    ></DialogComponent>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </RAFFormContext.Consumer>
    );
  }
}

export default RAFMultiSelectLookupCC;
