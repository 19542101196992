import { isNotNullAndUndefined } from '../helpers/utils';
import "./InputStyle.scss";
import RAFFieldLabel from './RAFFieldLabel';
import { RAFDefaultFieldClassName, RAFDefaultFieldProps } from './RFFUtils';

//const RAFTextBox = ({ field, label, required }) => {

interface IProps {
    label?: string;
    field?: string;
    required?: boolean;
    showLabel?: boolean;
    width?: string;
    children: any;
    formGroupClassName?: string;
    labelClassName?: string;
    description?: string;
    inputFieldClassName?: string;
}

function RAFInputGroup(
    {
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        ...props
    }: IProps
) {
    let labelClassName = isNotNullAndUndefined(props.labelClassName) ? " " + props.labelClassName : "";
    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName +
                    " form-group"
                    : "form-group"
            }>
            <div className={RAFDefaultFieldClassName.rowClassName}>
                {showLabel && showLabel === true && (
                    <div
                        className={`col-12 ${labelClassName}`}
                    >
                        <RAFFieldLabel field={props.field} label={props.label} required={required}
                            description={props.description}
                        ></RAFFieldLabel>
                    </div>
                )}
                {/* {props.showLabel && props.showLabel === true &&
                    <div className={isNotNullAndUndefined(props.labelClassName) ? props.labelClassName : "col-5 col-md-4 col-lg-4"}>
                        <RAFAttributesContext.Consumer>
                            {({ queryAttributes, attributeRelatedList }) => {
                                return (
                                    <label
                                        className={props.required ? "form-label required" : "form-label"}
                                    >
                                        {
                                            GetFieldsDisplayName(
                                                queryAttributes,
                                                props.field.toString(),
                                                props.label, attributeRelatedList
                                            )}{" "}
                                    </label>
                                );
                            }}
                        </RAFAttributesContext.Consumer>
                    </div>
                } */}
                <div className={(showLabel === true ? isNotNullAndUndefined(props.inputFieldClassName) ? props.inputFieldClassName : "col-7 col-md-8 col-lg-8" : 'col-12')}>
                    <div className={props.formGroupClassName ? props.formGroupClassName + " form-group" : "form-group"} style={{ width: props.width }}>
                        <div className="e-input-group e-float-icon-left m-0 row">
                            {props.children.map((item, index) => {
                                return <div key={index} className={index === 1 ? 'col p-0' : 'col-auto w-auto p-0'}>
                                    {item}
                                </div>;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RAFInputGroup;