import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import MoodBadIcon from '@mui/icons-material/MoodBad';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

import Rating, { IconContainerProps } from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import React, { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "../helpers/utils";
import { ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFUIType } from '../models/Common/RAFDataType';
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFieldProps, isRequired } from "./RFFUtils";

interface IProps {
    valueJson: ValueJson[];
    isDefaultEmoji?: boolean;
    uiType?: RAFUIType.SentimentToggle | RAFUIType.SentimentToggleWithLabel;
}

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const defaultEmojiIcons:
    {
        icon: React.ReactElement;
        label: string;
    }[]
    = [
        {
            icon: <MoodBadIcon color="error" fontSize="large" />,
            label: 'Angry',
        },
        {
            icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />,
            label: 'Very Dissatisfied',
        },
        {
            icon: <SentimentDissatisfiedIcon color="error" fontSize="large" />,
            label: 'Dissatisfied',
        },
        {//SentimentSatisfiedIcon
            icon: <SentimentNeutralIcon color="warning" fontSize="large" />,
            label: 'Neutral',
        },
        {
            icon: <SentimentSatisfiedAltIcon color="success" fontSize="large" />,
            label: 'Satisfied',
        },
        {
            icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
            label: 'Very Satisfied',
        },
    ];

function IconContainer(
    args: IconContainerProps,
    customIcons: {
        icon: React.ReactElement;
        label: string;
    }[]
) {
    const { value, ...other } = args;
    if (isNotNullAndUndefined(value) && isNotEmptyArray(customIcons)) {
        const icon = customIcons[value - 1];
        if (isNotNullAndUndefined(icon)) {
            return <span {...other}>{icon.icon}</span>;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

function RAFSentimentToggle<T>({
    field,
    label,
    width,
    created,
    onChanged,
    fieldInnerText,
    children,
    maxlength,
    description,
    descriptionAsLabel,
    titleLocation,
    uiType,
    valueJson,

    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,

    ...props
}: PropsWithChildren<IProps & RAFFieldProps<T>>) {

    const [hoverActiveValue, setHoverActiveValue] = React.useState(null);

    let customIcons: {
        icon: React.ReactElement;
        label: string;
    }[] = [];

    if (isNotEmptyArray(valueJson)) {
        valueJson.forEach((item) => {
            const selectedEmojiIcon = defaultEmojiIcons.find((x) => x.label === item.Icon);
            if (isNotNullAndUndefined(selectedEmojiIcon)) {
                customIcons.push({
                    icon: selectedEmojiIcon.icon,
                    label: item.DisplayName,
                });
            }
        });
    } else {
        customIcons = defaultEmojiIcons;
    }

    let labelClassName = isNotNullAndUndefined(props.labelClassName)
        ? props.labelClassName
        : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : "col-12";
    if (titleLocation === "Right") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-auto order-last";
        inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-3";
        inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
        labelClassName = "order-last";
    }

    const getLabelText = (value: number) => {
        if (isNotNullAndUndefined(value)) {
            const selectedIcon = customIcons[value - 1];
            if (isNotNullAndUndefined(selectedIcon)) {
                return selectedIcon.label;
            }
        } else {
            return null;
        }
    };

    const getRatingValueIndex = (text) => {
        let index = null;
        if (isNotNullAndUndefined(text) && isNotEmptyArray(customIcons)) {
            const itemIndex = customIcons.findIndex((item) => item.label === text);
            if (itemIndex !== -1) {
                index = itemIndex + 1;
            } else {
                index = null;
            }
        }
        return index;
    };

    const onChangeInput = (itemIndex, input) => {
        let value = null;
        if (isNotEmptyArray(customIcons) && isNotNullAndUndefined(itemIndex) && itemIndex > 0) {
            const selectedItem = customIcons[itemIndex - 1];
            value = isNotNullAndUndefined(selectedItem) ? selectedItem.label : null;
        }
        if (isNotNullAndUndefined(value)) {
            input.onChange(value);
            if (onChanged) {
                onChanged(value);
            }
        }
    };

    const onChangeActive = (event, itemIndex) => {
        if (uiType === RAFUIType.SentimentToggleWithLabel && itemIndex > 0 && isNotNullAndUndefined(itemIndex) && isNotEmptyArray(customIcons)) {
            const hoverItem = customIcons[itemIndex - 1];
            const displayLabel = isNotNullAndUndefined(hoverItem) ? hoverItem.label : null;
            setHoverActiveValue(displayLabel);
        } else {
            setHoverActiveValue(null);
        }
    };

    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName + " form-group"
                    : "form-group"
            }
        >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                        descriptionAsLabel={descriptionAsLabel}
                    />
                )}
                <Field
                    name={field.toString()}
                    {...(props.initialValue ? { initialValue: props.initialValue } : {})}
                    //validate={validate === true ? (required && isRequired) : null}
                    {...(props.validators
                        ? {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null,
                                        ...props.validators
                                    )
                                    : null,
                        }
                        : {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null
                                    )
                                    : null,
                        })}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                >
                    {({ input }) => {
                        return (
                            <div className={inputFieldClassName}>
                                <div
                                    className={
                                        isNotNullAndUndefined(fieldInnerText)
                                            ? "fieldInnerText d-flex"
                                            : "d-flex align-items-center flex-wrap"
                                    }
                                >
                                    <StyledRating
                                        id={`styled_rating_${field.toString()}`}
                                        name={input.name}
                                        value={getRatingValueIndex(input.value)}
                                        onChange={(e, newValue) => {
                                            if (e.isTrusted) {
                                                onChangeInput(newValue, input);
                                            }
                                        }}
                                        onChangeActive={(event, newHover) => {
                                            onChangeActive(event, newHover);
                                        }}
                                        IconContainerComponent={(args) => IconContainer(args, customIcons)}
                                        getLabelText={getLabelText}
                                        highlightSelectedOnly
                                        disabled={disabled}
                                        {...(isNotEmptyArray(customIcons)
                                            ? {
                                                max: customIcons.length
                                            }
                                            : {})}

                                    />
                                    {uiType === RAFUIType.SentimentToggleWithLabel
                                        && ((isNotNullAndUndefined(hoverActiveValue)
                                            && IsNotNullOrWhiteSpace(hoverActiveValue))
                                            || (isNotNullAndUndefined(input.value)
                                                && IsNotNullOrWhiteSpace(input.value))) && (
                                            <div className='e-badge e-badge-lg e-badge-custom e-badge-info' style={{ textTransform: 'unset', minWidth: '80px' }}>{IsNotNullOrWhiteSpace(hoverActiveValue) ? hoverActiveValue : input.value}</div>
                                        )}
                                </div>
                                {props.hideRequiredMessage !== true ? (
                                    <RAFFieldError name={field.toString()} />
                                ) : (
                                    ""
                                )}
                            </div>
                        );
                    }}
                </Field>
            </div>
        </div>
    );
}

export default React.memo(RAFSentimentToggle);